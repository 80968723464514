// List All RegEx for Input Validation

// Org Name
export const orgNameRegEx = /^(?!\s)[\w\s\-'@()]{0,127}(?!\s)[\w)]$/

// Cluster Name
// 4~64 characters can include numbers, lower or uppercase letters, and hyphens. (first and last characters must be letter or number)
export const clusterNameRegEx = /^[A-Za-z0-9][-A-Za-z0-9]{2,62}[A-Za-z0-9]$/

// SQL Editor File name
export const SqlEditorFileNameRegEx = /^[A-Za-z0-9][-A-Za-z0-9\s]{2,62}[A-Za-z0-9]$/

// Backup Name
// 4~16 characters can include numbers, lower or uppercase letters, and hyphens. (first and last characters must be letter or number)
export const backupNameRegEx = /^[A-Za-z0-9][-A-Za-z0-9]{2,14}[A-Za-z0-9]$/

// Password
// must be 8-64 characters
export const rootPwdRegEx = /^.{8,64}$/

// IP and CIDR
export const ipOrCidrRegEx =
  /^((?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(?:(\/([0-9]|[1-2]\d|3[0-2])))?)$/

// CIDR
export const cidrRegEx =
  /^(?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/([0-9]|[1-2]\d|3[0-2])$/

// Email address
// eslint-disable-next-line
export const emailRegEx =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

// Regular Name
// 1 ~ 128 ASCII characters
export const regularNameRegexp = /^[\x20-\x7E]{1,128}$/
export const regularNamePeriodCheck = /\.(?!\s)/
export const baseNameRegexp = /^([a-zA-Z0-9_-]|'|\.|\s){1,64}$/
export const userNameRegexp = baseNameRegexp

// Length 64 String
// 1 ~ 64 ASCII characters
export const len64StrRegexp = /^[\x20-\x7E]{1,64}$/

// Phone Number
// only check length and illegal characters -+() and whitespace
export const phoneNumberRegexp = /^[-+() 0-9]{1,64}$/

export const asciiRegex = /^[\x20-\x7F]+$/

export const tsoRegex = /^\d{18}$/

export const ipPortRegex =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]):([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/
export const hostnamePortRegex =
  /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9]):([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/

export const objNameRegex = /^[a-zA-Z0-9*._[\]\-=]+$/

export const targetTableRegex = /^`(.+)`\.`(.+)`|(.+)\.(.+)$/
export const len100StrRegexp = /^[\x20-\x7E]{1,100}$/

export const VPCIdRegexp = /^vpc-([a-z0-9]{8}|[a-z0-9]{17})$/
export const securityGroupIDRegEx = /^sg-([a-z0-9]{8}|[a-z0-9]{17})$/
export const subnetIDRegEx = /^subnet-([a-z0-9]{8}|[a-z0-9]{17})$/
export const s3BucketNameRegEx = /(?!(^xn--|-s3alias$))^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$/
export const IAMRegex = /^arn:aws:iam::\d{12}:role\/[A-Za-z0-9]+(?:-[A-Za-z0-9]+)+$/

// see https://stackoverflow.com/questions/70731996/regex-to-match-an-aws-arn
export const getSecurityARNRegEx = (type: 'kms' | 'acm-pca' | 'acm', region: string) => {
  const suffixMaps = {
    kms: 'key',
    'acm-pca': 'certificate-authority',
    acm: 'certificate'
  }

  return new RegExp(
    `^arn:aws:${type}:${!!region ? region : '\\w+(?:-\\w+)+'}:\\d{12}:${
      suffixMaps[type]
    }/[A-Za-z0-9]+(?:-[A-Za-z0-9]+)+$`
  )
}

export const domainRegEx = /^((?!:\/\/)[A-Za-z0-9-]+\.)*[A-Za-z0-9-]+\.[A-Za-z0-9-]{2,}$/

// Vercel datababse Name
export const vercelDatabaseNameRegEx = /^[^\s`]{1,64}$/
