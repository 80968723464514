import axios from 'axios'
import mixpanel, { Dict, Query, Response } from 'mixpanel-browser'

import { log } from 'common/log'
import { CentralRegistrationReq, CentralRegistrationSource } from 'dbaas/services'
import { CentralUserProfile, CentralOrganization } from 'dbaas/services'

import { getCookie } from '../chore'
import { getThirdPartyVendor } from '../marketplace'

export { default as usePageViewTracking } from './usePageViewTracking'

function checkCallbackResponse(e: Response): [boolean, string] {
  if (typeof e === 'number') {
    return [!e, '']
  }
  if (typeof e === 'object' && 'status' in e && !e.status) {
    return [true, e.error]
  }
  return [false, '']
}

export function identifyUser(
  user: { email: string; id: string; is_org_owner?: boolean; first_name?: string; last_name?: string },
  tenant: { third_party_account_provider: string }
) {
  try {
    const { id } = user

    // identify users to mixpanel
    localStorage.setItem('dbaas.mixpanel_user', id)
    mixpanel.identify(id)
    initUserProfile({
      ...user,
      accountProvider: tenant.third_party_account_provider
        ? getThirdPartyVendor(tenant.third_party_account_provider)
        : undefined
    })
  } catch (e) {
    console.error(e)
  }
}

export function initUserProfile(userProfile: Dict) {
  try {
    const { email, id, is_org_owner, first_name, last_name, accountProvider } = userProfile
    // init the user profile: after first login
    // user profile properties: https://help.mixpanel.com/hc/en-us/articles/115004708186
    mixpanel.people.set_once(
      {
        // only reserved properties need the $
        $email: email,
        $first_name: first_name,
        $last_name: last_name,

        'User ID': id,
        'First Login Date': new Date(),
        'Is Org Owner': is_org_owner,
        accountProvider
      },
      (e) => {
        const [error, msg] = checkCallbackResponse(e)
        if (error) {
          log.tracking.warning('Init user profile error', msg)
        }
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export function userProfileTracking(userProfile: Dict) {
  try {
    const { id, ...props } = userProfile
    // update the user profile to mixpanel
    mixpanel.people.set(
      {
        $first_name: props.first_name,
        $last_name: props.last_name
      },
      (e) => {
        const [error, msg] = checkCallbackResponse(e)
        if (error) {
          log.tracking.warning('Tracking user profile error', msg)
        }
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export function eventTracking(eventName: string, properties?: Object) {
  try {
    // track specific event with name and other properties
    // need to set send_immediately as true, otherwise error callback can't be invoked
    const data = { type: 'MIXPANEL_LOG_FIRED', payload: { event: eventName, props: properties, timestamp: Date.now() } }
    window.postMessage(data, '*')
    mixpanel.track(eventName, properties, { send_immediately: true }, (e) => {
      const [error, msg] = checkCallbackResponse(e)
      if (error) {
        log.tracking.warning(`Tracking Event ${eventName} error`, msg)
      }
    })
  } catch (e) {
    console.error(e)
  }
}

export function linksTracking(query: Query, name: string) {
  try {
    // track click for link query id, like #nav
    mixpanel.track_links(`#${query}`, `${name} Link Clicked`, {
      'Link Name': name
    })
  } catch (e) {
    console.error(e)
  }
}

export function getUTMSource() {
  const utms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  const params = new URLSearchParams(window.location.search)
  const source: CentralRegistrationSource = {}
  utms.forEach((utm) => {
    if (params.has(utm)) {
      source[utm] = params.get(utm) || ''
    }
  })
  const websiteUrl = params.get('website_referrer_url')
  source.most_recent_referral_url = websiteUrl || document.referrer
  source.website_referrer_url = params.get('prev_referrer') || document.referrer
  return source
}

export function reportSignup(data: CentralRegistrationReq & { signupSource?: string; productType?: string }) {
  const utms = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content']
  const params = new URLSearchParams(window.location.search)

  const fields = [
    { name: 'email', value: data.email, ObjectTypeId: '0-1' },
    {
      name: 'firstname',
      value: data.first_name.trim(),
      ObjectTypeId: '0-1'
    },
    {
      name: 'lastname',
      value: data.last_name.trim(),
      ObjectTypeId: '0-1'
    },
    { name: 'phone', value: data.phone_number || '', ObjectTypeId: '0-1' },
    { name: 'contact_country', value: data.country || '', ObjectTypeId: '0-1' },
    { name: 'company', value: data.company || '', ObjectTypeId: '0-1' },
    { name: 'jobtitle', value: data.job || '', ObjectTypeId: '0-1' },
    {
      name: 'connection_type',
      value: (data.connection_type || '').toLowerCase(),
      ObjectTypeId: '0-1'
    },
    {
      name: 'website_referrer_url',
      value: params.get('prev_referrer') || document.referrer,
      ObjectTypeId: '0-1'
    },
    {
      name: 'most_recent_referral_url',
      value: params.get('website_referrer_url') || document.referrer,
      ObjectTypeId: '0-1'
    },
    {
      name: 'signup_source',
      value: data.signupSource ?? '',
      ObjectTypeId: '0-1'
    },
    {
      name: 'product_type',
      value: data.productType ?? '',
      ObjectTypeId: '0-1'
    }
  ]

  utms.forEach((field) => {
    fields.push({
      name: field,
      value: params.get(field) || '',
      ObjectTypeId: '0-1'
    })
  })

  axios
    .post('https://api.hsforms.com/submissions/v3/integration/submit/4466002/1caba22c-31f7-49b2-997a-53f79b6b60e8', {
      fields,
      context: {
        hutk: getCookie('hubspotutk')
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'I agree to the TiDB Cloud Services Agreement and PingCAP Privacy Policy.'
        }
      }
    })
    .catch((e) => {
      log.error('sync to hubspot error', e)
    })
}

export const REGISTER_TYPES = {
  FREE_TRIAL: 10,
  SIGNUP: 20,
  SIGNUP_MSP: 21,
  SSO_REGISTER: 30
} as const

export const PRODUCT_TYPES = {
  SERVERLESS: 'serverless',
  DEDICATED: 'dedicated',
  GENERAL_CLOUD: 'general cloud'
}

export const GA4_PAGE_CATEGORY = {
  CLUSTER_LIST: 'cluster_list',
  CLUSTER_OVERVIEW: 'cluster_overview',
  CLUSTER_BRANCHES: 'cluster_branches'
} as const

export const reportGA = (item: {
  event: string
  site?: string
  page_category?: string
  page_category2?: string
  product_type?: string
  user_id?: string
  [index: string]: any
}) => {
  if (window.dataLayer && Array.isArray(window.dataLayer)) {
    window.dataLayer.push(item)
  }
  window.universalDataLayer = window.universalDataLayer || []
  window.universalDataLayer.push(item)
}
