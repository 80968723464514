import compareVersions from 'compare-versions'

import { OrgPlanStatus } from 'dbaas/screens/Plans/constant'
import type { CentralCluster } from 'dbaas/services'
import type { StoreConfig } from 'dbaas/stores'

export const sleep = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay))

export interface OpenParams {
  clusterName?: string
  version?: string
  orgId?: string
  subject?: string
  description?: string
}

// https://stackoverflow.com/questions/10730362/get-cookie-by-name
export function getCookie(name: string) {
  const match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)'))
  return match ? match[1] : null
}

export const compareVersion = (
  firstVersion: string,
  secondVersion: string,
  operator: compareVersions.CompareOperator
) => {
  if (!firstVersion || !secondVersion) {
    return false
  }
  try {
    return compareVersions.compare(firstVersion, secondVersion, operator)
  } catch (e) {
    return false
  }
}

export function hasChangefeed(flag: StoreConfig, cluster: CentralCluster) {
  if (cluster.is_dev_tier) {
    return false
  }

  const version = cluster.version

  // old version will be hidden
  if (version && compareVersion(version, '5.3.0', '<')) {
    return false
  }

  if (version && flag.enableReplication && compareVersion(version, '5.4.0', '<')) {
    return false
  }

  return true
}

export const isPoC = (plan: any) => {
  if (typeof plan !== 'string') {
    return false
  }
  return plan.toUpperCase() === OrgPlanStatus.POC.toUpperCase()
}

export const isCrossAccount = (id: string | undefined) => {
  return id !== '0' && id !== undefined
}
