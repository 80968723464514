/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * gRPC for our DBaaS central service
 * notably uses gRPC-Gateway with OpenAPI
 * OpenAPI spec version: 0.1
 */

export type CentralClusterStatus = typeof CentralClusterStatus[keyof typeof CentralClusterStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CentralClusterStatus = {
  NORMAL: 'NORMAL',
  CREATING: 'CREATING',
  SCALING: 'SCALING',
  UPGRADING: 'UPGRADING',
  UNAVAILABLE: 'UNAVAILABLE',
  RECOVERING: 'RECOVERING',
  IMPORTING: 'IMPORTING',
  PAUSED: 'PAUSED',
  RESUMING: 'RESUMING',
  DELETED: 'DELETED',
  MAINTAINING: 'MAINTAINING',
  PAUSING: 'PAUSING',
} as const;
