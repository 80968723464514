import { Box, Flex, Button, Typography, ButtonProps, TypographyProps } from '@tidbcloud/uikit'
import { Link } from 'react-router-dom'

import { BackgroundMask } from 'dbaas/screens/User/Common/Background'
import auth from 'dbaas/services/auth'
import { eventTracking } from 'dbaas/utils/tracking'

import { Logo } from '../Logo'

interface ErrorSupportLinkProps extends ButtonProps {}

export const ErrorSupportLink: React.FC<ErrorSupportLinkProps> = ({ ...rest }) => {
  return (
    <Button
      variant="default"
      component="a"
      href="mailto:tidbcloud-support@pingcap.com"
      rel="noopener noreferrer"
      data-mp-event="Tidbcloud Email Support Link Clicked"
      sx={() => ({ ':hover': { textDecoration: 'none' } })}
      {...rest}
    >
      Contact Support Team
    </Button>
  )
}

export const ContactMessage: React.FC<TypographyProps> = ({ ...rest }) => (
  <Typography ta="center" {...rest}>
    Please try again later or contact support team for assistance.
  </Typography>
)

export interface ErrorPanelProps {
  title?: React.ReactNode
  message?: React.ReactNode

  withRetryLogin?: boolean
  withSignup?: boolean
  withRefresh?: boolean
  withSupport?: boolean
}

export const ErrorPanel: React.FC<ErrorPanelProps> = ({
  title = 'Error Occurred',
  message,
  children,
  withRefresh,
  withRetryLogin,
  withSignup,
  withSupport = true
}) => {
  return (
    <Flex w="100vw" align="center" justify="center" h="100vh">
      <BackgroundMask enableDark />
      <Box onClick={() => auth.logout()} sx={{ cursor: 'pointer', position: 'fixed', left: 24, top: 24 }}>
        <Logo withText />
      </Box>
      <Flex m="0 auto" w="900px" direction="column" pos="relative">
        {!!title && (
          <Typography variant="headline-lg" ta="center" fz={48} lh="96px" c="gray.9">
            {title}
          </Typography>
        )}
        {!!message && (
          <Typography variant="title-lg" ta="center" c="gray.9">
            {message}
          </Typography>
        )}
        {children}
        {!message && !children && <ContactMessage ta="center" />}
        <Flex justify="center">
          {withRetryLogin && (
            <Button
              variant="default"
              my={32}
              mr={24}
              onClick={() => auth.logout()}
              data-mp-event="Error - Retry Login Button Clicked"
              data-mp-reason={title || message}
            >
              Retry Login
            </Button>
          )}
          {withSignup && (
            <Typography
              variant="action-lg"
              c="gray.9"
              onClick={() => eventTracking('Error - Signup Button Clicked')}
              data-mp-reason={title || message}
            >
              Don't have an account <Link to={`/signup${window.location.search}`}>Sign Up</Link>
            </Typography>
          )}
          {withRefresh && (
            <Button
              variant="default"
              my={32}
              mr={24}
              onClick={() => (window.location.href = window.location.pathname)}
              data-mp-event="Error - Refresh Button Clicked"
              data-mp-reason={title || message}
            >
              Refresh
            </Button>
          )}
          {withSupport && <ErrorSupportLink mt={32} />}
        </Flex>
      </Flex>
    </Flex>
  )
}
