import { isO11yTestHostEnabled } from 'dbaas/utils/feature-flags'

import config from '../../config'

const xbackend = localStorage.getItem('dbaasUrl')

function awsUrl(region: string, project: string): string {
  return `https://${region}.${project}.shared.aws.tidbcloud.com`
}

export function gkeUrl(region: string, project: string): string {
  return `https://${region}.${project}.gke.tidbcloud.com`
}

let _dbaasUrl = awsUrl('us-west-2', 'nightly')
export const dbaasUrl: string = xbackend || config.SERVICE_URL || _dbaasUrl

const releaseEnv = config.RELEASE_ENV
export const alertUrl: string =
  releaseEnv !== 'dev'
    ? `https://www.gs.us-west-2.aws.observability.tidbcloud.com/api/v1`
    : `https://www.gs.us-west-2.aws.observability-dev.pingcap.cloud/api/v1`

// for o11y to test new arch for specific tenant and project
export function dynamicAlertUrl(tenantID: string, projectID: string) {
  if (isO11yTestHostEnabled(tenantID, projectID)) {
    const o11yDomainSuffix = config.O11Y_TEST_HOST_DOMAIN_SUFFIX || 'observability.tidbcloud.com'
    return `https://www.gs.us-west-2.aws.${o11yDomainSuffix}/api/v1`
  }

  return alertUrl
}

type HostEnv = 'localhost' | 'dev' | 'prod' | 'staging'

export const hostEnv: HostEnv = (() => {
  if (window.location.host.match(/localhost/)) {
    return 'localhost'
  }
  if (window.location.host.match(/dev/)) {
    return 'dev'
  }
  if (window.location.host.match(/staging/)) {
    return 'staging'
  }

  return 'prod'
})()
