export enum AUTH_METHOD_TYPE {
  PASSWORD = 'Password',
  SOCIAL = 'Social',
  OIDC = 'OIDC',
  SAML = 'SAML'
}

export enum ENTITY_STATUS {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export enum ORG_TYPE {
  ENTERPRISE = 'enterprise',
  PERSONAL = 'personal'
}

export const ENTERPRISE_SIGNIN_PREFIX = '/enterprise/signin'

export const ENTERPRISE_SIGNIN_URL = `${window.location.origin}${ENTERPRISE_SIGNIN_PREFIX}`

export const getEnterpriseSwitchURL = (companyName: string, orgId?: string) => {
  const url = `${ENTERPRISE_SIGNIN_PREFIX}?company_name=${companyName}`
  return orgId ? `${url}&org_id=${orgId}` : url
}
