import { Box, Center } from '@tidbcloud/uikit'
import React from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

import { ReactComponent as TiDBLoadingSVG } from 'media/loader/tidb-loading.svg'

export const XYChart: React.FC<{}> = (props) => {
  return (
    <ContentLoader viewBox="0 0 180 400" height={180} width={180} speed={1} {...props}>
      <rect x="20" y="5" rx="0" ry="0" width="1" height="170" />
      <rect x="20" y="175" rx="0" ry="0" width="360" height="1" />
      <rect x="40" y="75" rx="0" ry="0" width="35" height="100" />
      <rect x="80" y="125" rx="0" ry="0" width="35" height="50" />
      <rect x="120" y="105" rx="0" ry="0" width="35" height="70" />
      <rect x="160" y="35" rx="0" ry="0" width="35" height="140" />
      <rect x="200" y="55" rx="0" ry="0" width="35" height="120" />
      <rect x="240" y="15" rx="0" ry="0" width="35" height="160" />
      <rect x="280" y="135" rx="0" ry="0" width="35" height="40" />
      <rect x="320" y="85" rx="0" ry="0" width="35" height="90" />
    </ContentLoader>
  )
}

/**
 * @deprecated
 */
const TableRow: React.FC<IContentLoaderProps> = (props) => {
  const random = Math.random() * (1 - 0.7) + 0.7
  return (
    <ContentLoader viewBox="0 0 1060 40" height={40} width={1060} speed={2} {...props}>
      <rect x="0" y="15" rx="4" ry="4" width="6" height="6.4" />
      <rect x="34" y="13" rx="6" ry="6" width={200 * random} height="12" />
      <rect x="633" y="13" rx="6" ry="6" width={23 * random} height="12" />
      <rect x="653" y="13" rx="6" ry="6" width={78 * random} height="12" />
      <rect x="755" y="13" rx="6" ry="6" width={117 * random} height="12" />
      <rect x="938" y="13" rx="6" ry="6" width={83 * random} height="12" />

      <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
    </ContentLoader>
  )
}

/**
 * @deprecated
 */
const TableLoader = ({ rows = 5 }) => (
  <>
    {Array(rows)
      .fill('')
      .map((e, i) => (
        <TableRow key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
      ))}
  </>
)

/**
 * @deprecated
 */
const ShortParagraph: React.FC<IContentLoaderProps> = (props) => (
  <ContentLoader viewBox="0 0 778 116" width={778} height={116} speed={2} {...props}>
    <rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
    <rect x="28" y="29" rx="0" ry="0" width="258" height="32" />
    <rect x="28" y="71" rx="0" ry="0" width="465" height="32" />
    <rect x="434" y="94" rx="0" ry="0" width="0" height="0" />
    <rect x="29" y="116" rx="0" ry="0" width="749" height="32" />
  </ContentLoader>
)

/**
 * @deprecated
 */
const FormFieldLoader: React.FC<IContentLoaderProps> = (props) => (
  <ContentLoader speed={2} width={420} height={60} viewBox="0 0 420 60" {...props}>
    <rect x="1" y="18" rx="3" ry="3" width="78" height="24" />
    <rect x="98" y="18" rx="3" ry="3" width="400" height="24" />
  </ContentLoader>
)

/**
 * @deprecated
 */
const FormLoader: React.FC<{ rows: number }> = ({ rows = 3 }) => (
  <>
    {Array(rows)
      .fill('')
      .map((e, i) => (
        <FormFieldLoader key={i} width="100%" />
      ))}
  </>
)

/**
 * @deprecated
 */
const BulletList: React.FC<IContentLoaderProps> = (props) => (
  <ContentLoader viewBox="0 0 400 150" height={130} width={400} {...props}>
    <circle cx="10" cy="20" r="8" />
    <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="50" r="8" />
    <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="80" r="8" />
    <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="110" r="8" />
    <rect x="25" y="105" rx="5" ry="5" width="220" height="10" />
  </ContentLoader>
)

/**
 * @deprecated
 */
const ThreeColumnLoader: React.FC<{}> = (props) => (
  <div className="three-col-loader" {...props}>
    <BulletList height="9rem" width="33%" />
    <BulletList height="9rem" width="33%" />
    <BulletList height="9rem" width="33%" />
  </div>
)

/**
 * @deprecated
 */
const LegacyPageLoader = () => {
  return (
    <div className="page-loader">
      <div className="gear" />
    </div>
  )
}

const TiDBLoading = () => {
  return (
    <Center h="100vh">
      <Box h={200}>
        <TiDBLoadingSVG width={70} height={80} />
      </Box>
    </Center>
  )
}

export const PageLoader = TiDBLoading
export default PageLoader
