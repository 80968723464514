/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * gRPC for our DBaaS billing service
 * notably uses gRPC-Gateway with OpenAPI
 * OpenAPI spec version: 0.1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  FormsAppAPIUsageExplore,
  FormsTenantPlanInfo,
  FormsPaymentMethod,
  FormsBillingInformation,
  FormsBillingInformationInput,
  FormsBindedSupportPlan,
  ApiHttpBody,
  FormsCostExplorerDetailsResult,
  FormsMonthlycostExplorerResult,
  FormsCostExploreTrendsOutput,
  FormsCostExploreTrendsInputBody,
  FormsCreditsPackage,
  FormsCreditsSummary,
  FormsCostExplorerArgs,
  FormsStripeCustomerInfo,
  FormsStripeInvoice,
  FormsStripePaymentMethod,
  FormsOKType,
  FormsSetupIntent,
  FormsCostQuota,
  FormsContractsResponse,
  ListUserViewContractParams,
  FormsContract,
  FormsDiscount,
  FormsBillingConfig
} from './interface'
import { customInstance } from '../openapi';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary app usage Explorer Monthly
 */
export const getOpenAPIUsageExplorerMonthly = (
    tenantId: string,
    appName: string,
    appId: string,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsAppAPIUsageExplore>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_usage/app/${appName}/apps/${appId}/year/${year}/month/${month}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getOpenAPIUsageExplorerMonthly', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/costexplorer/monthly_usage/app/{app_name}/apps/{app_id}/year/{year}/month/{month}', },  ...options});
    }
  

export const getGetOpenAPIUsageExplorerMonthlyQueryKey = (tenantId: string,
    appName: string,
    appId: string,
    year: string,
    month: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_usage/app/${appName}/apps/${appId}/year/${year}/month/${month}`];

    
export type GetOpenAPIUsageExplorerMonthlyQueryResult = NonNullable<Awaited<ReturnType<typeof getOpenAPIUsageExplorerMonthly>>>
export type GetOpenAPIUsageExplorerMonthlyQueryError = unknown

export const useGetOpenAPIUsageExplorerMonthly = <TData = Awaited<ReturnType<typeof getOpenAPIUsageExplorerMonthly>>, TError = unknown>(
 tenantId: string,
    appName: string,
    appId: string,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOpenAPIUsageExplorerMonthly>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOpenAPIUsageExplorerMonthlyQueryKey(tenantId,appName,appId,year,month);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOpenAPIUsageExplorerMonthly>>> = ({ signal }) => getOpenAPIUsageExplorerMonthly(tenantId,appName,appId,year,month, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getOpenAPIUsageExplorerMonthly>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && appName && appId && year && month), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Get Tenant Plan Info
 * @summary Get Tenant Plan Info
 */
export const getTenantPlanInfo = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsTenantPlanInfo>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/plans`, method: 'get', signal
    },
      { _metadata: { operationName: 'getTenantPlanInfo', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/plans', },  ...options});
    }
  

export const getGetTenantPlanInfoQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/plans`];

    
export type GetTenantPlanInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantPlanInfo>>>
export type GetTenantPlanInfoQueryError = unknown

export const useGetTenantPlanInfo = <TData = Awaited<ReturnType<typeof getTenantPlanInfo>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantPlanInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantPlanInfoQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantPlanInfo>>> = ({ signal }) => getTenantPlanInfo(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTenantPlanInfo>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get Active PaymentMethod
 */
export const getActivePaymentMethod = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsPaymentMethod>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/active`, method: 'get', signal
    },
      { _metadata: { operationName: 'getActivePaymentMethod', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/payments/active', },  ...options});
    }
  

export const getGetActivePaymentMethodQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/active`];

    
export type GetActivePaymentMethodQueryResult = NonNullable<Awaited<ReturnType<typeof getActivePaymentMethod>>>
export type GetActivePaymentMethodQueryError = unknown

export const useGetActivePaymentMethod = <TData = Awaited<ReturnType<typeof getActivePaymentMethod>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getActivePaymentMethod>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetActivePaymentMethodQueryKey(tenantId,billingConfigId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivePaymentMethod>>> = ({ signal }) => getActivePaymentMethod(tenantId,billingConfigId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getActivePaymentMethod>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get Active PaymentMethod
 */
export const getActivePaymentMethodV1 = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsPaymentMethod>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/active`, method: 'get', signal
    },
      { _metadata: { operationName: 'getActivePaymentMethodV1', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/payments/active', },  ...options});
    }
  

export const getGetActivePaymentMethodV1QueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/active`];

    
export type GetActivePaymentMethodV1QueryResult = NonNullable<Awaited<ReturnType<typeof getActivePaymentMethodV1>>>
export type GetActivePaymentMethodV1QueryError = unknown

export const useGetActivePaymentMethodV1 = <TData = Awaited<ReturnType<typeof getActivePaymentMethodV1>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getActivePaymentMethodV1>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetActivePaymentMethodV1QueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivePaymentMethodV1>>> = ({ signal }) => getActivePaymentMethodV1(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getActivePaymentMethodV1>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get BillingInfo
 */
export const getBillingInfo = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsBillingInformation>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_info`, method: 'get', signal
    },
      { _metadata: { operationName: 'getBillingInfo', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_info', },  ...options});
    }
  

export const getGetBillingInfoQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_info`];

    
export type GetBillingInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getBillingInfo>>>
export type GetBillingInfoQueryError = unknown

export const useGetBillingInfo = <TData = Awaited<ReturnType<typeof getBillingInfo>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillingInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBillingInfoQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillingInfo>>> = ({ signal }) => getBillingInfo(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getBillingInfo>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Update BillingInfo
 */
export const updateBillingInfo = (
    tenantId: string,
    formsBillingInformationInput: FormsBillingInformationInput,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBillingInformation>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_info`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsBillingInformationInput
    },
      { _metadata: { operationName: 'updateBillingInfo', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_info', },  ...options});
    }
  


    export type UpdateBillingInfoMutationResult = NonNullable<Awaited<ReturnType<typeof updateBillingInfo>>>
    export type UpdateBillingInfoMutationBody = FormsBillingInformationInput
    export type UpdateBillingInfoMutationError = unknown

    export const useUpdateBillingInfo = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBillingInfo>>, TError,{tenantId: string;data: FormsBillingInformationInput}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBillingInfo>>, {tenantId: string;data: FormsBillingInformationInput}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  updateBillingInfo(tenantId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateBillingInfo>>, TError, {tenantId: string;data: FormsBillingInformationInput}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary Get Tenant Support Plan
 */
export const getTenantSupportPlan = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsBindedSupportPlan>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/binded_support_plan`, method: 'get', signal
    },
      { _metadata: { operationName: 'getTenantSupportPlan', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/binded_support_plan', },  ...options});
    }
  

export const getGetTenantSupportPlanQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/binded_support_plan`];

    
export type GetTenantSupportPlanQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantSupportPlan>>>
export type GetTenantSupportPlanQueryError = unknown

export const useGetTenantSupportPlan = <TData = Awaited<ReturnType<typeof getTenantSupportPlan>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantSupportPlan>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantSupportPlanQueryKey(tenantId,billingConfigId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantSupportPlan>>> = ({ signal }) => getTenantSupportPlan(tenantId,billingConfigId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTenantSupportPlan>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Download Contract
 */
export const downloadContract = (
    tenantId: string,
    billingConfigId: number,
    contractId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<ApiHttpBody>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/contracts/${contractId}/download`, method: 'get', signal
    },
      { _metadata: { operationName: 'downloadContract', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/contracts/{contract_id}/download', },  ...options});
    }
  

export const getDownloadContractQueryKey = (tenantId: string,
    billingConfigId: number,
    contractId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/contracts/${contractId}/download`];

    
export type DownloadContractQueryResult = NonNullable<Awaited<ReturnType<typeof downloadContract>>>
export type DownloadContractQueryError = unknown

export const useDownloadContract = <TData = Awaited<ReturnType<typeof downloadContract>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    contractId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof downloadContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadContractQueryKey(tenantId,billingConfigId,contractId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadContract>>> = ({ signal }) => downloadContract(tenantId,billingConfigId,contractId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof downloadContract>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId && contractId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getBillsDetails = (
    tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsCostExplorerDetailsResult>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/bills_details/year/${year}/month/${month}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getBillsDetails', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/costexplorer/bills_details/year/{year}/month/{month}', },  ...options});
    }
  

export const getGetBillsDetailsQueryKey = (tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/bills_details/year/${year}/month/${month}`];

    
export type GetBillsDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getBillsDetails>>>
export type GetBillsDetailsQueryError = unknown

export const useGetBillsDetails = <TData = Awaited<ReturnType<typeof getBillsDetails>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillsDetails>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBillsDetailsQueryKey(tenantId,billingConfigId,year,month);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillsDetails>>> = ({ signal }) => getBillsDetails(tenantId,billingConfigId,year,month, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getBillsDetails>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId && year && month), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Monthly Bills Explorer Monthly
 */
export const getMonthlyBillsExport = (
    tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiHttpBody>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/monthly_bills/year/${year}/month/${month}/export`, method: 'post'
    },
      { _metadata: { operationName: 'getMonthlyBillsExport', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/costexplorer/monthly_bills/year/{year}/month/{month}/export', },  ...options});
    }
  


    export type GetMonthlyBillsExportMutationResult = NonNullable<Awaited<ReturnType<typeof getMonthlyBillsExport>>>
    
    export type GetMonthlyBillsExportMutationError = unknown

    export const useGetMonthlyBillsExport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getMonthlyBillsExport>>, TError,{tenantId: string;billingConfigId: number;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getMonthlyBillsExport>>, {tenantId: string;billingConfigId: number;year: string;month: string}> = (props) => {
          const {tenantId,billingConfigId,year,month} = props ?? {};

          return  getMonthlyBillsExport(tenantId,billingConfigId,year,month,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof getMonthlyBillsExport>>, TError, {tenantId: string;billingConfigId: number;year: string;month: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary Cost Explorer Monthly
 */
export const getMonthlyCost = (
    tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsMonthlycostExplorerResult>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/monthly_cost/year/${year}/month/${month}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getMonthlyCost', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/costexplorer/monthly_cost/year/{year}/month/{month}', },  ...options});
    }
  

export const getGetMonthlyCostQueryKey = (tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/monthly_cost/year/${year}/month/${month}`];

    
export type GetMonthlyCostQueryResult = NonNullable<Awaited<ReturnType<typeof getMonthlyCost>>>
export type GetMonthlyCostQueryError = unknown

export const useGetMonthlyCost = <TData = Awaited<ReturnType<typeof getMonthlyCost>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMonthlyCost>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMonthlyCostQueryKey(tenantId,billingConfigId,year,month);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMonthlyCost>>> = ({ signal }) => getMonthlyCost(tenantId,billingConfigId,year,month, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getMonthlyCost>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId && year && month), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Cost Explorer Monthly
 */
export const exportMonthBillCSV = (
    tenantId: string,
    billingConfigId: number,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsMonthlycostExplorerResult>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/monthly_cost/year/${year}/month/${month}/export`, method: 'post'
    },
      { _metadata: { operationName: 'exportMonthBillCSV', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/costexplorer/monthly_cost/year/{year}/month/{month}/export', },  ...options});
    }
  


    export type ExportMonthBillCSVMutationResult = NonNullable<Awaited<ReturnType<typeof exportMonthBillCSV>>>
    
    export type ExportMonthBillCSVMutationError = unknown

    export const useExportMonthBillCSV = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exportMonthBillCSV>>, TError,{tenantId: string;billingConfigId: number;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportMonthBillCSV>>, {tenantId: string;billingConfigId: number;year: string;month: string}> = (props) => {
          const {tenantId,billingConfigId,year,month} = props ?? {};

          return  exportMonthBillCSV(tenantId,billingConfigId,year,month,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof exportMonthBillCSV>>, TError, {tenantId: string;billingConfigId: number;year: string;month: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary Cost Explorer Trends
 */
export const getCostExplorerTrends = (
    tenantId: string,
    billingConfigId: string,
    formsCostExploreTrendsInputBody: FormsCostExploreTrendsInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostExploreTrendsOutput>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/trends`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCostExploreTrendsInputBody
    },
      { _metadata: { operationName: 'getCostExplorerTrends', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/costexplorer/trends', },  ...options});
    }
  


    export type GetCostExplorerTrendsMutationResult = NonNullable<Awaited<ReturnType<typeof getCostExplorerTrends>>>
    export type GetCostExplorerTrendsMutationBody = FormsCostExploreTrendsInputBody
    export type GetCostExplorerTrendsMutationError = unknown

    export const useGetCostExplorerTrends = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getCostExplorerTrends>>, TError,{tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getCostExplorerTrends>>, {tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody}> = (props) => {
          const {tenantId,billingConfigId,data} = props ?? {};

          return  getCostExplorerTrends(tenantId,billingConfigId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof getCostExplorerTrends>>, TError, {tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const exportCostExplorerTrends = (
    tenantId: string,
    billingConfigId: string,
    formsCostExploreTrendsInputBody: FormsCostExploreTrendsInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<number[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/costexplorer/trends/csv`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCostExploreTrendsInputBody
    },
      { _metadata: { operationName: 'exportCostExplorerTrends', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/costexplorer/trends/csv', },  ...options});
    }
  


    export type ExportCostExplorerTrendsMutationResult = NonNullable<Awaited<ReturnType<typeof exportCostExplorerTrends>>>
    export type ExportCostExplorerTrendsMutationBody = FormsCostExploreTrendsInputBody
    export type ExportCostExplorerTrendsMutationError = unknown

    export const useExportCostExplorerTrends = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exportCostExplorerTrends>>, TError,{tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportCostExplorerTrends>>, {tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody}> = (props) => {
          const {tenantId,billingConfigId,data} = props ?? {};

          return  exportCostExplorerTrends(tenantId,billingConfigId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof exportCostExplorerTrends>>, TError, {tenantId: string;billingConfigId: string;data: FormsCostExploreTrendsInputBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary List Tenants Credits
 */
export const listTenantsCredits = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsCreditsPackage[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits`, method: 'get', signal
    },
      { _metadata: { operationName: 'listTenantsCredits', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/credits', },  ...options});
    }
  

export const getListTenantsCreditsQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits`];

    
export type ListTenantsCreditsQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantsCredits>>>
export type ListTenantsCreditsQueryError = unknown

export const useListTenantsCredits = <TData = Awaited<ReturnType<typeof listTenantsCredits>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantsCredits>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantsCreditsQueryKey(tenantId,billingConfigId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantsCredits>>> = ({ signal }) => listTenantsCredits(tenantId,billingConfigId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listTenantsCredits>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get Credits
 */
export const getCredits = (
    tenantId: string,
    billingConfigId: number,
    creditId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsCreditsPackage>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits/${creditId}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getCredits', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/credits/{credit_id}', },  ...options});
    }
  

export const getGetCreditsQueryKey = (tenantId: string,
    billingConfigId: number,
    creditId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits/${creditId}`];

    
export type GetCreditsQueryResult = NonNullable<Awaited<ReturnType<typeof getCredits>>>
export type GetCreditsQueryError = unknown

export const useGetCredits = <TData = Awaited<ReturnType<typeof getCredits>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    creditId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCredits>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCreditsQueryKey(tenantId,billingConfigId,creditId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCredits>>> = ({ signal }) => getCredits(tenantId,billingConfigId,creditId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getCredits>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId && creditId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get Tenants Credits Summary
 */
export const getTenantsCreditsSummary = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsCreditsSummary>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits_summary`, method: 'get', signal
    },
      { _metadata: { operationName: 'getTenantsCreditsSummary', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/credits_summary', },  ...options});
    }
  

export const getGetTenantsCreditsSummaryQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/credits_summary`];

    
export type GetTenantsCreditsSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantsCreditsSummary>>>
export type GetTenantsCreditsSummaryQueryError = unknown

export const useGetTenantsCreditsSummary = <TData = Awaited<ReturnType<typeof getTenantsCreditsSummary>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantsCreditsSummary>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantsCreditsSummaryQueryKey(tenantId,billingConfigId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantsCreditsSummary>>> = ({ signal }) => getTenantsCreditsSummary(tenantId,billingConfigId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTenantsCreditsSummary>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Cost Explorer Args
 */
export const getTenantCostExplorerArgs = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsCostExplorerArgs>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/args`, method: 'get', signal
    },
      { _metadata: { operationName: 'getTenantCostExplorerArgs', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/costexplorer/args', },  ...options});
    }
  

export const getGetTenantCostExplorerArgsQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/args`];

    
export type GetTenantCostExplorerArgsQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantCostExplorerArgs>>>
export type GetTenantCostExplorerArgsQueryError = unknown

export const useGetTenantCostExplorerArgs = <TData = Awaited<ReturnType<typeof getTenantCostExplorerArgs>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCostExplorerArgs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantCostExplorerArgsQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantCostExplorerArgs>>> = ({ signal }) => getTenantCostExplorerArgs(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTenantCostExplorerArgs>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Cost Explorer Trends
 */
export const postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrends = (
    tenantId: string,
    formsCostExploreTrendsInputBody: FormsCostExploreTrendsInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsCostExploreTrendsOutput>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/trends`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCostExploreTrendsInputBody
    },
      { _metadata: { operationName: 'postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrends', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/costexplorer/trends', },  ...options});
    }
  


    export type PostApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrends>>>
    export type PostApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsMutationBody = FormsCostExploreTrendsInputBody
    export type PostApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsMutationError = unknown

    export const usePostApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrends = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrends>>, TError,{tenantId: string;data: FormsCostExploreTrendsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrends>>, {tenantId: string;data: FormsCostExploreTrendsInputBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrends(tenantId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrends>>, TError, {tenantId: string;data: FormsCostExploreTrendsInputBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsCsv = (
    tenantId: string,
    formsCostExploreTrendsInputBody: FormsCostExploreTrendsInputBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<number[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/trends/csv`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCostExploreTrendsInputBody
    },
      { _metadata: { operationName: 'postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsCsv', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/costexplorer/trends/csv', },  ...options});
    }
  


    export type PostApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsCsvMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsCsv>>>
    export type PostApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsCsvMutationBody = FormsCostExploreTrendsInputBody
    export type PostApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsCsvMutationError = unknown

    export const usePostApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsCsv = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsCsv>>, TError,{tenantId: string;data: FormsCostExploreTrendsInputBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsCsv>>, {tenantId: string;data: FormsCostExploreTrendsInputBody}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsCsv(tenantId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdCostexplorerTrendsCsv>>, TError, {tenantId: string;data: FormsCostExploreTrendsInputBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * GetCustomerInfo
 * @summary GetCustomerInfo
 */
export const getCustomerInfo = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsStripeCustomerInfo>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/customer`, method: 'get', signal
    },
      { _metadata: { operationName: 'getCustomerInfo', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/payments/stripe/customer', },  ...options});
    }
  

export const getGetCustomerInfoQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/customer`];

    
export type GetCustomerInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomerInfo>>>
export type GetCustomerInfoQueryError = unknown

export const useGetCustomerInfo = <TData = Awaited<ReturnType<typeof getCustomerInfo>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCustomerInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomerInfoQueryKey(tenantId,billingConfigId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerInfo>>> = ({ signal }) => getCustomerInfo(tenantId,billingConfigId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getCustomerInfo>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * ListStripeInvoices
 * @summary ListStripeInvoices
 */
export const listStripeInvoices = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsStripeInvoice[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/invoices`, method: 'get', signal
    },
      { _metadata: { operationName: 'listStripeInvoices', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/payments/stripe/invoices', },  ...options});
    }
  

export const getListStripeInvoicesQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/invoices`];

    
export type ListStripeInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof listStripeInvoices>>>
export type ListStripeInvoicesQueryError = unknown

export const useListStripeInvoices = <TData = Awaited<ReturnType<typeof listStripeInvoices>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listStripeInvoices>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListStripeInvoicesQueryKey(tenantId,billingConfigId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listStripeInvoices>>> = ({ signal }) => listStripeInvoices(tenantId,billingConfigId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listStripeInvoices>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * ListPaymentMethods
 * @summary ListPaymentMethods
 */
export const listPaymentMethods = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsStripePaymentMethod[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/payment_methods`, method: 'get', signal
    },
      { _metadata: { operationName: 'listPaymentMethods', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/payments/stripe/payment_methods', },  ...options});
    }
  

export const getListPaymentMethodsQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/payment_methods`];

    
export type ListPaymentMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof listPaymentMethods>>>
export type ListPaymentMethodsQueryError = unknown

export const useListPaymentMethods = <TData = Awaited<ReturnType<typeof listPaymentMethods>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listPaymentMethods>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListPaymentMethodsQueryKey(tenantId,billingConfigId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPaymentMethods>>> = ({ signal }) => listPaymentMethods(tenantId,billingConfigId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listPaymentMethods>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * UpdatePaymentMethod
 * @summary UpdatePaymentMethod
 */
export const updatePaymentMethod = (
    tenantId: string,
    billingConfigId: number,
    paymentMethodId: string,
    formsStripePaymentMethod: FormsStripePaymentMethod,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsStripePaymentMethod>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/payment_methods/${paymentMethodId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsStripePaymentMethod
    },
      { _metadata: { operationName: 'updatePaymentMethod', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/payments/stripe/payment_methods/{payment_method_id}', },  ...options});
    }
  


    export type UpdatePaymentMethodMutationResult = NonNullable<Awaited<ReturnType<typeof updatePaymentMethod>>>
    export type UpdatePaymentMethodMutationBody = FormsStripePaymentMethod
    export type UpdatePaymentMethodMutationError = unknown

    export const useUpdatePaymentMethod = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePaymentMethod>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string;data: FormsStripePaymentMethod}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePaymentMethod>>, {tenantId: string;billingConfigId: number;paymentMethodId: string;data: FormsStripePaymentMethod}> = (props) => {
          const {tenantId,billingConfigId,paymentMethodId,data} = props ?? {};

          return  updatePaymentMethod(tenantId,billingConfigId,paymentMethodId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updatePaymentMethod>>, TError, {tenantId: string;billingConfigId: number;paymentMethodId: string;data: FormsStripePaymentMethod}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * DeletePaymentMethod
 * @summary DeletePaymentMethod
 */
export const deletePaymentMethod = (
    tenantId: string,
    billingConfigId: number,
    paymentMethodId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/payment_methods/${paymentMethodId}`, method: 'delete'
    },
      { _metadata: { operationName: 'deletePaymentMethod', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/payments/stripe/payment_methods/{payment_method_id}', },  ...options});
    }
  


    export type DeletePaymentMethodMutationResult = NonNullable<Awaited<ReturnType<typeof deletePaymentMethod>>>
    
    export type DeletePaymentMethodMutationError = unknown

    export const useDeletePaymentMethod = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePaymentMethod>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePaymentMethod>>, {tenantId: string;billingConfigId: number;paymentMethodId: string}> = (props) => {
          const {tenantId,billingConfigId,paymentMethodId} = props ?? {};

          return  deletePaymentMethod(tenantId,billingConfigId,paymentMethodId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deletePaymentMethod>>, TError, {tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * SetPaymentMethodDefault
 * @summary SetPaymentMethodDefault
 */
export const setPaymentMethodDefault = (
    tenantId: string,
    billingConfigId: number,
    paymentMethodId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/payment_methods/${paymentMethodId}/default`, method: 'put'
    },
      { _metadata: { operationName: 'setPaymentMethodDefault', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/payments/stripe/payment_methods/{payment_method_id}/default', },  ...options});
    }
  


    export type SetPaymentMethodDefaultMutationResult = NonNullable<Awaited<ReturnType<typeof setPaymentMethodDefault>>>
    
    export type SetPaymentMethodDefaultMutationError = unknown

    export const useSetPaymentMethodDefault = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setPaymentMethodDefault>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setPaymentMethodDefault>>, {tenantId: string;billingConfigId: number;paymentMethodId: string}> = (props) => {
          const {tenantId,billingConfigId,paymentMethodId} = props ?? {};

          return  setPaymentMethodDefault(tenantId,billingConfigId,paymentMethodId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof setPaymentMethodDefault>>, TError, {tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * credit card validation. if pass, attach the paymentMethod to customer
 * @summary credit card validation, query after s.SetUpIntent, before s.ConfirmIntent
 */
export const validateCreditCard = (
    tenantId: string,
    billingConfigId: number,
    paymentMethodId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/payment_methods/${paymentMethodId}/validate`, method: 'put'
    },
      { _metadata: { operationName: 'validateCreditCard', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/payments/stripe/payment_methods/{payment_method_id}/validate', },  ...options});
    }
  


    export type ValidateCreditCardMutationResult = NonNullable<Awaited<ReturnType<typeof validateCreditCard>>>
    
    export type ValidateCreditCardMutationError = unknown

    export const useValidateCreditCard = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateCreditCard>>, TError,{tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof validateCreditCard>>, {tenantId: string;billingConfigId: number;paymentMethodId: string}> = (props) => {
          const {tenantId,billingConfigId,paymentMethodId} = props ?? {};

          return  validateCreditCard(tenantId,billingConfigId,paymentMethodId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof validateCreditCard>>, TError, {tenantId: string;billingConfigId: number;paymentMethodId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * SetupIntent
 * @summary SetupIntent
 */
export const setupIntent = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsSetupIntent>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/payments/stripe/setup_intent`, method: 'post'
    },
      { _metadata: { operationName: 'setupIntent', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/payments/stripe/setup_intent', },  ...options});
    }
  


    export type SetupIntentMutationResult = NonNullable<Awaited<ReturnType<typeof setupIntent>>>
    
    export type SetupIntentMutationError = unknown

    export const useSetupIntent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setupIntent>>, TError,{tenantId: string;billingConfigId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setupIntent>>, {tenantId: string;billingConfigId: number}> = (props) => {
          const {tenantId,billingConfigId} = props ?? {};

          return  setupIntent(tenantId,billingConfigId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof setupIntent>>, TError, {tenantId: string;billingConfigId: number}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary Get Tenant Cost Quota
 */
export const getTenantCostQuota = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsCostQuota>(
      {url: `/api_proxy/billing/api/v1/billing/api_proxy/billing/api/v1/billing/tenants/${tenantId}/cost_quota`, method: 'get', signal
    },
      { _metadata: { operationName: 'getTenantCostQuota', pathRoute: '/api_proxy/billing/api/v1/billing/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/cost_quota', },  ...options});
    }
  

export const getGetTenantCostQuotaQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/api_proxy/billing/api/v1/billing/tenants/${tenantId}/cost_quota`];

    
export type GetTenantCostQuotaQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantCostQuota>>>
export type GetTenantCostQuotaQueryError = unknown

export const useGetTenantCostQuota = <TData = Awaited<ReturnType<typeof getTenantCostQuota>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCostQuota>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantCostQuotaQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantCostQuota>>> = ({ signal }) => getTenantCostQuota(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTenantCostQuota>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary List User View Contract
 */
export const listUserViewContract = (
    tenantId: string,
    billingConfigId: number,
    params?: ListUserViewContractParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsContractsResponse>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/users/contracts`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listUserViewContract', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/users/contracts', },  ...options});
    }
  

export const getListUserViewContractQueryKey = (tenantId: string,
    billingConfigId: number,
    params?: ListUserViewContractParams,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/users/contracts`, ...(params ? [params]: [])];

    
export type ListUserViewContractQueryResult = NonNullable<Awaited<ReturnType<typeof listUserViewContract>>>
export type ListUserViewContractQueryError = unknown

export const useListUserViewContract = <TData = Awaited<ReturnType<typeof listUserViewContract>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    params?: ListUserViewContractParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listUserViewContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserViewContractQueryKey(tenantId,billingConfigId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserViewContract>>> = ({ signal }) => listUserViewContract(tenantId,billingConfigId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listUserViewContract>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get User View Contract
 */
export const getUserViewContract = (
    tenantId: string,
    billingConfigId: number,
    contractId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsContract>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/users/contracts/${contractId}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getUserViewContract', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/users/contracts/{contract_id}', },  ...options});
    }
  

export const getGetUserViewContractQueryKey = (tenantId: string,
    billingConfigId: number,
    contractId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/users/contracts/${contractId}`];

    
export type GetUserViewContractQueryResult = NonNullable<Awaited<ReturnType<typeof getUserViewContract>>>
export type GetUserViewContractQueryError = unknown

export const useGetUserViewContract = <TData = Awaited<ReturnType<typeof getUserViewContract>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number,
    contractId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserViewContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserViewContractQueryKey(tenantId,billingConfigId,contractId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserViewContract>>> = ({ signal }) => getUserViewContract(tenantId,billingConfigId,contractId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserViewContract>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId && contractId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Operate Contract
 */
export const operateContract = (
    tenantId: string,
    contractId: string,
    action: 'accept' | 'reject',
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/users/contracts/${contractId}/actions/${action}`, method: 'post'
    },
      { _metadata: { operationName: 'operateContract', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/users/contracts/{contract_id}/actions/{action}', },  ...options});
    }
  


    export type OperateContractMutationResult = NonNullable<Awaited<ReturnType<typeof operateContract>>>
    
    export type OperateContractMutationError = unknown

    export const useOperateContract = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operateContract>>, TError,{tenantId: string;contractId: string;action: 'accept' | 'reject'}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof operateContract>>, {tenantId: string;contractId: string;action: 'accept' | 'reject'}> = (props) => {
          const {tenantId,contractId,action} = props ?? {};

          return  operateContract(tenantId,contractId,action,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof operateContract>>, TError, {tenantId: string;contractId: string;action: 'accept' | 'reject'}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * List Discount (User portal)
 * @summary List Discount (User portal)
 */
export const listTenantDiscounts = (
    tenantId: string,
    billingConfigId: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsDiscount[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/users/discounts`, method: 'get', signal
    },
      { _metadata: { operationName: 'listTenantDiscounts', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_config_id/{billing_config_id}/users/discounts', },  ...options});
    }
  

export const getListTenantDiscountsQueryKey = (tenantId: string,
    billingConfigId: number,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_config_id/${billingConfigId}/users/discounts`];

    
export type ListTenantDiscountsQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantDiscounts>>>
export type ListTenantDiscountsQueryError = unknown

export const useListTenantDiscounts = <TData = Awaited<ReturnType<typeof listTenantDiscounts>>, TError = unknown>(
 tenantId: string,
    billingConfigId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantDiscounts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantDiscountsQueryKey(tenantId,billingConfigId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantDiscounts>>> = ({ signal }) => listTenantDiscounts(tenantId,billingConfigId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listTenantDiscounts>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && billingConfigId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary List Billing Configs
 */
export const listBillingConfigs = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsBillingConfig[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_configs`, method: 'get', signal
    },
      { _metadata: { operationName: 'listBillingConfigs', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_configs', },  ...options});
    }
  

export const getListBillingConfigsQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_configs`];

    
export type ListBillingConfigsQueryResult = NonNullable<Awaited<ReturnType<typeof listBillingConfigs>>>
export type ListBillingConfigsQueryError = unknown

export const useListBillingConfigs = <TData = Awaited<ReturnType<typeof listBillingConfigs>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listBillingConfigs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListBillingConfigsQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBillingConfigs>>> = ({ signal }) => listBillingConfigs(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listBillingConfigs>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


