import { MantineProvider, ColorSchemeProvider } from '@tidbcloud/uikit'
import { themeColors } from '@tidbcloud/uikit/theme'
import { lazy } from 'react'

// const SignUp = lazy(() => import(/* webpackChunkName: "signup" */ 'dbaas/screens/User/SignUp'))
const EnterpriseSignIn = lazy(
  () => import(/* webpackChunkName: "enterpise-signin" */ 'dbaas/screens/User/SignIn/enterprise')
)
const EnterpriseSwitch = lazy(
  () => import(/* webpackChunkName: "enterpise-signin" */ 'dbaas/screens/User/SignIn/Switch')
)
const EnterpriseInvite = lazy(
  () => import(/* webpackChunkName: "enterpise-invite" */ 'dbaas/screens/User/InviteeAccepting/Enterprise')
)
const SignUpVendorCallback = lazy(
  () => import(/* webpackChunkName: "signup-sso" */ 'dbaas/screens/User/SignUpSSOCallback')
)
const Channel = lazy(() => import('dbaas/screens/User/Channel'))
const SignIn = lazy(() => import('dbaas/screens/User/SignIn'))

// const FreeTrial = lazy(() => import(/* webpackChunkName: "free-trial" */ 'dbaas/screens/User/FreeTrial'))

const ForgotPassword = lazy(() => import('dbaas/screens/User/ForgotPassword'))
const ResetPassword = lazy(() => import('dbaas/screens/User/ResetPassword'))

const InviteCallback = lazy(() => import('dbaas/screens/User/InviteeAccepting'))
const AuthCallback = lazy(() => import('dbaas/screens/Auth/Callback'))
const AuthError = lazy(() => import('dbaas/screens/Auth/Error'))

const EnterpriseConnection = lazy(
  () => import(/* webpackChunkName: "enterprise-connection" */ 'dbaas/screens/User/Connection')
)

const SwitchOrgRedirect = lazy(() => import('dbaas/screens/User/LinkMarketplace/SwitchOrg'))

const MSPAPIKeys = lazy(() => import('dbaas/screens/MSP/APIKeys'))

const OAuthSignIn = lazy(() => import('dbaas/screens/OAuth/SignIn'))
const OAuthDevice = lazy(() => import('dbaas/screens/OAuth/Device'))
const OAuthSignup = lazy(() => import('dbaas/screens/OAuth/Signup'))
const OAuthVerify = lazy(() => import('dbaas/screens/OAuth/Verify'))
const OAuthAuthroize = lazy(() => import('dbaas/screens/OAuth/Authorize'))

const RedirectRoutes = [
  {
    component: AuthCallback,
    path: '/auth_redirect'
  },
  {
    component: AuthError,
    path: '/error',
    meta: { colorSchemeInherited: true }
  },
  {
    component: SwitchOrgRedirect,
    path: '/switch-org'
  }
]

export const publicRoutes = [
  ...RedirectRoutes,
  ...[
    {
      component: ForgotPassword,
      path: '/forgot_password'
    },
    {
      component: ResetPassword,
      path: '/reset_password'
    },
    {
      component: InviteCallback,
      path: '/invite_callback',
      meta: {
        colorSchemeInherited: false
      }
    },
    // {
    //   component: SignUp,
    //   path: '/signup'
    // },
    {
      component: SignUpVendorCallback,
      path: '/signup-sso'
    },
    // {
    //   component: FreeTrial,
    //   path: '/free-trial'
    // },
    {
      component: EnterpriseConnection,
      path: '/enterprise-connection'
    },
    {
      component: Channel,
      path: '/channel'
    },
    {
      component: EnterpriseSignIn,
      path: '/enterprise/signin/:company',
      exact: true
    },
    {
      component: EnterpriseSwitch,
      path: '/enterprise/signin',
      exact: true
    },
    {
      component: EnterpriseInvite,
      path: '/enterprise/invite'
    },
    {
      component: SignIn,
      path: '/signin'
    },
    {
      component: MSPAPIKeys,
      path: '/msp/api-keys',
      exact: true
    },
    {
      component: OAuthSignIn,
      path: '/oauth/signin',
      exact: true
    },
    {
      component: OAuthDevice,
      path: '/oauth/device',
      exact: true
    },
    {
      component: OAuthVerify,
      path: '/oauth/verify/callback',
      exact: true
    },
    {
      component: OAuthSignup,
      path: '/oauth/signup'
    },
    {
      component: OAuthAuthroize,
      path: '/oauth/authorize'
    }
  ].map((route) => {
    return {
      ...route,
      component: () => {
        const Comp = route.component
        // some pages need follow the settings
        if (route.meta?.colorSchemeInherited) {
          return <Comp />
        }
        return (
          // public pages should use light mode
          <ColorSchemeProvider colorScheme="light" toggleColorScheme={() => ({})}>
            <MantineProvider theme={{ colors: themeColors, colorScheme: 'light' }} inherit>
              <Comp />
            </MantineProvider>
          </ColorSchemeProvider>
        )
      }
    }
  })
]
