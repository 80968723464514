import React from 'react'
import { MobXProviderContext } from 'mobx-react';

import { StoresType } from './index';


// mobx with Hooks
// https://mobx-react.js.org/recipes-migration#prerequisite-upgrade-to-mobx-react-6x
export default function useStores() {
  return React.useContext(MobXProviderContext) as StoresType
}
