import '@tidb-cloud/ui-components/style/global.less'
import './virtual:windi.css'

import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'
import { HookFormProvider } from '@tidbcloud/uikit/biz'
import mixpanel from 'mixpanel-browser'
import * as ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { matchPath } from 'react-router-dom'

import { log, LogErrorType } from 'common/log'
import config from 'config'
import App from 'dbaas/App'
import DbaaSBoundary from 'dbaas/components/ErrorBoundary'
import { heroTracing } from 'dbaas/components/Measurement'
import { UIWrapper } from 'dbaas/contexts'
import { tracingRoutes } from 'dbaas/routes/tracing'
import { hostEnv } from 'dbaas/services/endpoint'
import { getErrorMessage } from 'dbaas/services/errorCodes'
import history from 'dbaas/services/history'
import { shouldDowngrade, shouldSkipError } from 'dbaas/utils/errors'
import { addFMPMark } from 'dbaas/utils/tracing/getFMP'
import { enableAutoClickTracking } from 'dbaas/utils/tracking/enableAutoClickTracking'

import * as serviceWorkerRegistration from './registerServiceWorker'

window.APP_NAME = 'dbaas'

enableAutoClickTracking()

const enabledSentry = config.SENTRY_DEBUG || hostEnv === 'prod'

// SentryEnabled helps us doubl check that Sentry is enabled.
if (enabledSentry) {
  window.SentryEnabled = true
}

Sentry.init({
  enabled: enabledSentry,
  dsn: config.SENTRY_DSN,
  release: `${config.RELEASE_ENV}-${config.VERSION}-${config.DEPLOY_ID}`,
  environment: config.RELEASE_ENV,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'An attempt was made to break through the security policy of the user agent',
    'Looks like your website URL has changed. To ensure the proper functioning of your banner',
    'ResizeObserver loop completed with undelivered notifications'
  ],
  denyUrls: [
    /ingesteer\.services-prod\.nsvcs\.net\/rum_collection/,
    /ws\.zoominfo\.com/,
    /static\.hotjar\.com/,
    /idsync\.rlcdn\.com/
  ],
  integrations: [
    new BrowserTracing({
      routingInstrumentation: addFMPMark(history, tracingRoutes, matchPath)
    })
  ],
  tracesSampler: (samplingContext) => {
    const name = samplingContext.transactionContext.name
    const op = samplingContext.transactionContext.op
    if (
      op === 'http' &&
      ['queryMetrics', 'listClusters', 'updateSqlEditorFile', 'getClusterPlaygroundStatus', 'getCluster'].includes(name)
    ) {
      return 0.01
    }
    if (['c_/free-trial', '/free-trial'].includes(name)) {
      return 0.6
    }
    if (op === 'http') {
      return config.API_SAMPLING_RATE
    }

    return 1
  },
  beforeSendTransaction(event, hint) {
    const tags = event.tags || {}
    // only built-in transaction
    if (!tags.c_type && (heroTracing.apiError || heroTracing.feError)) {
      if (heroTracing.apiError) {
        event.tags = {
          ...tags,
          error_occurred: true,
          apiError: true
        }
      }
      if (heroTracing.feError) {
        event.tags = {
          ...tags,
          error_occurred: true,
          feError: true
        }
      }
      heroTracing.resetError()
    }
    if (hint?.originalException) {
      log.warning('Transaction Exception Hint: ', hint.originalException as any)
    }
    return event
  },
  beforeSend(event, hint) {
    const error = hint?.originalException

    let msg = ''
    // ignore ResizeObserver error
    if (error instanceof Error && error.message) {
      msg = error.message
    } else if (typeof error === 'string') {
      msg = error
    }

    // filter venfor fetch error
    if (shouldSkipError(msg)) {
      return null
    }

    if (shouldDowngrade(msg)) {
      // downgrade to warning
      event.level = 'warning'
    }

    if (event.level === 'error') {
      // only handle error
      const errorTriage = event.tags?.errorTriage
      if (errorTriage === LogErrorType.APIError) {
        heroTracing.apiError = true
      } else {
        heroTracing.feError = true
      }
    }

    return event
  }
})
Sentry.setTag('version', config.VERSION)
Sentry.setTag('branch', config.BRANCH)

// setup mixpanel
mixpanel.init(config.MIXPANEL_TOKEN, { api_host: config.MIXPANEL_HOST || '', verbose: true, ignore_dnt: true }, '')

heroTracing.start()

const queryClient = new QueryClient()

const ProvideApp = () => {
  return (
    <UIWrapper>
      <DbaaSBoundary>
        <QueryClientProvider client={queryClient}>
          <HookFormProvider value={{ onError: getErrorMessage }}>
            <App />
          </HookFormProvider>
        </QueryClientProvider>
      </DbaaSBoundary>
    </UIWrapper>
  )
}

ReactDOM.render(<ProvideApp />, document.getElementById('app'))

// unregister service worker
serviceWorkerRegistration.unregister()
