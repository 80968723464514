import { Suspense, useEffect, lazy } from 'react'
import { Router, Route, Switch } from 'react-router-dom'

import PageLoader from 'dbaas/components/Placeholder'
import history from 'dbaas/services/history'

import { getEnterpriseSwitchURL, ORG_TYPE } from './features/Organization'
import { publicRoutes } from './routes'
import auth from './services/auth'
import { getAuthInfo } from './stores/localStorage'
import { useDevFavicon } from './utils/useDevFavicon'

import './App.less'

const Console = lazy(() => import('./Main'))

function LoginRedirect() {
  useEffect(() => {
    // enterprise signin has another entry
    const authInfo = getAuthInfo()
    if (authInfo.type === ORG_TYPE.ENTERPRISE && authInfo.companyName) {
      return auth.logout(getEnterpriseSwitchURL(authInfo.companyName))
    }
    auth.login()
  }, [])

  return <PageLoader />
}

const ProtectedPage = () => {
  // check whether the current time is past the Access Token's expiry time
  return auth.isAuthenticated() ? <Console /> : <LoginRedirect />
}

const App = () => {
  useDevFavicon()

  return (
    <Router history={history}>
      <Suspense fallback={<div></div>}>
        <Switch>
          {/* public routes */}
          {publicRoutes.map((route) => (
            <Route key={route.path} exact path={route.path} component={route.component} />
          ))}
          {/* protected routes */}
          <Route path="/" component={ProtectedPage} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
