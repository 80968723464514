import * as Sentry from '@sentry/react'
import { notifier } from '@tidbcloud/uikit'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders } from 'axios'

import { log } from 'common/log'
import { getEnterpriseSwitchURL, ORG_TYPE } from 'dbaas/features/Organization'
import { clearLastPage, getAuthInfo, getSignInUserProfile } from 'dbaas/stores/localStorage'

import auth from './auth'
import { dbaasUrl, alertUrl } from './endpoint'
import { ResponseError, ErrorMessageDict, defaultErrorMsg, NetworkErrorMsg } from './errorCodes'

const DEFAULT_TIMEOUT = 30 * 1000

declare module 'axios' {
  interface AxiosRequestConfig {
    _warningStatus?: number[]
    _ignoreStatus?: number[]
    _metadata?: {
      startTime?: number
      operationName?: string
      pathRoute?: string
    }
  }
}

type ServerResponseHeaders = AxiosResponseHeaders & {
  'x-debug-trace-id'?: string
  'x-kong-proxy-latency'?: string
  'x-kong-upstream-latency'?: string
}

function shouldReportVerbose(operationName: string) {
  return !['registration'].includes(operationName)
}

function formatTags(data: any) {
  try {
    return JSON.stringify(data)
  } catch (e) {
    return undefined
  }
}

function sqlShell(orgId: string, clusterId: string, mysqlUser?: string): string {
  if (!mysqlUser) {
    mysqlUser = 'root'
  }
  return dbaasUrl + `/proxy/orgs/${orgId}/clusters/${clusterId}/terminal/${mysqlUser}/?jwt=${auth.getToken()}`
}

function dashboardUrl(orgId: string, clusterId: string, withJWT = true) {
  const base = `${dbaasUrl}/proxy/orgs/${orgId}/clusters/${clusterId}/pd/dashboard/`
  return withJWT ? `${base}?jwt=${auth.getToken()}` : base
}

function addAuth(request: AxiosRequestConfig<any>) {
  if (request.method !== 'options') {
    let token = auth.getToken()
    if (token && request.headers) {
      request.headers.Authorization = `Bearer ${token}`
    }
  }
  request._metadata = {
    ...(request._metadata || {}),
    startTime: Date.now()
  }
  return request
}

function handleTracing(
  config: AxiosRequestConfig<any>,
  responseHeader: ServerResponseHeaders | undefined,
  status: string = 'ok',
  httpCode: number
) {
  if (config?._metadata) {
    const startTime = (config._metadata.startTime || 0) / 1000
    const transaction = Sentry.startTransaction({
      name: config._metadata.operationName || 'http-request',
      op: 'http',
      status,
      startTimestamp: startTime
    })

    const name = `${config.method?.toUpperCase()} ${config._metadata?.pathRoute || config.url}`

    if (responseHeader && responseHeader['x-debug-trace-id']) {
      transaction.setTag('x_debug_trace_id', responseHeader['x-debug-trace-id'])
      const proxyLatency = Number.parseInt(responseHeader['x-kong-proxy-latency'] || '0')
      const upstreamLantency = Number.parseInt(responseHeader['x-kong-upstream-latency'] || '0')
      const endTimestamp = Date.now()

      const serverSpan = transaction.startChild({
        op: 'server',
        status,
        description: `${name} - server`
      })
      const serverStartTime = (endTimestamp - upstreamLantency - proxyLatency) / 1000
      serverSpan.startTimestamp = serverStartTime
      serverSpan.setHttpStatus(httpCode)
      serverSpan.finish(endTimestamp / 1000)

      const clientSpan = transaction.startChild({
        op: 'client',
        status,
        description: `${name} - client`
      })

      clientSpan.startTimestamp = startTime
      clientSpan.setHttpStatus(httpCode)
      clientSpan.finish(serverStartTime)
    } else {
      const span = transaction.startChild({
        op: 'http',
        status,
        description: name
      })
      span.startTimestamp = startTime
      span.setHttpStatus(httpCode)
      span.finish()
    }

    transaction.finish()
  }
}

function handleBaseError(response: AxiosResponse | AxiosError) {
  let res: AxiosResponse | undefined
  if (axios.isAxiosError(response)) {
    res = response.response
  }
  // reject new response error
  else {
    res = response
  }

  // For open API error response: GooglerpcStatus
  if (res?.data?.code && res?.data?.message && res?.data?.details) {
    return new ResponseError(res?.data?.message, res?.data?.code, res?.data?.message)
  }

  // For legacy error response
  if (res?.data?.base_resp?.err_code) {
    const code = Number(res.data.base_resp.err_code) % 100000000
    // not 0 and not get requests
    if (code) {
      return new ResponseError(
        ErrorMessageDict[code] ?? res.data.base_resp.err_msg ?? '',
        code,
        res.data.base_resp.err_msg
      )
    }
  }

  return false
}

function pickMetadata(res: AxiosError | AxiosResponse) {
  let headers = {}
  let requests = {}
  let response: AxiosResponse | undefined = undefined

  const reqConfig = res.config
  const tags = {}

  const operationName = reqConfig?._metadata?.operationName || reqConfig?.url || ''

  if (axios.isAxiosError(res)) {
    headers = res.response?.headers || {}
    response = res.response
  } else {
    headers = res.headers || {}
    response = res
  }

  // report debug trace_id
  if (headers['x-debug-trace-id']) {
    tags['x_debug_trace_id'] = headers['x-debug-trace-id']
  }

  // report request & response
  if (shouldReportVerbose(operationName)) {
    if (reqConfig.params) {
      requests['request_params'] = reqConfig.params
    }
    if (reqConfig.data) {
      requests['request_body'] = reqConfig.data
    }
    if (response?.data) {
      requests['response'] = reqConfig.data
    }
  }

  return {
    headers,
    tags,
    requests
  }
}

function handleErrRes(error: AxiosError) {
  handleTracing(error.config, error.response?.headers, 'error', error.response?.status || 520)

  const operationName = error.config?._metadata?.operationName || error.config?.url || ''

  if (axios.isCancel(error)) {
    error.message = 'Request cancelled'
    return Promise.reject(error)
  }

  const { tags, requests } = pickMetadata(error)

  if (
    error.code === 'ECONNABORTED' &&
    error.message === `timeout of ${error.config.timeout || DEFAULT_TIMEOUT}ms exceeded` &&
    !error.response // undefined response for auth0 CORS
  ) {
    log.network.warning('API Timeout: ' + operationName, error)
    // auth.authorizeWithPrev()
    error.message = defaultErrorMsg
    return Promise.reject(error)
  }

  if (error.message === 'Network Error' || error.message === 'Request aborted') {
    log.network.warning('API Network Error: ' + operationName, error)
    error.message = NetworkErrorMsg
    return Promise.reject(error)
  }

  // has a custom error handler
  if (error.config.isHandleErr) {
    const status = error.response?.status || 0
    if (error.config._ignoreStatus && error.config._ignoreStatus.includes(status)) {
      console.log('API Custom Info Handler: ' + operationName, error)
    } else if (error.config._warningStatus && error.config._warningStatus.includes(status)) {
      log.api.warning('API Custom Warning Handler: ' + operationName, error, { tags })
    } else {
      log.api.error('API Custom Error Handler: ' + operationName, error, { tags })
    }
    return Promise.reject(error)
  }

  // un unauthorized
  if (error.response?.status === 401) {
    if (!auth.isAuthenticated()) {
      log.api.warning('API Expiration Mismatch: ' + operationName, { tags })
    } else {
      log.api.warning('API Unauthroized: ' + operationName, error, { tags })
    }
    // clear last page, in case of infinite redirect
    const user = getSignInUserProfile()
    if (user.userId) {
      clearLastPage(user.userId)
    }

    // Clear auth0 session and redirect to sign in page
    // enterprise signin has another entry
    const authInfo = getAuthInfo()
    if (authInfo.type === ORG_TYPE.ENTERPRISE && authInfo.companyName) {
      auth.logout(getEnterpriseSwitchURL(authInfo.companyName))
      return Promise.reject(error)
    }
    auth.logout()
    return Promise.reject(error)
  }

  let showError = false
  // hide get method error, show post etc method error by config
  if (error.config.method === 'get') showError = false
  if (error.config && error.config.isShowToast !== undefined) {
    showError = error.config.isShowToast
  }
  let errMsg = 'Empty Res Body'
  try {
    errMsg = error.response?.data?.message || errMsg
  } catch (e) {
    console.error(e)
  }
  if (showError) {
    console.log('API Error: ' + errMsg)
    const status = error.response?.status || 0
    if (status >= 400 && status < 500) {
      notifier.warn(errMsg, { autoClose: 6000 })
    } else {
      notifier.error('API Error: ' + errMsg, { autoClose: 6000 })
    }
  }

  log.api.error('API Error: ' + operationName, error, formatTags(requests), { tags })

  const responseError = handleBaseError(error)

  if (responseError) {
    return Promise.reject(responseError)
  }

  return Promise.reject(error)
}

const instance = axios.create({
  baseURL: dbaasUrl,
  timeout: DEFAULT_TIMEOUT
})
instance.defaults.timeout = DEFAULT_TIMEOUT
instance.interceptors.request.use(addAuth)

instance.interceptors.response.use(
  function (response) {
    handleTracing(response.config, response.headers, 'ok', response.status)
    // reject new response error
    const responseError = handleBaseError(response)
    if (responseError) {
      const operationName = response.config?._metadata?.operationName || response.config.url || ''
      const { tags, requests } = pickMetadata(response)

      log.api.error('API Error: ' + operationName, responseError, formatTags(requests), { tags })
      return Promise.reject(responseError)
    }
    return response
  },
  function (error) {
    return handleErrRes(error)
  }
)

export const customInstance = <T>(
  config: AxiosRequestConfig,
  options: AxiosRequestConfig = {} // declare second options to let each generated query have an extra option argument
): Promise<T> => {
  const source = axios.CancelToken.source()
  const promise = instance({
    ...options,
    ...config,
    cancelToken: source.token
  }).then(({ data }) => data)

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

export const metricAPI = axios.create({
  baseURL: dbaasUrl,
  timeout: DEFAULT_TIMEOUT
})
metricAPI.defaults.timeout = DEFAULT_TIMEOUT
metricAPI.interceptors.request.use(addAuth)
metricAPI.interceptors.response.use(function (response) {
  handleTracing(response.config, response.headers, 'ok', response.status)
  return response
}, handleErrRes)

export const eventsAPI = axios.create({
  baseURL: dbaasUrl,
  timeout: DEFAULT_TIMEOUT
})
eventsAPI.defaults.timeout = DEFAULT_TIMEOUT
eventsAPI.interceptors.request.use(addAuth)
eventsAPI.interceptors.response.use(function (response) {
  handleTracing(response.config, response.headers, 'ok', response.status)
  return response
}, handleErrRes)

export const alertsAPI = axios.create({
  baseURL: alertUrl,
  timeout: DEFAULT_TIMEOUT
})
alertsAPI.defaults.timeout = DEFAULT_TIMEOUT
alertsAPI.interceptors.request.use(addAuth)
alertsAPI.interceptors.response.use(function (response) {
  handleTracing(response.config, response.headers, 'ok', response.status)
  return response
}, handleErrRes)

export const alertsInstance = <T>(
  config: AxiosRequestConfig,
  options: AxiosRequestConfig = {} // declare second options to let each generated query have an extra option argument
): Promise<T> => {
  const source = axios.CancelToken.source()
  const promise = alertsAPI({
    ...options,
    ...config,
    cancelToken: source.token
  }).then(({ data }) => data)

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

const componentsDict = {
  tidb: 'TiDB',
  tikv: 'TiKV',
  tiflash: 'TiFlash'
}

export { dashboardUrl, sqlShell, componentsDict }
export default instance
