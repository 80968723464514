/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * gRPC for our DBaaS central service
 * notably uses gRPC-Gateway with OpenAPI
 * OpenAPI spec version: 0.1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CentralGetGlobalNoticeResp,
  RpcStatus,
  CentralGetExpWhiteListResp,
  GetExpWhiteListParams,
  RuntimeError,
  CentralAuth0AuditLogReq,
  CentralPaymentMethodAuditLogReq,
  CentralMetaData,
  CentralUpdateMetaReq,
  CentralGetOrgByCompanyNameRsp,
  CentralCloudProviders,
  CentralListCloudProvidersReq,
  CentralListLoginUserRolesRsp,
  ListLoginUserRolesParams,
  CentralLoginMetricsReq,
  CentralGetClientIPResp,
  CentralCalcNodeProfilesResp,
  CentralCalcNodeProfilesReq,
  CentralOrganization,
  CentralValidateThirdPartyTokenResponse,
  CentralValidateThirdPartyTokenReq,
  CentralUpdateOrgReq,
  CentralAcceptInvitationReq,
  CentralOpenAPIKeys,
  ListOpenAPIKeysParams,
  CentralOpenAPIKey,
  CentralCreateOpenAPIKeyReq,
  CentralUpdateOpenAPIKeyReq,
  CentralGetEmailDomainsRsp,
  GetEmailDomainsParams,
  CentralCreateAuth0UserReq,
  OrgBindThirdParty200,
  OrgBindThirdPartyBody,
  CentralAcknowledgeCNPolicyReq,
  CentralUpdateOrgDefaultPaymentReq,
  CentralCreatePocApplicationResp,
  CentralCreatePocApplicationReq,
  CentralProjects,
  ListProjectsParams,
  CentralProjectId,
  CentralCreateProjectReq,
  CentralUpdateProjectReq,
  ListProjectOpenAPIKeysParams,
  CentralAwsVpcPeerings,
  ListAwsVpcPeeringsParams,
  CentralCreateAWSVpcPeeringResp,
  CentralCreateAWSVpcPeeringReq,
  CentralAwsVpcPeering,
  CentralGetProjectRegionCIDRResp,
  GetProjectRegionCIDRParams,
  GooglerpcStatus,
  ListCloudProvidersV2Body,
  CalcNodeProfilesV2Body,
  CentralCalcClusterFeeResp,
  CentralCalcClusterFeeReq,
  CentralClusters,
  ListClustersParams,
  CentralClusterId,
  CentralClusterCreateReq,
  CentralGenClusterNameResp,
  CentralChangeClusterNameReq,
  CentralGetAuditLogStatusResp,
  CentralUpdateAuditLogStatusResp,
  CentralUpdateAuditLogStatusReq,
  CentralGetAuditLogAccessRecordsResp,
  CentralCreateAuditLogAccessRecordResp,
  CentralCreateAuditLogAccessRecordReq,
  CentralDeleteAuditLogAccessRecordResp,
  CentralCheckAuditLogPluginStatusResp,
  CentralCheckAuditLogPluginStatusReq,
  CentralPrecheckAuditLogConfigResp,
  CentralPrecheckAuditLogConfigReq,
  CentralScaleComponentReq,
  CentralGetClusterCACertificateResp,
  GetClusterCACertificateParams,
  CentralGetPrivateEndpointResp,
  CentralChangePasswordReq,
  CentralCreatePrivateLinkEndpointResp,
  CentralPrivateLinkEndpoint,
  CentralDefaultResp,
  CentralGetPrivateLinkServiceResp,
  CentralCreatePrivateLinkServiceResp,
  CentralPrivateLinkService,
  CentralSendPasswordVerifyCodeReq,
  CentralTiDBNodes,
  GetTiDBNodesParams,
  CentralTiFlashNodes,
  GetTiFlashNodesParams,
  CentralTiKVNodes,
  GetTiKVNodesParams,
  CentralTrafficFilterResp,
  CentralUpdateTrafficFilterReq,
  CentralMakeClusterWritableReq,
  CentralGetClusterResp,
  CentralGetSecondaryClusterProfilesResp,
  CentralCreateClusterResp,
  CentralCreateClusterReq,
  PauseClusterBodyBody,
  CentralScaleClusterReq,
  CentralListCrossAccountDeploymentResp,
  CentralCreateCrossAccountDeploymentResp,
  CentralCreateCrossAccountDeploymentReq,
  CentralGetTiDBCloudDelegationInfoResp,
  CentralGcpVpcPeerings,
  ListGcpVpcPeeringsParams,
  CentralCreateGcpVpcPeeringResp,
  CentralCreateGcpVpcPeeringReq,
  CentralGcpVpcPeering,
  CentralIntegrationNewrelicValidateResp,
  CentralIntegrationNewrelicValidateReq,
  CentralGetAllMetricsKeyResp,
  CentralCreateMetricsKeyResp,
  CentralListIamRolesResp,
  CentralCreateIamRoleResp,
  CentralCreateIamRoleReq,
  CentralListPrivateLinkEndpointsResp,
  CentralIntegrationListResp,
  ListIntegrationsParams,
  CentralIntegrationStatusResp,
  DeleteIntegrationParams,
  CentralIntegrationCreateReq,
  CentralIntegrationDatadogValidateResp,
  CentralIntegrationDatadogValidateReq,
  CentralDeletedClusters,
  ListDeletedClustersParams,
  CentralDeletedCluster,
  CentralUpdateProjectTrafficFilterReq,
  CentralGetUserAlertListResp,
  CentralSubscribeUserAlertResp,
  CentralSubscribeUserAlertReq,
  CentralUnsubscribeUserAlertResp,
  CentralUsers,
  ListProjectUsersParams,
  InviteUserBody,
  UpdateProjectUserRole200,
  UpdateProjectUserRoleBody,
  CentralProjectCIDR,
  CentralCreateProjectCIDRReqBody,
  ListUsersParams,
  InviteUserToOrg200,
  InviteUserToOrgBody,
  CentralUserProfile,
  CentralUpdateUserProfileReq,
  UpdateUserBody,
  FormsDiscount,
  FormsCostExplorerDetailsResult,
  ApiHttpBody,
  FormsMonthlycostExplorerResult,
  FormsDataAPIUsageExplore,
  FormsInvoice,
  ListInvoicesParams,
  FormsCreateInvoiceReq,
  FormsStripeCustomerInfo,
  FormsStripeInvoice,
  FormsStripePaymentMethod,
  FormsOKType,
  ApiSwitchStruct,
  FormsSetupIntent,
  CentralOrgIdReq,
  FormsBillingInformation,
  FormsBillingInformationInput,
  FormsSupportPlan,
  FormsBindedSupportPlan,
  FormsCreditsPackage,
  FormsCreditsSummary,
  FormsCostQuota,
  FormsContractsResponse,
  ListUserViewContractParams,
  FormsContract,
  UserDebugFeatureList,
  UserDebugAuthorizationList,
  GetUserDebugAuthorizationListParams,
  UserDebugAuthorization,
  UserDebugResponseMessage,
  UserDebugAuthorizationDetail,
  UserDebugAuthorizationRecords,
  GetUserDebugAuthorizationRecordsParams,
  GetUserDebugAuthorizationRecordsForAuthParams,
  HibernateClusterReq,
  PlaygroundSQLRespSucc,
  PlaygroundSQLRespFail,
  PlaygroundSQLReq,
  ServerlessGetClusterSpendLimitsResp,
  ChangeSpendLimitBody,
  ServerlessGetSpendLimitDetailsResp,
  ServerlessSpendLimitsMeasureResp,
  GetSpendLimitMeasureDetailParams,
  PredictionSpendLimitMeasureBody,
  ServerlessGetAllSpendLimitsResp,
  GetAllSpendLimitParams,
  ServerlessPredictSpendLimitResp,
  PredictSpendLimitParams,
  PredictClusterSpendLimitParams,
  DtoSampleInitializeRes,
  DtoShowDBsRes,
  DtoShowTablesRes,
  DtoShowTableMetaRes,
  DtoClusterMetaRes,
  GetAllDbDataParams,
  DtoSessionRes,
  EntitySessionParam,
  DtoStreamingInteractResponse,
  GenerateSqlByBotStreamingParams,
  DtoInteractResponse,
  DtoInteractRequest,
  DtoShowSettingsRes,
  DtoUpdateSettingsRes,
  EntitySettingsParam,
  DtoListSQLFileRes,
  DtoCreateSQLFileRes,
  EntitySqlEditorFileBody,
  DtoShowSQLFileRes,
  DtoUpdateSQLFileRes,
  DtoDeleteSQLFileRes,
  DtoStatementsRes,
  EntityStatementParam,
  DtoShowUserInfoRes,
  DtoUpdateUserInfoRes,
  EntityUserInfoParam,
  DtoIsDBTableExistRes,
  DtoGenericSuccessApiResponse,
  EntityDataAppSettingsFormat,
  DtoBasicRes,
  EntitySqlEditorOplogParam,
  TypesQueryAuditLogResp,
  GetAuditLogsParams,
  TypesEventTypesResp,
  TypesUpdateSettingResp,
  TypesQuerySettingsResp,
  AuditLogsExportParams
} from './interface'
import { customInstance } from '../openapi';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getGlobalNotice = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetGlobalNoticeResp>(
      {url: `/api/v1/message/notice`, method: 'get', signal
    },
      { _metadata: { operationName: 'getGlobalNotice', pathRoute: '/api/v1/message/notice', },  ...options});
    }
  

export const getGetGlobalNoticeQueryKey = () => [`/api/v1/message/notice`];

    
export type GetGlobalNoticeQueryResult = NonNullable<Awaited<ReturnType<typeof getGlobalNotice>>>
export type GetGlobalNoticeQueryError = RpcStatus

export const useGetGlobalNotice = <TData = Awaited<ReturnType<typeof getGlobalNotice>>, TError = RpcStatus>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGlobalNotice>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGlobalNoticeQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGlobalNotice>>> = ({ signal }) => getGlobalNotice(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getGlobalNotice>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getExpWhiteList = (
    orgId: string,
    params?: GetExpWhiteListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetExpWhiteListResp>(
      {url: `/api/v1/orgs/${orgId}/exp-whitelist`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getExpWhiteList', pathRoute: '/api/v1/orgs/{org_id}/exp-whitelist', },  ...options});
    }
  

export const getGetExpWhiteListQueryKey = (orgId: string,
    params?: GetExpWhiteListParams,) => [`/api/v1/orgs/${orgId}/exp-whitelist`, ...(params ? [params]: [])];

    
export type GetExpWhiteListQueryResult = NonNullable<Awaited<ReturnType<typeof getExpWhiteList>>>
export type GetExpWhiteListQueryError = RpcStatus

export const useGetExpWhiteList = <TData = Awaited<ReturnType<typeof getExpWhiteList>>, TError = RpcStatus>(
 orgId: string,
    params?: GetExpWhiteListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getExpWhiteList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetExpWhiteListQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExpWhiteList>>> = ({ signal }) => getExpWhiteList(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getExpWhiteList>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const updateAuth0AuditLog = (
    centralAuth0AuditLogReq: CentralAuth0AuditLogReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/audit_log/auth0`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralAuth0AuditLogReq
    },
      { _metadata: { operationName: 'updateAuth0AuditLog', pathRoute: '/api/v1/audit_log/auth0', },  ...options});
    }
  


    export type UpdateAuth0AuditLogMutationResult = NonNullable<Awaited<ReturnType<typeof updateAuth0AuditLog>>>
    export type UpdateAuth0AuditLogMutationBody = CentralAuth0AuditLogReq
    export type UpdateAuth0AuditLogMutationError = RuntimeError

    export const useUpdateAuth0AuditLog = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAuth0AuditLog>>, TError,{data: CentralAuth0AuditLogReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAuth0AuditLog>>, {data: CentralAuth0AuditLogReq}> = (props) => {
          const {data} = props ?? {};

          return  updateAuth0AuditLog(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateAuth0AuditLog>>, TError, {data: CentralAuth0AuditLogReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const updatePaymentMethodAuditLog = (
    centralPaymentMethodAuditLogReq: CentralPaymentMethodAuditLogReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/audit_log/payment_method`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralPaymentMethodAuditLogReq
    },
      { _metadata: { operationName: 'updatePaymentMethodAuditLog', pathRoute: '/api/v1/audit_log/payment_method', },  ...options});
    }
  


    export type UpdatePaymentMethodAuditLogMutationResult = NonNullable<Awaited<ReturnType<typeof updatePaymentMethodAuditLog>>>
    export type UpdatePaymentMethodAuditLogMutationBody = CentralPaymentMethodAuditLogReq
    export type UpdatePaymentMethodAuditLogMutationError = RuntimeError

    export const useUpdatePaymentMethodAuditLog = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePaymentMethodAuditLog>>, TError,{data: CentralPaymentMethodAuditLogReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePaymentMethodAuditLog>>, {data: CentralPaymentMethodAuditLogReq}> = (props) => {
          const {data} = props ?? {};

          return  updatePaymentMethodAuditLog(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updatePaymentMethodAuditLog>>, TError, {data: CentralPaymentMethodAuditLogReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const meta = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralMetaData>(
      {url: `/api/v1/auth/meta`, method: 'get', signal
    },
      { _metadata: { operationName: 'meta', pathRoute: '/api/v1/auth/meta', },  ...options});
    }
  

export const getMetaQueryKey = () => [`/api/v1/auth/meta`];

    
export type MetaQueryResult = NonNullable<Awaited<ReturnType<typeof meta>>>
export type MetaQueryError = unknown

export const useMeta = <TData = Awaited<ReturnType<typeof meta>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof meta>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getMetaQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof meta>>> = ({ signal }) => meta(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof meta>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const updateMeta = (
    centralUpdateMetaReq: CentralUpdateMetaReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/auth/meta`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: centralUpdateMetaReq
    },
      { _metadata: { operationName: 'updateMeta', pathRoute: '/api/v1/auth/meta', },  ...options});
    }
  


    export type UpdateMetaMutationResult = NonNullable<Awaited<ReturnType<typeof updateMeta>>>
    export type UpdateMetaMutationBody = CentralUpdateMetaReq
    export type UpdateMetaMutationError = unknown

    export const useUpdateMeta = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMeta>>, TError,{data: CentralUpdateMetaReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMeta>>, {data: CentralUpdateMetaReq}> = (props) => {
          const {data} = props ?? {};

          return  updateMeta(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateMeta>>, TError, {data: CentralUpdateMetaReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getOrgByCompanyName = (
    companyName: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetOrgByCompanyNameRsp>(
      {url: `/api/v1/org/${companyName}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getOrgByCompanyName', pathRoute: '/api/v1/org/{company_name}', },  ...options});
    }
  

export const getGetOrgByCompanyNameQueryKey = (companyName: string,) => [`/api/v1/org/${companyName}`];

    
export type GetOrgByCompanyNameQueryResult = NonNullable<Awaited<ReturnType<typeof getOrgByCompanyName>>>
export type GetOrgByCompanyNameQueryError = RpcStatus

export const useGetOrgByCompanyName = <TData = Awaited<ReturnType<typeof getOrgByCompanyName>>, TError = RpcStatus>(
 companyName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrgByCompanyName>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrgByCompanyNameQueryKey(companyName);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrgByCompanyName>>> = ({ signal }) => getOrgByCompanyName(companyName, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getOrgByCompanyName>>, TError, TData>({ queryKey, queryFn, enabled: !!(companyName), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary deprecated, use ListCloudProvidersV2 to instead
 */
export const listCloudProviders = (
    orgId: string,
    centralListCloudProvidersReq: CentralListCloudProvidersReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCloudProviders>(
      {url: `/api/v1/orgs/${orgId}/cloud_providers/query`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralListCloudProvidersReq
    },
      { _metadata: { operationName: 'listCloudProviders', pathRoute: '/api/v1/orgs/{org_id}/cloud_providers/query', },  ...options});
    }
  


    export type ListCloudProvidersMutationResult = NonNullable<Awaited<ReturnType<typeof listCloudProviders>>>
    export type ListCloudProvidersMutationBody = CentralListCloudProvidersReq
    export type ListCloudProvidersMutationError = unknown

    export const useListCloudProviders = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof listCloudProviders>>, TError,{orgId: string;data: CentralListCloudProvidersReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof listCloudProviders>>, {orgId: string;data: CentralListCloudProvidersReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  listCloudProviders(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof listCloudProviders>>, TError, {orgId: string;data: CentralListCloudProvidersReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const listLoginUserRoles = (
    params?: ListLoginUserRolesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralListLoginUserRolesRsp>(
      {url: `/api/v1/login-user-roles`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listLoginUserRoles', pathRoute: '/api/v1/login-user-roles', },  ...options});
    }
  

export const getListLoginUserRolesQueryKey = (params?: ListLoginUserRolesParams,) => [`/api/v1/login-user-roles`, ...(params ? [params]: [])];

    
export type ListLoginUserRolesQueryResult = NonNullable<Awaited<ReturnType<typeof listLoginUserRoles>>>
export type ListLoginUserRolesQueryError = RpcStatus

export const useListLoginUserRoles = <TData = Awaited<ReturnType<typeof listLoginUserRoles>>, TError = RpcStatus>(
 params?: ListLoginUserRolesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listLoginUserRoles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListLoginUserRolesQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listLoginUserRoles>>> = ({ signal }) => listLoginUserRoles(params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listLoginUserRoles>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const loginMetrics = (
    centralLoginMetricsReq: CentralLoginMetricsReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/metrics/login-metrics`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralLoginMetricsReq
    },
      { _metadata: { operationName: 'loginMetrics', pathRoute: '/api/v1/metrics/login-metrics', },  ...options});
    }
  


    export type LoginMetricsMutationResult = NonNullable<Awaited<ReturnType<typeof loginMetrics>>>
    export type LoginMetricsMutationBody = CentralLoginMetricsReq
    export type LoginMetricsMutationError = RuntimeError

    export const useLoginMetrics = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loginMetrics>>, TError,{data: CentralLoginMetricsReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loginMetrics>>, {data: CentralLoginMetricsReq}> = (props) => {
          const {data} = props ?? {};

          return  loginMetrics(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof loginMetrics>>, TError, {data: CentralLoginMetricsReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getClientIP = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetClientIPResp>(
      {url: `/api/v1/my-ip`, method: 'get', signal
    },
      { _metadata: { operationName: 'getClientIP', pathRoute: '/api/v1/my-ip', },  ...options});
    }
  

export const getGetClientIPQueryKey = () => [`/api/v1/my-ip`];

    
export type GetClientIPQueryResult = NonNullable<Awaited<ReturnType<typeof getClientIP>>>
export type GetClientIPQueryError = unknown

export const useGetClientIP = <TData = Awaited<ReturnType<typeof getClientIP>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClientIP>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClientIPQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientIP>>> = ({ signal }) => getClientIP(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getClientIP>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary deprecated, use CalcNodeProfilesV2 to instead
 */
export const calcNodeProfiles = (
    orgId: string,
    centralCalcNodeProfilesReq: CentralCalcNodeProfilesReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCalcNodeProfilesResp>(
      {url: `/api/v1/orgs/${orgId}/node_profiles/query`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCalcNodeProfilesReq
    },
      { _metadata: { operationName: 'calcNodeProfiles', pathRoute: '/api/v1/orgs/{org_id}/node_profiles/query', },  ...options});
    }
  


    export type CalcNodeProfilesMutationResult = NonNullable<Awaited<ReturnType<typeof calcNodeProfiles>>>
    export type CalcNodeProfilesMutationBody = CentralCalcNodeProfilesReq
    export type CalcNodeProfilesMutationError = unknown

    export const useCalcNodeProfiles = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof calcNodeProfiles>>, TError,{orgId: string;data: CentralCalcNodeProfilesReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof calcNodeProfiles>>, {orgId: string;data: CentralCalcNodeProfilesReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  calcNodeProfiles(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof calcNodeProfiles>>, TError, {orgId: string;data: CentralCalcNodeProfilesReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getOrganization = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralOrganization>(
      {url: `/api/v1/orgs/me`, method: 'get', signal
    },
      { _metadata: { operationName: 'getOrganization', pathRoute: '/api/v1/orgs/me', },  ...options});
    }
  

export const getGetOrganizationQueryKey = () => [`/api/v1/orgs/me`];

    
export type GetOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganization>>>
export type GetOrganizationQueryError = unknown

export const useGetOrganization = <TData = Awaited<ReturnType<typeof getOrganization>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrganization>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetOrganizationQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganization>>> = ({ signal }) => getOrganization(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getOrganization>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Valiadte Token
 */
export const valiadteThirdPartyToken = (
    centralValidateThirdPartyTokenReq: CentralValidateThirdPartyTokenReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralValidateThirdPartyTokenResponse>(
      {url: `/api/v1/orgs/validate_thirdparty_token`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralValidateThirdPartyTokenReq
    },
      { _metadata: { operationName: 'valiadteThirdPartyToken', pathRoute: '/api/v1/orgs/validate_thirdparty_token', },  ...options});
    }
  


    export type ValiadteThirdPartyTokenMutationResult = NonNullable<Awaited<ReturnType<typeof valiadteThirdPartyToken>>>
    export type ValiadteThirdPartyTokenMutationBody = CentralValidateThirdPartyTokenReq
    export type ValiadteThirdPartyTokenMutationError = RpcStatus

    export const useValiadteThirdPartyToken = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof valiadteThirdPartyToken>>, TError,{data: CentralValidateThirdPartyTokenReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof valiadteThirdPartyToken>>, {data: CentralValidateThirdPartyTokenReq}> = (props) => {
          const {data} = props ?? {};

          return  valiadteThirdPartyToken(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof valiadteThirdPartyToken>>, TError, {data: CentralValidateThirdPartyTokenReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const updateOrganization = (
    orgId: string,
    centralUpdateOrgReq: CentralUpdateOrgReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: centralUpdateOrgReq
    },
      { _metadata: { operationName: 'updateOrganization', pathRoute: '/api/v1/orgs/{org_id}', },  ...options});
    }
  


    export type UpdateOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof updateOrganization>>>
    export type UpdateOrganizationMutationBody = CentralUpdateOrgReq
    export type UpdateOrganizationMutationError = unknown

    export const useUpdateOrganization = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrganization>>, TError,{orgId: string;data: CentralUpdateOrgReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOrganization>>, {orgId: string;data: CentralUpdateOrgReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  updateOrganization(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateOrganization>>, TError, {orgId: string;data: CentralUpdateOrgReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const acceptInvitation = (
    orgId: string,
    centralAcceptInvitationReq: CentralAcceptInvitationReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/acception`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralAcceptInvitationReq
    },
      { _metadata: { operationName: 'acceptInvitation', pathRoute: '/api/v1/orgs/{org_id}/acception', },  ...options});
    }
  


    export type AcceptInvitationMutationResult = NonNullable<Awaited<ReturnType<typeof acceptInvitation>>>
    export type AcceptInvitationMutationBody = CentralAcceptInvitationReq
    export type AcceptInvitationMutationError = unknown

    export const useAcceptInvitation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptInvitation>>, TError,{orgId: string;data: CentralAcceptInvitationReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof acceptInvitation>>, {orgId: string;data: CentralAcceptInvitationReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  acceptInvitation(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof acceptInvitation>>, TError, {orgId: string;data: CentralAcceptInvitationReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const listOpenAPIKeys = (
    orgId: string,
    params?: ListOpenAPIKeysParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralOpenAPIKeys>(
      {url: `/api/v1/orgs/${orgId}/api_keys`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listOpenAPIKeys', pathRoute: '/api/v1/orgs/{org_id}/api_keys', },  ...options});
    }
  

export const getListOpenAPIKeysQueryKey = (orgId: string,
    params?: ListOpenAPIKeysParams,) => [`/api/v1/orgs/${orgId}/api_keys`, ...(params ? [params]: [])];

    
export type ListOpenAPIKeysQueryResult = NonNullable<Awaited<ReturnType<typeof listOpenAPIKeys>>>
export type ListOpenAPIKeysQueryError = RuntimeError

export const useListOpenAPIKeys = <TData = Awaited<ReturnType<typeof listOpenAPIKeys>>, TError = RuntimeError>(
 orgId: string,
    params?: ListOpenAPIKeysParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listOpenAPIKeys>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListOpenAPIKeysQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listOpenAPIKeys>>> = ({ signal }) => listOpenAPIKeys(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listOpenAPIKeys>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createOpenAPIKey = (
    orgId: string,
    centralCreateOpenAPIKeyReq: CentralCreateOpenAPIKeyReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralOpenAPIKey>(
      {url: `/api/v1/orgs/${orgId}/api_keys`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateOpenAPIKeyReq
    },
      { _metadata: { operationName: 'createOpenAPIKey', pathRoute: '/api/v1/orgs/{org_id}/api_keys', },  ...options});
    }
  


    export type CreateOpenAPIKeyMutationResult = NonNullable<Awaited<ReturnType<typeof createOpenAPIKey>>>
    export type CreateOpenAPIKeyMutationBody = CentralCreateOpenAPIKeyReq
    export type CreateOpenAPIKeyMutationError = RuntimeError

    export const useCreateOpenAPIKey = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOpenAPIKey>>, TError,{orgId: string;data: CentralCreateOpenAPIKeyReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOpenAPIKey>>, {orgId: string;data: CentralCreateOpenAPIKeyReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createOpenAPIKey(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createOpenAPIKey>>, TError, {orgId: string;data: CentralCreateOpenAPIKeyReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const deleteOpenAPIKey = (
    orgId: string,
    apiKeyId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/api_keys/${apiKeyId}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteOpenAPIKey', pathRoute: '/api/v1/orgs/{org_id}/api_keys/{api_key_id}', },  ...options});
    }
  


    export type DeleteOpenAPIKeyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOpenAPIKey>>>
    
    export type DeleteOpenAPIKeyMutationError = RuntimeError

    export const useDeleteOpenAPIKey = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOpenAPIKey>>, TError,{orgId: string;apiKeyId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOpenAPIKey>>, {orgId: string;apiKeyId: string}> = (props) => {
          const {orgId,apiKeyId} = props ?? {};

          return  deleteOpenAPIKey(orgId,apiKeyId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteOpenAPIKey>>, TError, {orgId: string;apiKeyId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const updateOpenAPIKey = (
    orgId: string,
    apiKeyId: string,
    centralUpdateOpenAPIKeyReq: CentralUpdateOpenAPIKeyReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/api_keys/${apiKeyId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: centralUpdateOpenAPIKeyReq
    },
      { _metadata: { operationName: 'updateOpenAPIKey', pathRoute: '/api/v1/orgs/{org_id}/api_keys/{api_key_id}', },  ...options});
    }
  


    export type UpdateOpenAPIKeyMutationResult = NonNullable<Awaited<ReturnType<typeof updateOpenAPIKey>>>
    export type UpdateOpenAPIKeyMutationBody = CentralUpdateOpenAPIKeyReq
    export type UpdateOpenAPIKeyMutationError = RuntimeError

    export const useUpdateOpenAPIKey = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOpenAPIKey>>, TError,{orgId: string;apiKeyId: string;data: CentralUpdateOpenAPIKeyReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOpenAPIKey>>, {orgId: string;apiKeyId: string;data: CentralUpdateOpenAPIKeyReq}> = (props) => {
          const {orgId,apiKeyId,data} = props ?? {};

          return  updateOpenAPIKey(orgId,apiKeyId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateOpenAPIKey>>, TError, {orgId: string;apiKeyId: string;data: CentralUpdateOpenAPIKeyReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getEmailDomains = (
    orgId: string,
    params?: GetEmailDomainsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetEmailDomainsRsp>(
      {url: `/api/v1/orgs/${orgId}/auth-method/email-domain`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getEmailDomains', pathRoute: '/api/v1/orgs/{org_id}/auth-method/email-domain', },  ...options});
    }
  

export const getGetEmailDomainsQueryKey = (orgId: string,
    params?: GetEmailDomainsParams,) => [`/api/v1/orgs/${orgId}/auth-method/email-domain`, ...(params ? [params]: [])];

    
export type GetEmailDomainsQueryResult = NonNullable<Awaited<ReturnType<typeof getEmailDomains>>>
export type GetEmailDomainsQueryError = RpcStatus

export const useGetEmailDomains = <TData = Awaited<ReturnType<typeof getEmailDomains>>, TError = RpcStatus>(
 orgId: string,
    params?: GetEmailDomainsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmailDomains>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEmailDomainsQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmailDomains>>> = ({ signal }) => getEmailDomains(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getEmailDomains>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createAuth0User = (
    orgId: string,
    centralCreateAuth0UserReq: CentralCreateAuth0UserReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/auth0-user`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateAuth0UserReq
    },
      { _metadata: { operationName: 'createAuth0User', pathRoute: '/api/v1/orgs/{org_id}/auth0-user', },  ...options});
    }
  


    export type CreateAuth0UserMutationResult = NonNullable<Awaited<ReturnType<typeof createAuth0User>>>
    export type CreateAuth0UserMutationBody = CentralCreateAuth0UserReq
    export type CreateAuth0UserMutationError = unknown

    export const useCreateAuth0User = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAuth0User>>, TError,{orgId: string;data: CentralCreateAuth0UserReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAuth0User>>, {orgId: string;data: CentralCreateAuth0UserReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createAuth0User(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createAuth0User>>, TError, {orgId: string;data: CentralCreateAuth0UserReq}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Org Bind Third Party
 */
export const orgBindThirdParty = (
    orgId: string,
    orgBindThirdPartyBody: OrgBindThirdPartyBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OrgBindThirdParty200>(
      {url: `/api/v1/orgs/${orgId}/bind_third_party`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: orgBindThirdPartyBody
    },
      { _metadata: { operationName: 'orgBindThirdParty', pathRoute: '/api/v1/orgs/{org_id}/bind_third_party', },  ...options});
    }
  


    export type OrgBindThirdPartyMutationResult = NonNullable<Awaited<ReturnType<typeof orgBindThirdParty>>>
    export type OrgBindThirdPartyMutationBody = OrgBindThirdPartyBody
    export type OrgBindThirdPartyMutationError = RpcStatus

    export const useOrgBindThirdParty = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgBindThirdParty>>, TError,{orgId: string;data: OrgBindThirdPartyBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgBindThirdParty>>, {orgId: string;data: OrgBindThirdPartyBody}> = (props) => {
          const {orgId,data} = props ?? {};

          return  orgBindThirdParty(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgBindThirdParty>>, TError, {orgId: string;data: OrgBindThirdPartyBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const acknowledgeCNPolicy = (
    orgId: string,
    centralAcknowledgeCNPolicyReq: CentralAcknowledgeCNPolicyReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/cn-policy`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralAcknowledgeCNPolicyReq
    },
      { _metadata: { operationName: 'acknowledgeCNPolicy', pathRoute: '/api/v1/orgs/{org_id}/cn-policy', },  ...options});
    }
  


    export type AcknowledgeCNPolicyMutationResult = NonNullable<Awaited<ReturnType<typeof acknowledgeCNPolicy>>>
    export type AcknowledgeCNPolicyMutationBody = CentralAcknowledgeCNPolicyReq
    export type AcknowledgeCNPolicyMutationError = unknown

    export const useAcknowledgeCNPolicy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acknowledgeCNPolicy>>, TError,{orgId: string;data: CentralAcknowledgeCNPolicyReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof acknowledgeCNPolicy>>, {orgId: string;data: CentralAcknowledgeCNPolicyReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  acknowledgeCNPolicy(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof acknowledgeCNPolicy>>, TError, {orgId: string;data: CentralAcknowledgeCNPolicyReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const updateOrgDefaultPayment = (
    orgId: string,
    centralUpdateOrgDefaultPaymentReq: CentralUpdateOrgDefaultPaymentReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/default-payment`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: centralUpdateOrgDefaultPaymentReq
    },
      { _metadata: { operationName: 'updateOrgDefaultPayment', pathRoute: '/api/v1/orgs/{org_id}/default-payment', },  ...options});
    }
  


    export type UpdateOrgDefaultPaymentMutationResult = NonNullable<Awaited<ReturnType<typeof updateOrgDefaultPayment>>>
    export type UpdateOrgDefaultPaymentMutationBody = CentralUpdateOrgDefaultPaymentReq
    export type UpdateOrgDefaultPaymentMutationError = unknown

    export const useUpdateOrgDefaultPayment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrgDefaultPayment>>, TError,{orgId: string;data: CentralUpdateOrgDefaultPaymentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOrgDefaultPayment>>, {orgId: string;data: CentralUpdateOrgDefaultPaymentReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  updateOrgDefaultPayment(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateOrgDefaultPayment>>, TError, {orgId: string;data: CentralUpdateOrgDefaultPaymentReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const createPOCApplication = (
    orgId: string,
    centralCreatePocApplicationReq: CentralCreatePocApplicationReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCreatePocApplicationResp>(
      {url: `/api/v1/orgs/${orgId}/poc-applications`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreatePocApplicationReq
    },
      { _metadata: { operationName: 'createPOCApplication', pathRoute: '/api/v1/orgs/{org_id}/poc-applications', },  ...options});
    }
  


    export type CreatePOCApplicationMutationResult = NonNullable<Awaited<ReturnType<typeof createPOCApplication>>>
    export type CreatePOCApplicationMutationBody = CentralCreatePocApplicationReq
    export type CreatePOCApplicationMutationError = RuntimeError

    export const useCreatePOCApplication = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPOCApplication>>, TError,{orgId: string;data: CentralCreatePocApplicationReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPOCApplication>>, {orgId: string;data: CentralCreatePocApplicationReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createPOCApplication(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createPOCApplication>>, TError, {orgId: string;data: CentralCreatePocApplicationReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const listProjects = (
    orgId: string,
    params?: ListProjectsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralProjects>(
      {url: `/api/v1/orgs/${orgId}/projects`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listProjects', pathRoute: '/api/v1/orgs/{org_id}/projects', },  ...options});
    }
  

export const getListProjectsQueryKey = (orgId: string,
    params?: ListProjectsParams,) => [`/api/v1/orgs/${orgId}/projects`, ...(params ? [params]: [])];

    
export type ListProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof listProjects>>>
export type ListProjectsQueryError = unknown

export const useListProjects = <TData = Awaited<ReturnType<typeof listProjects>>, TError = unknown>(
 orgId: string,
    params?: ListProjectsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listProjects>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListProjectsQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listProjects>>> = ({ signal }) => listProjects(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listProjects>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createProject = (
    orgId: string,
    centralCreateProjectReq: CentralCreateProjectReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralProjectId>(
      {url: `/api/v1/orgs/${orgId}/projects`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateProjectReq
    },
      { _metadata: { operationName: 'createProject', pathRoute: '/api/v1/orgs/{org_id}/projects', },  ...options});
    }
  


    export type CreateProjectMutationResult = NonNullable<Awaited<ReturnType<typeof createProject>>>
    export type CreateProjectMutationBody = CentralCreateProjectReq
    export type CreateProjectMutationError = unknown

    export const useCreateProject = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProject>>, TError,{orgId: string;data: CentralCreateProjectReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProject>>, {orgId: string;data: CentralCreateProjectReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createProject(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createProject>>, TError, {orgId: string;data: CentralCreateProjectReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const deleteProject = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteProject', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}', },  ...options});
    }
  


    export type DeleteProjectMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProject>>>
    
    export type DeleteProjectMutationError = unknown

    export const useDeleteProject = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProject>>, TError,{orgId: string;projectId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProject>>, {orgId: string;projectId: string}> = (props) => {
          const {orgId,projectId} = props ?? {};

          return  deleteProject(orgId,projectId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteProject>>, TError, {orgId: string;projectId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const updateProject = (
    orgId: string,
    projectId: string,
    centralUpdateProjectReq: CentralUpdateProjectReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: centralUpdateProjectReq
    },
      { _metadata: { operationName: 'updateProject', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}', },  ...options});
    }
  


    export type UpdateProjectMutationResult = NonNullable<Awaited<ReturnType<typeof updateProject>>>
    export type UpdateProjectMutationBody = CentralUpdateProjectReq
    export type UpdateProjectMutationError = unknown

    export const useUpdateProject = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProject>>, TError,{orgId: string;projectId: string;data: CentralUpdateProjectReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProject>>, {orgId: string;projectId: string;data: CentralUpdateProjectReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  updateProject(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateProject>>, TError, {orgId: string;projectId: string;data: CentralUpdateProjectReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const listProjectOpenAPIKeys = (
    orgId: string,
    projectId: string,
    params?: ListProjectOpenAPIKeysParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralOpenAPIKeys>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/api_keys`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listProjectOpenAPIKeys', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/api_keys', },  ...options});
    }
  

export const getListProjectOpenAPIKeysQueryKey = (orgId: string,
    projectId: string,
    params?: ListProjectOpenAPIKeysParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/api_keys`, ...(params ? [params]: [])];

    
export type ListProjectOpenAPIKeysQueryResult = NonNullable<Awaited<ReturnType<typeof listProjectOpenAPIKeys>>>
export type ListProjectOpenAPIKeysQueryError = RuntimeError

export const useListProjectOpenAPIKeys = <TData = Awaited<ReturnType<typeof listProjectOpenAPIKeys>>, TError = RuntimeError>(
 orgId: string,
    projectId: string,
    params?: ListProjectOpenAPIKeysParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listProjectOpenAPIKeys>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListProjectOpenAPIKeysQueryKey(orgId,projectId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listProjectOpenAPIKeys>>> = ({ signal }) => listProjectOpenAPIKeys(orgId,projectId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listProjectOpenAPIKeys>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const listAwsVpcPeerings = (
    orgId: string,
    projectId: string,
    params?: ListAwsVpcPeeringsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralAwsVpcPeerings>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/aws_vpc_peerings`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listAwsVpcPeerings', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/aws_vpc_peerings', },  ...options});
    }
  

export const getListAwsVpcPeeringsQueryKey = (orgId: string,
    projectId: string,
    params?: ListAwsVpcPeeringsParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/aws_vpc_peerings`, ...(params ? [params]: [])];

    
export type ListAwsVpcPeeringsQueryResult = NonNullable<Awaited<ReturnType<typeof listAwsVpcPeerings>>>
export type ListAwsVpcPeeringsQueryError = unknown

export const useListAwsVpcPeerings = <TData = Awaited<ReturnType<typeof listAwsVpcPeerings>>, TError = unknown>(
 orgId: string,
    projectId: string,
    params?: ListAwsVpcPeeringsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAwsVpcPeerings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAwsVpcPeeringsQueryKey(orgId,projectId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAwsVpcPeerings>>> = ({ signal }) => listAwsVpcPeerings(orgId,projectId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listAwsVpcPeerings>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createAWSVpcPeering = (
    orgId: string,
    projectId: string,
    centralCreateAWSVpcPeeringReq: CentralCreateAWSVpcPeeringReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCreateAWSVpcPeeringResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/aws_vpc_peerings`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateAWSVpcPeeringReq
    },
      { _metadata: { operationName: 'createAWSVpcPeering', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/aws_vpc_peerings', },  ...options});
    }
  


    export type CreateAWSVpcPeeringMutationResult = NonNullable<Awaited<ReturnType<typeof createAWSVpcPeering>>>
    export type CreateAWSVpcPeeringMutationBody = CentralCreateAWSVpcPeeringReq
    export type CreateAWSVpcPeeringMutationError = unknown

    export const useCreateAWSVpcPeering = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAWSVpcPeering>>, TError,{orgId: string;projectId: string;data: CentralCreateAWSVpcPeeringReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAWSVpcPeering>>, {orgId: string;projectId: string;data: CentralCreateAWSVpcPeeringReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  createAWSVpcPeering(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createAWSVpcPeering>>, TError, {orgId: string;projectId: string;data: CentralCreateAWSVpcPeeringReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getAwsVpcPeering = (
    orgId: string,
    projectId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralAwsVpcPeering>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/aws_vpc_peerings/${id}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getAwsVpcPeering', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/aws_vpc_peerings/{id}', },  ...options});
    }
  

export const getGetAwsVpcPeeringQueryKey = (orgId: string,
    projectId: string,
    id: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/aws_vpc_peerings/${id}`];

    
export type GetAwsVpcPeeringQueryResult = NonNullable<Awaited<ReturnType<typeof getAwsVpcPeering>>>
export type GetAwsVpcPeeringQueryError = unknown

export const useGetAwsVpcPeering = <TData = Awaited<ReturnType<typeof getAwsVpcPeering>>, TError = unknown>(
 orgId: string,
    projectId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAwsVpcPeering>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAwsVpcPeeringQueryKey(orgId,projectId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAwsVpcPeering>>> = ({ signal }) => getAwsVpcPeering(orgId,projectId,id, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAwsVpcPeering>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const deleteAwsVpcPeering = (
    orgId: string,
    projectId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/aws_vpc_peerings/${id}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteAwsVpcPeering', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/aws_vpc_peerings/{id}', },  ...options});
    }
  


    export type DeleteAwsVpcPeeringMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAwsVpcPeering>>>
    
    export type DeleteAwsVpcPeeringMutationError = unknown

    export const useDeleteAwsVpcPeering = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAwsVpcPeering>>, TError,{orgId: string;projectId: string;id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAwsVpcPeering>>, {orgId: string;projectId: string;id: string}> = (props) => {
          const {orgId,projectId,id} = props ?? {};

          return  deleteAwsVpcPeering(orgId,projectId,id,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteAwsVpcPeering>>, TError, {orgId: string;projectId: string;id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const getProjectRegionCIDR = (
    orgId: string,
    projectId: string,
    params?: GetProjectRegionCIDRParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetProjectRegionCIDRResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/cidr/regions`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getProjectRegionCIDR', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/cidr/regions', },  ...options});
    }
  

export const getGetProjectRegionCIDRQueryKey = (orgId: string,
    projectId: string,
    params?: GetProjectRegionCIDRParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/cidr/regions`, ...(params ? [params]: [])];

    
export type GetProjectRegionCIDRQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectRegionCIDR>>>
export type GetProjectRegionCIDRQueryError = unknown

export const useGetProjectRegionCIDR = <TData = Awaited<ReturnType<typeof getProjectRegionCIDR>>, TError = unknown>(
 orgId: string,
    projectId: string,
    params?: GetProjectRegionCIDRParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectRegionCIDR>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectRegionCIDRQueryKey(orgId,projectId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectRegionCIDR>>> = ({ signal }) => getProjectRegionCIDR(orgId,projectId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getProjectRegionCIDR>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const listCloudProvidersV2 = (
    orgId: string,
    projectId: string,
    listCloudProvidersV2Body: ListCloudProvidersV2Body,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCloudProviders>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/cloud_providers/query`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: listCloudProvidersV2Body
    },
      { _metadata: { operationName: 'listCloudProvidersV2', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/cloud_providers/query', },  ...options});
    }
  


    export type ListCloudProvidersV2MutationResult = NonNullable<Awaited<ReturnType<typeof listCloudProvidersV2>>>
    export type ListCloudProvidersV2MutationBody = ListCloudProvidersV2Body
    export type ListCloudProvidersV2MutationError = GooglerpcStatus

    export const useListCloudProvidersV2 = <TError = GooglerpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof listCloudProvidersV2>>, TError,{orgId: string;projectId: string;data: ListCloudProvidersV2Body}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof listCloudProvidersV2>>, {orgId: string;projectId: string;data: ListCloudProvidersV2Body}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  listCloudProvidersV2(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof listCloudProvidersV2>>, TError, {orgId: string;projectId: string;data: ListCloudProvidersV2Body}, TContext>(mutationFn, mutationOptions);
    }
    
export const calcNodeProfilesV2 = (
    orgId: string,
    projectId: string,
    calcNodeProfilesV2Body: CalcNodeProfilesV2Body,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCalcNodeProfilesResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/node_profiles/query`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: calcNodeProfilesV2Body
    },
      { _metadata: { operationName: 'calcNodeProfilesV2', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/node_profiles/query', },  ...options});
    }
  


    export type CalcNodeProfilesV2MutationResult = NonNullable<Awaited<ReturnType<typeof calcNodeProfilesV2>>>
    export type CalcNodeProfilesV2MutationBody = CalcNodeProfilesV2Body
    export type CalcNodeProfilesV2MutationError = GooglerpcStatus

    export const useCalcNodeProfilesV2 = <TError = GooglerpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof calcNodeProfilesV2>>, TError,{orgId: string;projectId: string;data: CalcNodeProfilesV2Body}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof calcNodeProfilesV2>>, {orgId: string;projectId: string;data: CalcNodeProfilesV2Body}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  calcNodeProfilesV2(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof calcNodeProfilesV2>>, TError, {orgId: string;projectId: string;data: CalcNodeProfilesV2Body}, TContext>(mutationFn, mutationOptions);
    }
    
export const calcClusterFee = (
    orgId: string,
    projectId: string,
    centralCalcClusterFeeReq: CentralCalcClusterFeeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCalcClusterFeeResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/cluster_fee/query`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCalcClusterFeeReq
    },
      { _metadata: { operationName: 'calcClusterFee', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/cluster_fee/query', },  ...options});
    }
  


    export type CalcClusterFeeMutationResult = NonNullable<Awaited<ReturnType<typeof calcClusterFee>>>
    export type CalcClusterFeeMutationBody = CentralCalcClusterFeeReq
    export type CalcClusterFeeMutationError = unknown

    export const useCalcClusterFee = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof calcClusterFee>>, TError,{orgId: string;projectId: string;data: CentralCalcClusterFeeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof calcClusterFee>>, {orgId: string;projectId: string;data: CentralCalcClusterFeeReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  calcClusterFee(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof calcClusterFee>>, TError, {orgId: string;projectId: string;data: CentralCalcClusterFeeReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const listClusters = (
    orgId: string,
    projectId: string,
    params?: ListClustersParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralClusters>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listClusters', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters', },  ...options});
    }
  

export const getListClustersQueryKey = (orgId: string,
    projectId: string,
    params?: ListClustersParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters`, ...(params ? [params]: [])];

    
export type ListClustersQueryResult = NonNullable<Awaited<ReturnType<typeof listClusters>>>
export type ListClustersQueryError = unknown

export const useListClusters = <TData = Awaited<ReturnType<typeof listClusters>>, TError = unknown>(
 orgId: string,
    projectId: string,
    params?: ListClustersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listClusters>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListClustersQueryKey(orgId,projectId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listClusters>>> = ({ signal }) => listClusters(orgId,projectId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listClusters>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createCluster = (
    orgId: string,
    projectId: string,
    centralClusterCreateReq: CentralClusterCreateReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralClusterId>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralClusterCreateReq
    },
      { _metadata: { operationName: 'createCluster', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters', },  ...options});
    }
  


    export type CreateClusterMutationResult = NonNullable<Awaited<ReturnType<typeof createCluster>>>
    export type CreateClusterMutationBody = CentralClusterCreateReq
    export type CreateClusterMutationError = unknown

    export const useCreateCluster = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCluster>>, TError,{orgId: string;projectId: string;data: CentralClusterCreateReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCluster>>, {orgId: string;projectId: string;data: CentralClusterCreateReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  createCluster(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createCluster>>, TError, {orgId: string;projectId: string;data: CentralClusterCreateReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const genClusterName = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralGenClusterNameResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/names`, method: 'post'
    },
      { _metadata: { operationName: 'genClusterName', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/names', },  ...options});
    }
  


    export type GenClusterNameMutationResult = NonNullable<Awaited<ReturnType<typeof genClusterName>>>
    
    export type GenClusterNameMutationError = RuntimeError

    export const useGenClusterName = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof genClusterName>>, TError,{orgId: string;projectId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof genClusterName>>, {orgId: string;projectId: string}> = (props) => {
          const {orgId,projectId} = props ?? {};

          return  genClusterName(orgId,projectId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof genClusterName>>, TError, {orgId: string;projectId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const deleteCluster = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteCluster', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}', },  ...options});
    }
  


    export type DeleteClusterMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCluster>>>
    
    export type DeleteClusterMutationError = unknown

    export const useDeleteCluster = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCluster>>, TError,{orgId: string;projectId: string;clusterId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCluster>>, {orgId: string;projectId: string;clusterId: string}> = (props) => {
          const {orgId,projectId,clusterId} = props ?? {};

          return  deleteCluster(orgId,projectId,clusterId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteCluster>>, TError, {orgId: string;projectId: string;clusterId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const changeClusterName = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralChangeClusterNameReq: CentralChangeClusterNameReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: centralChangeClusterNameReq
    },
      { _metadata: { operationName: 'changeClusterName', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}', },  ...options});
    }
  


    export type ChangeClusterNameMutationResult = NonNullable<Awaited<ReturnType<typeof changeClusterName>>>
    export type ChangeClusterNameMutationBody = CentralChangeClusterNameReq
    export type ChangeClusterNameMutationError = unknown

    export const useChangeClusterName = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeClusterName>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralChangeClusterNameReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeClusterName>>, {orgId: string;projectId: string;clusterId: string;data: CentralChangeClusterNameReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  changeClusterName(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof changeClusterName>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralChangeClusterNameReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const clusterServiceGetAuditLogStatus = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetAuditLogStatusResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/audit_log`, method: 'get', signal
    },
      { _metadata: { operationName: 'clusterServiceGetAuditLogStatus', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/audit_log', },  ...options});
    }
  

export const getClusterServiceGetAuditLogStatusQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/audit_log`];

    
export type ClusterServiceGetAuditLogStatusQueryResult = NonNullable<Awaited<ReturnType<typeof clusterServiceGetAuditLogStatus>>>
export type ClusterServiceGetAuditLogStatusQueryError = RuntimeError

export const useClusterServiceGetAuditLogStatus = <TData = Awaited<ReturnType<typeof clusterServiceGetAuditLogStatus>>, TError = RuntimeError>(
 orgId: string,
    projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clusterServiceGetAuditLogStatus>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getClusterServiceGetAuditLogStatusQueryKey(orgId,projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof clusterServiceGetAuditLogStatus>>> = ({ signal }) => clusterServiceGetAuditLogStatus(orgId,projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof clusterServiceGetAuditLogStatus>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const clusterServiceUpdateAuditLogStatus = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralUpdateAuditLogStatusReq: CentralUpdateAuditLogStatusReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralUpdateAuditLogStatusResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/audit_log`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: centralUpdateAuditLogStatusReq
    },
      { _metadata: { operationName: 'clusterServiceUpdateAuditLogStatus', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/audit_log', },  ...options});
    }
  


    export type ClusterServiceUpdateAuditLogStatusMutationResult = NonNullable<Awaited<ReturnType<typeof clusterServiceUpdateAuditLogStatus>>>
    export type ClusterServiceUpdateAuditLogStatusMutationBody = CentralUpdateAuditLogStatusReq
    export type ClusterServiceUpdateAuditLogStatusMutationError = RuntimeError

    export const useClusterServiceUpdateAuditLogStatus = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clusterServiceUpdateAuditLogStatus>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralUpdateAuditLogStatusReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clusterServiceUpdateAuditLogStatus>>, {orgId: string;projectId: string;clusterId: string;data: CentralUpdateAuditLogStatusReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  clusterServiceUpdateAuditLogStatus(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof clusterServiceUpdateAuditLogStatus>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralUpdateAuditLogStatusReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const clusterServiceGetAuditLogAccessRecords = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetAuditLogAccessRecordsResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/audit_log/access_records`, method: 'get', signal
    },
      { _metadata: { operationName: 'clusterServiceGetAuditLogAccessRecords', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/audit_log/access_records', },  ...options});
    }
  

export const getClusterServiceGetAuditLogAccessRecordsQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/audit_log/access_records`];

    
export type ClusterServiceGetAuditLogAccessRecordsQueryResult = NonNullable<Awaited<ReturnType<typeof clusterServiceGetAuditLogAccessRecords>>>
export type ClusterServiceGetAuditLogAccessRecordsQueryError = RuntimeError

export const useClusterServiceGetAuditLogAccessRecords = <TData = Awaited<ReturnType<typeof clusterServiceGetAuditLogAccessRecords>>, TError = RuntimeError>(
 orgId: string,
    projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof clusterServiceGetAuditLogAccessRecords>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getClusterServiceGetAuditLogAccessRecordsQueryKey(orgId,projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof clusterServiceGetAuditLogAccessRecords>>> = ({ signal }) => clusterServiceGetAuditLogAccessRecords(orgId,projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof clusterServiceGetAuditLogAccessRecords>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const clusterServiceCreateAuditLogAccessRecord = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralCreateAuditLogAccessRecordReq: CentralCreateAuditLogAccessRecordReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCreateAuditLogAccessRecordResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/audit_log/access_records`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateAuditLogAccessRecordReq
    },
      { _metadata: { operationName: 'clusterServiceCreateAuditLogAccessRecord', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/audit_log/access_records', },  ...options});
    }
  


    export type ClusterServiceCreateAuditLogAccessRecordMutationResult = NonNullable<Awaited<ReturnType<typeof clusterServiceCreateAuditLogAccessRecord>>>
    export type ClusterServiceCreateAuditLogAccessRecordMutationBody = CentralCreateAuditLogAccessRecordReq
    export type ClusterServiceCreateAuditLogAccessRecordMutationError = RuntimeError

    export const useClusterServiceCreateAuditLogAccessRecord = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clusterServiceCreateAuditLogAccessRecord>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralCreateAuditLogAccessRecordReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clusterServiceCreateAuditLogAccessRecord>>, {orgId: string;projectId: string;clusterId: string;data: CentralCreateAuditLogAccessRecordReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  clusterServiceCreateAuditLogAccessRecord(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof clusterServiceCreateAuditLogAccessRecord>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralCreateAuditLogAccessRecordReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const clusterServiceDeleteAuditLogAccessRecord = (
    orgId: string,
    projectId: string,
    clusterId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralDeleteAuditLogAccessRecordResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/audit_log/access_records/${id}`, method: 'delete'
    },
      { _metadata: { operationName: 'clusterServiceDeleteAuditLogAccessRecord', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/audit_log/access_records/{id}', },  ...options});
    }
  


    export type ClusterServiceDeleteAuditLogAccessRecordMutationResult = NonNullable<Awaited<ReturnType<typeof clusterServiceDeleteAuditLogAccessRecord>>>
    
    export type ClusterServiceDeleteAuditLogAccessRecordMutationError = RuntimeError

    export const useClusterServiceDeleteAuditLogAccessRecord = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clusterServiceDeleteAuditLogAccessRecord>>, TError,{orgId: string;projectId: string;clusterId: string;id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clusterServiceDeleteAuditLogAccessRecord>>, {orgId: string;projectId: string;clusterId: string;id: string}> = (props) => {
          const {orgId,projectId,clusterId,id} = props ?? {};

          return  clusterServiceDeleteAuditLogAccessRecord(orgId,projectId,clusterId,id,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof clusterServiceDeleteAuditLogAccessRecord>>, TError, {orgId: string;projectId: string;clusterId: string;id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const clusterServiceCheckAuditLogPluginStatus = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralCheckAuditLogPluginStatusReq: CentralCheckAuditLogPluginStatusReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCheckAuditLogPluginStatusResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/audit_log/plugin_status`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCheckAuditLogPluginStatusReq
    },
      { _metadata: { operationName: 'clusterServiceCheckAuditLogPluginStatus', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/audit_log/plugin_status', },  ...options});
    }
  


    export type ClusterServiceCheckAuditLogPluginStatusMutationResult = NonNullable<Awaited<ReturnType<typeof clusterServiceCheckAuditLogPluginStatus>>>
    export type ClusterServiceCheckAuditLogPluginStatusMutationBody = CentralCheckAuditLogPluginStatusReq
    export type ClusterServiceCheckAuditLogPluginStatusMutationError = RuntimeError

    export const useClusterServiceCheckAuditLogPluginStatus = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clusterServiceCheckAuditLogPluginStatus>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralCheckAuditLogPluginStatusReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clusterServiceCheckAuditLogPluginStatus>>, {orgId: string;projectId: string;clusterId: string;data: CentralCheckAuditLogPluginStatusReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  clusterServiceCheckAuditLogPluginStatus(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof clusterServiceCheckAuditLogPluginStatus>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralCheckAuditLogPluginStatusReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const clusterServicePrecheckAuditLogConfig = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralPrecheckAuditLogConfigReq: CentralPrecheckAuditLogConfigReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralPrecheckAuditLogConfigResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/audit_log/precheck`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralPrecheckAuditLogConfigReq
    },
      { _metadata: { operationName: 'clusterServicePrecheckAuditLogConfig', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/audit_log/precheck', },  ...options});
    }
  


    export type ClusterServicePrecheckAuditLogConfigMutationResult = NonNullable<Awaited<ReturnType<typeof clusterServicePrecheckAuditLogConfig>>>
    export type ClusterServicePrecheckAuditLogConfigMutationBody = CentralPrecheckAuditLogConfigReq
    export type ClusterServicePrecheckAuditLogConfigMutationError = RuntimeError

    export const useClusterServicePrecheckAuditLogConfig = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clusterServicePrecheckAuditLogConfig>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralPrecheckAuditLogConfigReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clusterServicePrecheckAuditLogConfig>>, {orgId: string;projectId: string;clusterId: string;data: CentralPrecheckAuditLogConfigReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  clusterServicePrecheckAuditLogConfig(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof clusterServicePrecheckAuditLogConfig>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralPrecheckAuditLogConfigReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const scaleComponent = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralScaleComponentReq: CentralScaleComponentReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/components`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: centralScaleComponentReq
    },
      { _metadata: { operationName: 'scaleComponent', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/components', },  ...options});
    }
  


    export type ScaleComponentMutationResult = NonNullable<Awaited<ReturnType<typeof scaleComponent>>>
    export type ScaleComponentMutationBody = CentralScaleComponentReq
    export type ScaleComponentMutationError = unknown

    export const useScaleComponent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scaleComponent>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralScaleComponentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scaleComponent>>, {orgId: string;projectId: string;clusterId: string;data: CentralScaleComponentReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  scaleComponent(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof scaleComponent>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralScaleComponentReq}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary GetClusterCACertificate get the CA certitficate of the cluster
 */
export const getClusterCACertificate = (
    orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetClusterCACertificateParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetClusterCACertificateResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/ca_cert`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getClusterCACertificate', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/ca_cert', },  ...options});
    }
  

export const getGetClusterCACertificateQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetClusterCACertificateParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/ca_cert`, ...(params ? [params]: [])];

    
export type GetClusterCACertificateQueryResult = NonNullable<Awaited<ReturnType<typeof getClusterCACertificate>>>
export type GetClusterCACertificateQueryError = GooglerpcStatus

export const useGetClusterCACertificate = <TData = Awaited<ReturnType<typeof getClusterCACertificate>>, TError = GooglerpcStatus>(
 orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetClusterCACertificateParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClusterCACertificate>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClusterCACertificateQueryKey(orgId,projectId,clusterId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClusterCACertificate>>> = ({ signal }) => getClusterCACertificate(orgId,projectId,clusterId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getClusterCACertificate>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getPrivateEndpoint = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetPrivateEndpointResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/connections`, method: 'get', signal
    },
      { _metadata: { operationName: 'getPrivateEndpoint', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/connections', },  ...options});
    }
  

export const getGetPrivateEndpointQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/connections`];

    
export type GetPrivateEndpointQueryResult = NonNullable<Awaited<ReturnType<typeof getPrivateEndpoint>>>
export type GetPrivateEndpointQueryError = unknown

export const useGetPrivateEndpoint = <TData = Awaited<ReturnType<typeof getPrivateEndpoint>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPrivateEndpoint>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPrivateEndpointQueryKey(orgId,projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPrivateEndpoint>>> = ({ signal }) => getPrivateEndpoint(orgId,projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getPrivateEndpoint>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createPrivateEndpoint = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/connections`, method: 'post'
    },
      { _metadata: { operationName: 'createPrivateEndpoint', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/connections', },  ...options});
    }
  


    export type CreatePrivateEndpointMutationResult = NonNullable<Awaited<ReturnType<typeof createPrivateEndpoint>>>
    
    export type CreatePrivateEndpointMutationError = unknown

    export const useCreatePrivateEndpoint = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPrivateEndpoint>>, TError,{orgId: string;projectId: string;clusterId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPrivateEndpoint>>, {orgId: string;projectId: string;clusterId: string}> = (props) => {
          const {orgId,projectId,clusterId} = props ?? {};

          return  createPrivateEndpoint(orgId,projectId,clusterId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createPrivateEndpoint>>, TError, {orgId: string;projectId: string;clusterId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const changePassword = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralChangePasswordReq: CentralChangePasswordReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/password`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: centralChangePasswordReq
    },
      { _metadata: { operationName: 'changePassword', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/password', },  ...options});
    }
  


    export type ChangePasswordMutationResult = NonNullable<Awaited<ReturnType<typeof changePassword>>>
    export type ChangePasswordMutationBody = CentralChangePasswordReq
    export type ChangePasswordMutationError = unknown

    export const useChangePassword = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changePassword>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralChangePasswordReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changePassword>>, {orgId: string;projectId: string;clusterId: string;data: CentralChangePasswordReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  changePassword(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof changePassword>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralChangePasswordReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const createPrivateLinkEndpoint = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralPrivateLinkEndpoint: CentralPrivateLinkEndpoint,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCreatePrivateLinkEndpointResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/privatelink_endpoints`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralPrivateLinkEndpoint
    },
      { _metadata: { operationName: 'createPrivateLinkEndpoint', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/privatelink_endpoints', },  ...options});
    }
  


    export type CreatePrivateLinkEndpointMutationResult = NonNullable<Awaited<ReturnType<typeof createPrivateLinkEndpoint>>>
    export type CreatePrivateLinkEndpointMutationBody = CentralPrivateLinkEndpoint
    export type CreatePrivateLinkEndpointMutationError = GooglerpcStatus

    export const useCreatePrivateLinkEndpoint = <TError = GooglerpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPrivateLinkEndpoint>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralPrivateLinkEndpoint}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPrivateLinkEndpoint>>, {orgId: string;projectId: string;clusterId: string;data: CentralPrivateLinkEndpoint}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  createPrivateLinkEndpoint(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createPrivateLinkEndpoint>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralPrivateLinkEndpoint}, TContext>(mutationFn, mutationOptions);
    }
    
export const deletePrivateLinkEndpoint = (
    orgId: string,
    projectId: string,
    clusterId: string,
    endpointId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralDefaultResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/privatelink_endpoints/${endpointId}`, method: 'delete'
    },
      { _metadata: { operationName: 'deletePrivateLinkEndpoint', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/privatelink_endpoints/{endpoint_id}', },  ...options});
    }
  


    export type DeletePrivateLinkEndpointMutationResult = NonNullable<Awaited<ReturnType<typeof deletePrivateLinkEndpoint>>>
    
    export type DeletePrivateLinkEndpointMutationError = GooglerpcStatus

    export const useDeletePrivateLinkEndpoint = <TError = GooglerpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePrivateLinkEndpoint>>, TError,{orgId: string;projectId: string;clusterId: string;endpointId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePrivateLinkEndpoint>>, {orgId: string;projectId: string;clusterId: string;endpointId: string}> = (props) => {
          const {orgId,projectId,clusterId,endpointId} = props ?? {};

          return  deletePrivateLinkEndpoint(orgId,projectId,clusterId,endpointId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deletePrivateLinkEndpoint>>, TError, {orgId: string;projectId: string;clusterId: string;endpointId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const getPrivateLinkService = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetPrivateLinkServiceResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/privatelink_service`, method: 'get', signal
    },
      { _metadata: { operationName: 'getPrivateLinkService', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/privatelink_service', },  ...options});
    }
  

export const getGetPrivateLinkServiceQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/privatelink_service`];

    
export type GetPrivateLinkServiceQueryResult = NonNullable<Awaited<ReturnType<typeof getPrivateLinkService>>>
export type GetPrivateLinkServiceQueryError = GooglerpcStatus

export const useGetPrivateLinkService = <TData = Awaited<ReturnType<typeof getPrivateLinkService>>, TError = GooglerpcStatus>(
 orgId: string,
    projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPrivateLinkService>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPrivateLinkServiceQueryKey(orgId,projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPrivateLinkService>>> = ({ signal }) => getPrivateLinkService(orgId,projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getPrivateLinkService>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const deletePrivateLinkService = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralDefaultResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/privatelink_service`, method: 'delete'
    },
      { _metadata: { operationName: 'deletePrivateLinkService', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/privatelink_service', },  ...options});
    }
  


    export type DeletePrivateLinkServiceMutationResult = NonNullable<Awaited<ReturnType<typeof deletePrivateLinkService>>>
    
    export type DeletePrivateLinkServiceMutationError = GooglerpcStatus

    export const useDeletePrivateLinkService = <TError = GooglerpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePrivateLinkService>>, TError,{orgId: string;projectId: string;clusterId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePrivateLinkService>>, {orgId: string;projectId: string;clusterId: string}> = (props) => {
          const {orgId,projectId,clusterId} = props ?? {};

          return  deletePrivateLinkService(orgId,projectId,clusterId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deletePrivateLinkService>>, TError, {orgId: string;projectId: string;clusterId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary PrivateLink Service
 */
export const createPrivateLinkService = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralPrivateLinkService: CentralPrivateLinkService,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCreatePrivateLinkServiceResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/privatelink_service`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralPrivateLinkService
    },
      { _metadata: { operationName: 'createPrivateLinkService', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/privatelink_service', },  ...options});
    }
  


    export type CreatePrivateLinkServiceMutationResult = NonNullable<Awaited<ReturnType<typeof createPrivateLinkService>>>
    export type CreatePrivateLinkServiceMutationBody = CentralPrivateLinkService
    export type CreatePrivateLinkServiceMutationError = GooglerpcStatus

    export const useCreatePrivateLinkService = <TError = GooglerpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPrivateLinkService>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralPrivateLinkService}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPrivateLinkService>>, {orgId: string;projectId: string;clusterId: string;data: CentralPrivateLinkService}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  createPrivateLinkService(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createPrivateLinkService>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralPrivateLinkService}, TContext>(mutationFn, mutationOptions);
    }
    
export const sendPasswordVerifyCode = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralSendPasswordVerifyCodeReq: CentralSendPasswordVerifyCodeReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/password-verify-code`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralSendPasswordVerifyCodeReq
    },
      { _metadata: { operationName: 'sendPasswordVerifyCode', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/password-verify-code', },  ...options});
    }
  


    export type SendPasswordVerifyCodeMutationResult = NonNullable<Awaited<ReturnType<typeof sendPasswordVerifyCode>>>
    export type SendPasswordVerifyCodeMutationBody = CentralSendPasswordVerifyCodeReq
    export type SendPasswordVerifyCodeMutationError = unknown

    export const useSendPasswordVerifyCode = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendPasswordVerifyCode>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralSendPasswordVerifyCodeReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendPasswordVerifyCode>>, {orgId: string;projectId: string;clusterId: string;data: CentralSendPasswordVerifyCodeReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  sendPasswordVerifyCode(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof sendPasswordVerifyCode>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralSendPasswordVerifyCodeReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getTiDBNodes = (
    orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetTiDBNodesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralTiDBNodes>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/tidb_nodes`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getTiDBNodes', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/tidb_nodes', },  ...options});
    }
  

export const getGetTiDBNodesQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetTiDBNodesParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/tidb_nodes`, ...(params ? [params]: [])];

    
export type GetTiDBNodesQueryResult = NonNullable<Awaited<ReturnType<typeof getTiDBNodes>>>
export type GetTiDBNodesQueryError = unknown

export const useGetTiDBNodes = <TData = Awaited<ReturnType<typeof getTiDBNodes>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetTiDBNodesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTiDBNodes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTiDBNodesQueryKey(orgId,projectId,clusterId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTiDBNodes>>> = ({ signal }) => getTiDBNodes(orgId,projectId,clusterId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTiDBNodes>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getTiFlashNodes = (
    orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetTiFlashNodesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralTiFlashNodes>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/tiflash_nodes`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getTiFlashNodes', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/tiflash_nodes', },  ...options});
    }
  

export const getGetTiFlashNodesQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetTiFlashNodesParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/tiflash_nodes`, ...(params ? [params]: [])];

    
export type GetTiFlashNodesQueryResult = NonNullable<Awaited<ReturnType<typeof getTiFlashNodes>>>
export type GetTiFlashNodesQueryError = unknown

export const useGetTiFlashNodes = <TData = Awaited<ReturnType<typeof getTiFlashNodes>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetTiFlashNodesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTiFlashNodes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTiFlashNodesQueryKey(orgId,projectId,clusterId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTiFlashNodes>>> = ({ signal }) => getTiFlashNodes(orgId,projectId,clusterId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTiFlashNodes>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getTiKVNodes = (
    orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetTiKVNodesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralTiKVNodes>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/tikv_nodes`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getTiKVNodes', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/tikv_nodes', },  ...options});
    }
  

export const getGetTiKVNodesQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetTiKVNodesParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/tikv_nodes`, ...(params ? [params]: [])];

    
export type GetTiKVNodesQueryResult = NonNullable<Awaited<ReturnType<typeof getTiKVNodes>>>
export type GetTiKVNodesQueryError = unknown

export const useGetTiKVNodes = <TData = Awaited<ReturnType<typeof getTiKVNodes>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string,
    params?: GetTiKVNodesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTiKVNodes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTiKVNodesQueryKey(orgId,projectId,clusterId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTiKVNodes>>> = ({ signal }) => getTiKVNodes(orgId,projectId,clusterId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTiKVNodes>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getTrafficFilter = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralTrafficFilterResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/traffic_filter`, method: 'get', signal
    },
      { _metadata: { operationName: 'getTrafficFilter', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/traffic_filter', },  ...options});
    }
  

export const getGetTrafficFilterQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/traffic_filter`];

    
export type GetTrafficFilterQueryResult = NonNullable<Awaited<ReturnType<typeof getTrafficFilter>>>
export type GetTrafficFilterQueryError = unknown

export const useGetTrafficFilter = <TData = Awaited<ReturnType<typeof getTrafficFilter>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTrafficFilter>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTrafficFilterQueryKey(orgId,projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTrafficFilter>>> = ({ signal }) => getTrafficFilter(orgId,projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTrafficFilter>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const updateTrafficFilter = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralUpdateTrafficFilterReq: CentralUpdateTrafficFilterReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/traffic_filter`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralUpdateTrafficFilterReq
    },
      { _metadata: { operationName: 'updateTrafficFilter', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/traffic_filter', },  ...options});
    }
  


    export type UpdateTrafficFilterMutationResult = NonNullable<Awaited<ReturnType<typeof updateTrafficFilter>>>
    export type UpdateTrafficFilterMutationBody = CentralUpdateTrafficFilterReq
    export type UpdateTrafficFilterMutationError = unknown

    export const useUpdateTrafficFilter = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTrafficFilter>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralUpdateTrafficFilterReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTrafficFilter>>, {orgId: string;projectId: string;clusterId: string;data: CentralUpdateTrafficFilterReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  updateTrafficFilter(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateTrafficFilter>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralUpdateTrafficFilterReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const makeClusterWritable = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralMakeClusterWritableReq: CentralMakeClusterWritableReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/writable`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: centralMakeClusterWritableReq
    },
      { _metadata: { operationName: 'makeClusterWritable', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/writable', },  ...options});
    }
  


    export type MakeClusterWritableMutationResult = NonNullable<Awaited<ReturnType<typeof makeClusterWritable>>>
    export type MakeClusterWritableMutationBody = CentralMakeClusterWritableReq
    export type MakeClusterWritableMutationError = unknown

    export const useMakeClusterWritable = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof makeClusterWritable>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralMakeClusterWritableReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof makeClusterWritable>>, {orgId: string;projectId: string;clusterId: string;data: CentralMakeClusterWritableReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  makeClusterWritable(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof makeClusterWritable>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralMakeClusterWritableReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getCluster = (
    orgId: string,
    projectId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetClusterResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${id}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getCluster', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{id}', },  ...options});
    }
  

export const getGetClusterQueryKey = (orgId: string,
    projectId: string,
    id: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${id}`];

    
export type GetClusterQueryResult = NonNullable<Awaited<ReturnType<typeof getCluster>>>
export type GetClusterQueryError = unknown

export const useGetCluster = <TData = Awaited<ReturnType<typeof getCluster>>, TError = unknown>(
 orgId: string,
    projectId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCluster>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClusterQueryKey(orgId,projectId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCluster>>> = ({ signal }) => getCluster(orgId,projectId,id, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getCluster>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getSecondaryClusterProfiles = (
    orgId: string,
    projectId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetSecondaryClusterProfilesResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${id}/secondary_cluster_profiles`, method: 'get', signal
    },
      { _metadata: { operationName: 'getSecondaryClusterProfiles', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{id}/secondary_cluster_profiles', },  ...options});
    }
  

export const getGetSecondaryClusterProfilesQueryKey = (orgId: string,
    projectId: string,
    id: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${id}/secondary_cluster_profiles`];

    
export type GetSecondaryClusterProfilesQueryResult = NonNullable<Awaited<ReturnType<typeof getSecondaryClusterProfiles>>>
export type GetSecondaryClusterProfilesQueryError = unknown

export const useGetSecondaryClusterProfiles = <TData = Awaited<ReturnType<typeof getSecondaryClusterProfiles>>, TError = unknown>(
 orgId: string,
    projectId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSecondaryClusterProfiles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSecondaryClusterProfilesQueryKey(orgId,projectId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSecondaryClusterProfiles>>> = ({ signal }) => getSecondaryClusterProfiles(orgId,projectId,id, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getSecondaryClusterProfiles>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createClusterV2 = (
    orgId: string,
    projectId: string,
    centralCreateClusterReq: CentralCreateClusterReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCreateClusterResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clustersv2`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateClusterReq
    },
      { _metadata: { operationName: 'createClusterV2', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clustersv2', },  ...options});
    }
  


    export type CreateClusterV2MutationResult = NonNullable<Awaited<ReturnType<typeof createClusterV2>>>
    export type CreateClusterV2MutationBody = CentralCreateClusterReq
    export type CreateClusterV2MutationError = unknown

    export const useCreateClusterV2 = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createClusterV2>>, TError,{orgId: string;projectId: string;data: CentralCreateClusterReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createClusterV2>>, {orgId: string;projectId: string;data: CentralCreateClusterReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  createClusterV2(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createClusterV2>>, TError, {orgId: string;projectId: string;data: CentralCreateClusterReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const pauseCluster = (
    orgId: string,
    projectId: string,
    clusterId: string,
    pauseClusterBodyBody: PauseClusterBodyBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralDefaultResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/pause`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: pauseClusterBodyBody
    },
      { _metadata: { operationName: 'pauseCluster', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/pause', },  ...options});
    }
  


    export type PauseClusterMutationResult = NonNullable<Awaited<ReturnType<typeof pauseCluster>>>
    export type PauseClusterMutationBody = PauseClusterBodyBody
    export type PauseClusterMutationError = GooglerpcStatus

    export const usePauseCluster = <TError = GooglerpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pauseCluster>>, TError,{orgId: string;projectId: string;clusterId: string;data: PauseClusterBodyBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pauseCluster>>, {orgId: string;projectId: string;clusterId: string;data: PauseClusterBodyBody}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  pauseCluster(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof pauseCluster>>, TError, {orgId: string;projectId: string;clusterId: string;data: PauseClusterBodyBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const resumeCluster = (
    orgId: string,
    projectId: string,
    clusterId: string,
    pauseClusterBodyBody: PauseClusterBodyBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralDefaultResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/resume`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: pauseClusterBodyBody
    },
      { _metadata: { operationName: 'resumeCluster', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/resume', },  ...options});
    }
  


    export type ResumeClusterMutationResult = NonNullable<Awaited<ReturnType<typeof resumeCluster>>>
    export type ResumeClusterMutationBody = PauseClusterBodyBody
    export type ResumeClusterMutationError = GooglerpcStatus

    export const useResumeCluster = <TError = GooglerpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resumeCluster>>, TError,{orgId: string;projectId: string;clusterId: string;data: PauseClusterBodyBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resumeCluster>>, {orgId: string;projectId: string;clusterId: string;data: PauseClusterBodyBody}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  resumeCluster(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof resumeCluster>>, TError, {orgId: string;projectId: string;clusterId: string;data: PauseClusterBodyBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const scaleClusterV2 = (
    orgId: string,
    projectId: string,
    clusterId: string,
    centralScaleClusterReq: CentralScaleClusterReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralDefaultResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clustersv2/${clusterId}/components`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: centralScaleClusterReq
    },
      { _metadata: { operationName: 'scaleClusterV2', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clustersv2/{cluster_id}/components', },  ...options});
    }
  


    export type ScaleClusterV2MutationResult = NonNullable<Awaited<ReturnType<typeof scaleClusterV2>>>
    export type ScaleClusterV2MutationBody = CentralScaleClusterReq
    export type ScaleClusterV2MutationError = unknown

    export const useScaleClusterV2 = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scaleClusterV2>>, TError,{orgId: string;projectId: string;clusterId: string;data: CentralScaleClusterReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scaleClusterV2>>, {orgId: string;projectId: string;clusterId: string;data: CentralScaleClusterReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  scaleClusterV2(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof scaleClusterV2>>, TError, {orgId: string;projectId: string;clusterId: string;data: CentralScaleClusterReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const listCrossAccountDeployments = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralListCrossAccountDeploymentResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/cross_account_deployments`, method: 'get', signal
    },
      { _metadata: { operationName: 'listCrossAccountDeployments', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/cross_account_deployments', },  ...options});
    }
  

export const getListCrossAccountDeploymentsQueryKey = (orgId: string,
    projectId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/cross_account_deployments`];

    
export type ListCrossAccountDeploymentsQueryResult = NonNullable<Awaited<ReturnType<typeof listCrossAccountDeployments>>>
export type ListCrossAccountDeploymentsQueryError = RuntimeError

export const useListCrossAccountDeployments = <TData = Awaited<ReturnType<typeof listCrossAccountDeployments>>, TError = RuntimeError>(
 orgId: string,
    projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listCrossAccountDeployments>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListCrossAccountDeploymentsQueryKey(orgId,projectId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listCrossAccountDeployments>>> = ({ signal }) => listCrossAccountDeployments(orgId,projectId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listCrossAccountDeployments>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Cross account deployment
 */
export const createCrossAccountDeployment = (
    orgId: string,
    projectId: string,
    centralCreateCrossAccountDeploymentReq: CentralCreateCrossAccountDeploymentReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCreateCrossAccountDeploymentResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/cross_account_deployments`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateCrossAccountDeploymentReq
    },
      { _metadata: { operationName: 'createCrossAccountDeployment', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/cross_account_deployments', },  ...options});
    }
  


    export type CreateCrossAccountDeploymentMutationResult = NonNullable<Awaited<ReturnType<typeof createCrossAccountDeployment>>>
    export type CreateCrossAccountDeploymentMutationBody = CentralCreateCrossAccountDeploymentReq
    export type CreateCrossAccountDeploymentMutationError = RuntimeError

    export const useCreateCrossAccountDeployment = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCrossAccountDeployment>>, TError,{orgId: string;projectId: string;data: CentralCreateCrossAccountDeploymentReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCrossAccountDeployment>>, {orgId: string;projectId: string;data: CentralCreateCrossAccountDeploymentReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  createCrossAccountDeployment(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createCrossAccountDeployment>>, TError, {orgId: string;projectId: string;data: CentralCreateCrossAccountDeploymentReq}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary Give TiDB Cloud delegation info to customers
 */
export const getTiDBCloudDelegationInfo = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetTiDBCloudDelegationInfoResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/cross_account_deployments/delegation_info`, method: 'get', signal
    },
      { _metadata: { operationName: 'getTiDBCloudDelegationInfo', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/cross_account_deployments/delegation_info', },  ...options});
    }
  

export const getGetTiDBCloudDelegationInfoQueryKey = (orgId: string,
    projectId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/cross_account_deployments/delegation_info`];

    
export type GetTiDBCloudDelegationInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getTiDBCloudDelegationInfo>>>
export type GetTiDBCloudDelegationInfoQueryError = RuntimeError

export const useGetTiDBCloudDelegationInfo = <TData = Awaited<ReturnType<typeof getTiDBCloudDelegationInfo>>, TError = RuntimeError>(
 orgId: string,
    projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTiDBCloudDelegationInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTiDBCloudDelegationInfoQueryKey(orgId,projectId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTiDBCloudDelegationInfo>>> = ({ signal }) => getTiDBCloudDelegationInfo(orgId,projectId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTiDBCloudDelegationInfo>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const deleteCrossAccountDeployment = (
    orgId: string,
    projectId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralDefaultResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/cross_account_deployments/${id}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteCrossAccountDeployment', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/cross_account_deployments/{id}', },  ...options});
    }
  


    export type DeleteCrossAccountDeploymentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCrossAccountDeployment>>>
    
    export type DeleteCrossAccountDeploymentMutationError = RuntimeError

    export const useDeleteCrossAccountDeployment = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCrossAccountDeployment>>, TError,{orgId: string;projectId: string;id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCrossAccountDeployment>>, {orgId: string;projectId: string;id: string}> = (props) => {
          const {orgId,projectId,id} = props ?? {};

          return  deleteCrossAccountDeployment(orgId,projectId,id,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteCrossAccountDeployment>>, TError, {orgId: string;projectId: string;id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const listGcpVpcPeerings = (
    orgId: string,
    projectId: string,
    params?: ListGcpVpcPeeringsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGcpVpcPeerings>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/gcp_vpc_peerings`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listGcpVpcPeerings', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/gcp_vpc_peerings', },  ...options});
    }
  

export const getListGcpVpcPeeringsQueryKey = (orgId: string,
    projectId: string,
    params?: ListGcpVpcPeeringsParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/gcp_vpc_peerings`, ...(params ? [params]: [])];

    
export type ListGcpVpcPeeringsQueryResult = NonNullable<Awaited<ReturnType<typeof listGcpVpcPeerings>>>
export type ListGcpVpcPeeringsQueryError = unknown

export const useListGcpVpcPeerings = <TData = Awaited<ReturnType<typeof listGcpVpcPeerings>>, TError = unknown>(
 orgId: string,
    projectId: string,
    params?: ListGcpVpcPeeringsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listGcpVpcPeerings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListGcpVpcPeeringsQueryKey(orgId,projectId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listGcpVpcPeerings>>> = ({ signal }) => listGcpVpcPeerings(orgId,projectId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listGcpVpcPeerings>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createGcpVpcPeering = (
    orgId: string,
    projectId: string,
    centralCreateGcpVpcPeeringReq: CentralCreateGcpVpcPeeringReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCreateGcpVpcPeeringResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/gcp_vpc_peerings`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateGcpVpcPeeringReq
    },
      { _metadata: { operationName: 'createGcpVpcPeering', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/gcp_vpc_peerings', },  ...options});
    }
  


    export type CreateGcpVpcPeeringMutationResult = NonNullable<Awaited<ReturnType<typeof createGcpVpcPeering>>>
    export type CreateGcpVpcPeeringMutationBody = CentralCreateGcpVpcPeeringReq
    export type CreateGcpVpcPeeringMutationError = unknown

    export const useCreateGcpVpcPeering = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGcpVpcPeering>>, TError,{orgId: string;projectId: string;data: CentralCreateGcpVpcPeeringReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGcpVpcPeering>>, {orgId: string;projectId: string;data: CentralCreateGcpVpcPeeringReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  createGcpVpcPeering(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createGcpVpcPeering>>, TError, {orgId: string;projectId: string;data: CentralCreateGcpVpcPeeringReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getGcpVpcPeering = (
    orgId: string,
    projectId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGcpVpcPeering>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/gcp_vpc_peerings/${id}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getGcpVpcPeering', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/gcp_vpc_peerings/{id}', },  ...options});
    }
  

export const getGetGcpVpcPeeringQueryKey = (orgId: string,
    projectId: string,
    id: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/gcp_vpc_peerings/${id}`];

    
export type GetGcpVpcPeeringQueryResult = NonNullable<Awaited<ReturnType<typeof getGcpVpcPeering>>>
export type GetGcpVpcPeeringQueryError = unknown

export const useGetGcpVpcPeering = <TData = Awaited<ReturnType<typeof getGcpVpcPeering>>, TError = unknown>(
 orgId: string,
    projectId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGcpVpcPeering>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGcpVpcPeeringQueryKey(orgId,projectId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGcpVpcPeering>>> = ({ signal }) => getGcpVpcPeering(orgId,projectId,id, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getGcpVpcPeering>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const deleteGcpVpcPeering = (
    orgId: string,
    projectId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/gcp_vpc_peerings/${id}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteGcpVpcPeering', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/gcp_vpc_peerings/{id}', },  ...options});
    }
  


    export type DeleteGcpVpcPeeringMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGcpVpcPeering>>>
    
    export type DeleteGcpVpcPeeringMutationError = unknown

    export const useDeleteGcpVpcPeering = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGcpVpcPeering>>, TError,{orgId: string;projectId: string;id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGcpVpcPeering>>, {orgId: string;projectId: string;id: string}> = (props) => {
          const {orgId,projectId,id} = props ?? {};

          return  deleteGcpVpcPeering(orgId,projectId,id,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteGcpVpcPeering>>, TError, {orgId: string;projectId: string;id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const validateNewrelicAPIKey = (
    orgId: string,
    projectId: string,
    centralIntegrationNewrelicValidateReq: CentralIntegrationNewrelicValidateReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralIntegrationNewrelicValidateResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/integrations/newrelic/api_key/validate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralIntegrationNewrelicValidateReq
    },
      { _metadata: { operationName: 'validateNewrelicAPIKey', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/integrations/newrelic/api_key/validate', },  ...options});
    }
  


    export type ValidateNewrelicAPIKeyMutationResult = NonNullable<Awaited<ReturnType<typeof validateNewrelicAPIKey>>>
    export type ValidateNewrelicAPIKeyMutationBody = CentralIntegrationNewrelicValidateReq
    export type ValidateNewrelicAPIKeyMutationError = RuntimeError

    export const useValidateNewrelicAPIKey = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateNewrelicAPIKey>>, TError,{orgId: string;projectId: string;data: CentralIntegrationNewrelicValidateReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof validateNewrelicAPIKey>>, {orgId: string;projectId: string;data: CentralIntegrationNewrelicValidateReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  validateNewrelicAPIKey(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof validateNewrelicAPIKey>>, TError, {orgId: string;projectId: string;data: CentralIntegrationNewrelicValidateReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getAllMetricsKey = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetAllMetricsKeyResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/metrics_key`, method: 'get', signal
    },
      { _metadata: { operationName: 'getAllMetricsKey', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/metrics_key', },  ...options});
    }
  

export const getGetAllMetricsKeyQueryKey = (orgId: string,
    projectId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/metrics_key`];

    
export type GetAllMetricsKeyQueryResult = NonNullable<Awaited<ReturnType<typeof getAllMetricsKey>>>
export type GetAllMetricsKeyQueryError = unknown

export const useGetAllMetricsKey = <TData = Awaited<ReturnType<typeof getAllMetricsKey>>, TError = unknown>(
 orgId: string,
    projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllMetricsKey>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllMetricsKeyQueryKey(orgId,projectId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllMetricsKey>>> = ({ signal }) => getAllMetricsKey(orgId,projectId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAllMetricsKey>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createMetricsKey = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCreateMetricsKeyResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/metrics_key`, method: 'post'
    },
      { _metadata: { operationName: 'createMetricsKey', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/metrics_key', },  ...options});
    }
  


    export type CreateMetricsKeyMutationResult = NonNullable<Awaited<ReturnType<typeof createMetricsKey>>>
    
    export type CreateMetricsKeyMutationError = unknown

    export const useCreateMetricsKey = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMetricsKey>>, TError,{orgId: string;projectId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMetricsKey>>, {orgId: string;projectId: string}> = (props) => {
          const {orgId,projectId} = props ?? {};

          return  createMetricsKey(orgId,projectId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createMetricsKey>>, TError, {orgId: string;projectId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const deleteMetricsKey = (
    orgId: string,
    projectId: string,
    keyId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/metrics_key/${keyId}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteMetricsKey', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/metrics_key/{key_id}', },  ...options});
    }
  


    export type DeleteMetricsKeyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMetricsKey>>>
    
    export type DeleteMetricsKeyMutationError = unknown

    export const useDeleteMetricsKey = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMetricsKey>>, TError,{orgId: string;projectId: string;keyId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMetricsKey>>, {orgId: string;projectId: string;keyId: string}> = (props) => {
          const {orgId,projectId,keyId} = props ?? {};

          return  deleteMetricsKey(orgId,projectId,keyId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteMetricsKey>>, TError, {orgId: string;projectId: string;keyId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary IAM roles
 */
export const listIamRoles = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralListIamRolesResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/iam_roles`, method: 'get', signal
    },
      { _metadata: { operationName: 'listIamRoles', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/iam_roles', },  ...options});
    }
  

export const getListIamRolesQueryKey = (orgId: string,
    projectId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/iam_roles`];

    
export type ListIamRolesQueryResult = NonNullable<Awaited<ReturnType<typeof listIamRoles>>>
export type ListIamRolesQueryError = RuntimeError

export const useListIamRoles = <TData = Awaited<ReturnType<typeof listIamRoles>>, TError = RuntimeError>(
 orgId: string,
    projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIamRoles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListIamRolesQueryKey(orgId,projectId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listIamRoles>>> = ({ signal }) => listIamRoles(orgId,projectId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listIamRoles>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary IAM roles
 */
export const createIamRole = (
    orgId: string,
    projectId: string,
    centralCreateIamRoleReq: CentralCreateIamRoleReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCreateIamRoleResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/iam_roles`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateIamRoleReq
    },
      { _metadata: { operationName: 'createIamRole', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/iam_roles', },  ...options});
    }
  


    export type CreateIamRoleMutationResult = NonNullable<Awaited<ReturnType<typeof createIamRole>>>
    export type CreateIamRoleMutationBody = CentralCreateIamRoleReq
    export type CreateIamRoleMutationError = RuntimeError

    export const useCreateIamRole = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIamRole>>, TError,{orgId: string;projectId: string;data: CentralCreateIamRoleReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createIamRole>>, {orgId: string;projectId: string;data: CentralCreateIamRoleReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  createIamRole(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createIamRole>>, TError, {orgId: string;projectId: string;data: CentralCreateIamRoleReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const deleteIamRole = (
    orgId: string,
    projectId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralDefaultResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/iam_roles/${id}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteIamRole', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/iam_roles/{id}', },  ...options});
    }
  


    export type DeleteIamRoleMutationResult = NonNullable<Awaited<ReturnType<typeof deleteIamRole>>>
    
    export type DeleteIamRoleMutationError = RuntimeError

    export const useDeleteIamRole = <TError = RuntimeError,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIamRole>>, TError,{orgId: string;projectId: string;id: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteIamRole>>, {orgId: string;projectId: string;id: string}> = (props) => {
          const {orgId,projectId,id} = props ?? {};

          return  deleteIamRole(orgId,projectId,id,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteIamRole>>, TError, {orgId: string;projectId: string;id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const listPrivateLinkEndpoints = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralListPrivateLinkEndpointsResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/privatelink_endpoints`, method: 'get', signal
    },
      { _metadata: { operationName: 'listPrivateLinkEndpoints', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/privatelink_endpoints', },  ...options});
    }
  

export const getListPrivateLinkEndpointsQueryKey = (orgId: string,
    projectId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/privatelink_endpoints`];

    
export type ListPrivateLinkEndpointsQueryResult = NonNullable<Awaited<ReturnType<typeof listPrivateLinkEndpoints>>>
export type ListPrivateLinkEndpointsQueryError = GooglerpcStatus

export const useListPrivateLinkEndpoints = <TData = Awaited<ReturnType<typeof listPrivateLinkEndpoints>>, TError = GooglerpcStatus>(
 orgId: string,
    projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listPrivateLinkEndpoints>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListPrivateLinkEndpointsQueryKey(orgId,projectId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPrivateLinkEndpoints>>> = ({ signal }) => listPrivateLinkEndpoints(orgId,projectId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listPrivateLinkEndpoints>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const listIntegrations = (
    orgId: string,
    projectId: string,
    params?: ListIntegrationsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralIntegrationListResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/integrations`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listIntegrations', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/integrations', },  ...options});
    }
  

export const getListIntegrationsQueryKey = (orgId: string,
    projectId: string,
    params?: ListIntegrationsParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/integrations`, ...(params ? [params]: [])];

    
export type ListIntegrationsQueryResult = NonNullable<Awaited<ReturnType<typeof listIntegrations>>>
export type ListIntegrationsQueryError = unknown

export const useListIntegrations = <TData = Awaited<ReturnType<typeof listIntegrations>>, TError = unknown>(
 orgId: string,
    projectId: string,
    params?: ListIntegrationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listIntegrations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListIntegrationsQueryKey(orgId,projectId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listIntegrations>>> = ({ signal }) => listIntegrations(orgId,projectId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listIntegrations>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const deleteIntegration = (
    orgId: string,
    projectId: string,
    params?: DeleteIntegrationParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralIntegrationStatusResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/integrations`, method: 'delete',
        params
    },
      { _metadata: { operationName: 'deleteIntegration', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/integrations', },  ...options});
    }
  


    export type DeleteIntegrationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteIntegration>>>
    
    export type DeleteIntegrationMutationError = unknown

    export const useDeleteIntegration = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteIntegration>>, TError,{orgId: string;projectId: string;params?: DeleteIntegrationParams}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteIntegration>>, {orgId: string;projectId: string;params?: DeleteIntegrationParams}> = (props) => {
          const {orgId,projectId,params} = props ?? {};

          return  deleteIntegration(orgId,projectId,params,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteIntegration>>, TError, {orgId: string;projectId: string;params?: DeleteIntegrationParams}, TContext>(mutationFn, mutationOptions);
    }
    
export const createIntegration = (
    orgId: string,
    projectId: string,
    centralIntegrationCreateReq: CentralIntegrationCreateReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralIntegrationStatusResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/integrations`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralIntegrationCreateReq
    },
      { _metadata: { operationName: 'createIntegration', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/integrations', },  ...options});
    }
  


    export type CreateIntegrationMutationResult = NonNullable<Awaited<ReturnType<typeof createIntegration>>>
    export type CreateIntegrationMutationBody = CentralIntegrationCreateReq
    export type CreateIntegrationMutationError = unknown

    export const useCreateIntegration = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createIntegration>>, TError,{orgId: string;projectId: string;data: CentralIntegrationCreateReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createIntegration>>, {orgId: string;projectId: string;data: CentralIntegrationCreateReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  createIntegration(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createIntegration>>, TError, {orgId: string;projectId: string;data: CentralIntegrationCreateReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const validateDatadogAPIKey = (
    orgId: string,
    projectId: string,
    centralIntegrationDatadogValidateReq: CentralIntegrationDatadogValidateReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralIntegrationDatadogValidateResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/integrations/datadog/api_key/validate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralIntegrationDatadogValidateReq
    },
      { _metadata: { operationName: 'validateDatadogAPIKey', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/integrations/datadog/api_key/validate', },  ...options});
    }
  


    export type ValidateDatadogAPIKeyMutationResult = NonNullable<Awaited<ReturnType<typeof validateDatadogAPIKey>>>
    export type ValidateDatadogAPIKeyMutationBody = CentralIntegrationDatadogValidateReq
    export type ValidateDatadogAPIKeyMutationError = unknown

    export const useValidateDatadogAPIKey = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateDatadogAPIKey>>, TError,{orgId: string;projectId: string;data: CentralIntegrationDatadogValidateReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof validateDatadogAPIKey>>, {orgId: string;projectId: string;data: CentralIntegrationDatadogValidateReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  validateDatadogAPIKey(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof validateDatadogAPIKey>>, TError, {orgId: string;projectId: string;data: CentralIntegrationDatadogValidateReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const listDeletedClusters = (
    orgId: string,
    projectId: string,
    params?: ListDeletedClustersParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralDeletedClusters>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/recycle-bin`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listDeletedClusters', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/recycle-bin', },  ...options});
    }
  

export const getListDeletedClustersQueryKey = (orgId: string,
    projectId: string,
    params?: ListDeletedClustersParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/recycle-bin`, ...(params ? [params]: [])];

    
export type ListDeletedClustersQueryResult = NonNullable<Awaited<ReturnType<typeof listDeletedClusters>>>
export type ListDeletedClustersQueryError = unknown

export const useListDeletedClusters = <TData = Awaited<ReturnType<typeof listDeletedClusters>>, TError = unknown>(
 orgId: string,
    projectId: string,
    params?: ListDeletedClustersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listDeletedClusters>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListDeletedClustersQueryKey(orgId,projectId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDeletedClusters>>> = ({ signal }) => listDeletedClusters(orgId,projectId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listDeletedClusters>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getDeletedCluster = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralDeletedCluster>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/recycle-bin/${clusterId}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getDeletedCluster', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/recycle-bin/{cluster_id}', },  ...options});
    }
  

export const getGetDeletedClusterQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/recycle-bin/${clusterId}`];

    
export type GetDeletedClusterQueryResult = NonNullable<Awaited<ReturnType<typeof getDeletedCluster>>>
export type GetDeletedClusterQueryError = unknown

export const useGetDeletedCluster = <TData = Awaited<ReturnType<typeof getDeletedCluster>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDeletedCluster>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDeletedClusterQueryKey(orgId,projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeletedCluster>>> = ({ signal }) => getDeletedCluster(orgId,projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getDeletedCluster>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getProjectTrafficFilter = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralTrafficFilterResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/traffic_filter`, method: 'get', signal
    },
      { _metadata: { operationName: 'getProjectTrafficFilter', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/traffic_filter', },  ...options});
    }
  

export const getGetProjectTrafficFilterQueryKey = (orgId: string,
    projectId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/traffic_filter`];

    
export type GetProjectTrafficFilterQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectTrafficFilter>>>
export type GetProjectTrafficFilterQueryError = unknown

export const useGetProjectTrafficFilter = <TData = Awaited<ReturnType<typeof getProjectTrafficFilter>>, TError = unknown>(
 orgId: string,
    projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectTrafficFilter>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectTrafficFilterQueryKey(orgId,projectId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectTrafficFilter>>> = ({ signal }) => getProjectTrafficFilter(orgId,projectId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getProjectTrafficFilter>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const updateProjectTrafficFilter = (
    orgId: string,
    projectId: string,
    centralUpdateProjectTrafficFilterReq: CentralUpdateProjectTrafficFilterReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/traffic_filter`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralUpdateProjectTrafficFilterReq
    },
      { _metadata: { operationName: 'updateProjectTrafficFilter', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/traffic_filter', },  ...options});
    }
  


    export type UpdateProjectTrafficFilterMutationResult = NonNullable<Awaited<ReturnType<typeof updateProjectTrafficFilter>>>
    export type UpdateProjectTrafficFilterMutationBody = CentralUpdateProjectTrafficFilterReq
    export type UpdateProjectTrafficFilterMutationError = unknown

    export const useUpdateProjectTrafficFilter = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectTrafficFilter>>, TError,{orgId: string;projectId: string;data: CentralUpdateProjectTrafficFilterReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProjectTrafficFilter>>, {orgId: string;projectId: string;data: CentralUpdateProjectTrafficFilterReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  updateProjectTrafficFilter(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateProjectTrafficFilter>>, TError, {orgId: string;projectId: string;data: CentralUpdateProjectTrafficFilterReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getUserAlertList = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetUserAlertListResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/user_alert`, method: 'get', signal
    },
      { _metadata: { operationName: 'getUserAlertList', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/user_alert', },  ...options});
    }
  

export const getGetUserAlertListQueryKey = (orgId: string,
    projectId: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/user_alert`];

    
export type GetUserAlertListQueryResult = NonNullable<Awaited<ReturnType<typeof getUserAlertList>>>
export type GetUserAlertListQueryError = unknown

export const useGetUserAlertList = <TData = Awaited<ReturnType<typeof getUserAlertList>>, TError = unknown>(
 orgId: string,
    projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserAlertList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserAlertListQueryKey(orgId,projectId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserAlertList>>> = ({ signal }) => getUserAlertList(orgId,projectId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserAlertList>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const subscribeUserAlert = (
    orgId: string,
    projectId: string,
    centralSubscribeUserAlertReq: CentralSubscribeUserAlertReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralSubscribeUserAlertResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/user_alert`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralSubscribeUserAlertReq
    },
      { _metadata: { operationName: 'subscribeUserAlert', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/user_alert', },  ...options});
    }
  


    export type SubscribeUserAlertMutationResult = NonNullable<Awaited<ReturnType<typeof subscribeUserAlert>>>
    export type SubscribeUserAlertMutationBody = CentralSubscribeUserAlertReq
    export type SubscribeUserAlertMutationError = unknown

    export const useSubscribeUserAlert = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof subscribeUserAlert>>, TError,{orgId: string;projectId: string;data: CentralSubscribeUserAlertReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof subscribeUserAlert>>, {orgId: string;projectId: string;data: CentralSubscribeUserAlertReq}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  subscribeUserAlert(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof subscribeUserAlert>>, TError, {orgId: string;projectId: string;data: CentralSubscribeUserAlertReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const unsubscribeUserAlert = (
    orgId: string,
    projectId: string,
    userAlertId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralUnsubscribeUserAlertResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/user_alert/${userAlertId}`, method: 'delete'
    },
      { _metadata: { operationName: 'unsubscribeUserAlert', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/user_alert/{user_alert_id}', },  ...options});
    }
  


    export type UnsubscribeUserAlertMutationResult = NonNullable<Awaited<ReturnType<typeof unsubscribeUserAlert>>>
    
    export type UnsubscribeUserAlertMutationError = unknown

    export const useUnsubscribeUserAlert = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unsubscribeUserAlert>>, TError,{orgId: string;projectId: string;userAlertId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unsubscribeUserAlert>>, {orgId: string;projectId: string;userAlertId: string}> = (props) => {
          const {orgId,projectId,userAlertId} = props ?? {};

          return  unsubscribeUserAlert(orgId,projectId,userAlertId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof unsubscribeUserAlert>>, TError, {orgId: string;projectId: string;userAlertId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const listProjectUsers = (
    orgId: string,
    projectId: string,
    params?: ListProjectUsersParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralUsers>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/users`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listProjectUsers', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/users', },  ...options});
    }
  

export const getListProjectUsersQueryKey = (orgId: string,
    projectId: string,
    params?: ListProjectUsersParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/users`, ...(params ? [params]: [])];

    
export type ListProjectUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listProjectUsers>>>
export type ListProjectUsersQueryError = unknown

export const useListProjectUsers = <TData = Awaited<ReturnType<typeof listProjectUsers>>, TError = unknown>(
 orgId: string,
    projectId: string,
    params?: ListProjectUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listProjectUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListProjectUsersQueryKey(orgId,projectId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listProjectUsers>>> = ({ signal }) => listProjectUsers(orgId,projectId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listProjectUsers>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const inviteUser = (
    orgId: string,
    projectId: string,
    inviteUserBody: InviteUserBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/users`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inviteUserBody
    },
      { _metadata: { operationName: 'inviteUser', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/users', },  ...options});
    }
  


    export type InviteUserMutationResult = NonNullable<Awaited<ReturnType<typeof inviteUser>>>
    export type InviteUserMutationBody = InviteUserBody
    export type InviteUserMutationError = unknown

    export const useInviteUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inviteUser>>, TError,{orgId: string;projectId: string;data: InviteUserBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inviteUser>>, {orgId: string;projectId: string;data: InviteUserBody}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  inviteUser(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof inviteUser>>, TError, {orgId: string;projectId: string;data: InviteUserBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const deleteProjectUser = (
    orgId: string,
    projectId: string,
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/users/${userId}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteProjectUser', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/users/{user_id}', },  ...options});
    }
  


    export type DeleteProjectUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectUser>>>
    
    export type DeleteProjectUserMutationError = unknown

    export const useDeleteProjectUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectUser>>, TError,{orgId: string;projectId: string;userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectUser>>, {orgId: string;projectId: string;userId: string}> = (props) => {
          const {orgId,projectId,userId} = props ?? {};

          return  deleteProjectUser(orgId,projectId,userId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteProjectUser>>, TError, {orgId: string;projectId: string;userId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const updateProjectUserRole = (
    orgId: string,
    projectId: string,
    userId: string,
    updateProjectUserRoleBody: UpdateProjectUserRoleBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateProjectUserRole200>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/users/${userId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateProjectUserRoleBody
    },
      { _metadata: { operationName: 'updateProjectUserRole', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/users/{user_id}', },  ...options});
    }
  


    export type UpdateProjectUserRoleMutationResult = NonNullable<Awaited<ReturnType<typeof updateProjectUserRole>>>
    export type UpdateProjectUserRoleMutationBody = UpdateProjectUserRoleBody
    export type UpdateProjectUserRoleMutationError = RpcStatus

    export const useUpdateProjectUserRole = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectUserRole>>, TError,{orgId: string;projectId: string;userId: string;data: UpdateProjectUserRoleBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProjectUserRole>>, {orgId: string;projectId: string;userId: string;data: UpdateProjectUserRoleBody}> = (props) => {
          const {orgId,projectId,userId,data} = props ?? {};

          return  updateProjectUserRole(orgId,projectId,userId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateProjectUserRole>>, TError, {orgId: string;projectId: string;userId: string;data: UpdateProjectUserRoleBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const createProjectCIDR = (
    orgId: string,
    projectId: string,
    provider: string,
    centralCreateProjectCIDRReqBody: CentralCreateProjectCIDRReqBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralProjectCIDR>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/${provider}/cidr`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateProjectCIDRReqBody
    },
      { _metadata: { operationName: 'createProjectCIDR', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/{provider}/cidr', },  ...options});
    }
  


    export type CreateProjectCIDRMutationResult = NonNullable<Awaited<ReturnType<typeof createProjectCIDR>>>
    export type CreateProjectCIDRMutationBody = CentralCreateProjectCIDRReqBody
    export type CreateProjectCIDRMutationError = unknown

    export const useCreateProjectCIDR = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectCIDR>>, TError,{orgId: string;projectId: string;provider: string;data: CentralCreateProjectCIDRReqBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProjectCIDR>>, {orgId: string;projectId: string;provider: string;data: CentralCreateProjectCIDRReqBody}> = (props) => {
          const {orgId,projectId,provider,data} = props ?? {};

          return  createProjectCIDR(orgId,projectId,provider,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createProjectCIDR>>, TError, {orgId: string;projectId: string;provider: string;data: CentralCreateProjectCIDRReqBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const validateProjectCIDR = (
    orgId: string,
    projectId: string,
    provider: string,
    centralCreateProjectCIDRReqBody: CentralCreateProjectCIDRReqBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralDefaultResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/${provider}/cidr/validation/query`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateProjectCIDRReqBody
    },
      { _metadata: { operationName: 'validateProjectCIDR', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/{provider}/cidr/validation/query', },  ...options});
    }
  


    export type ValidateProjectCIDRMutationResult = NonNullable<Awaited<ReturnType<typeof validateProjectCIDR>>>
    export type ValidateProjectCIDRMutationBody = CentralCreateProjectCIDRReqBody
    export type ValidateProjectCIDRMutationError = unknown

    export const useValidateProjectCIDR = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateProjectCIDR>>, TError,{orgId: string;projectId: string;provider: string;data: CentralCreateProjectCIDRReqBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof validateProjectCIDR>>, {orgId: string;projectId: string;provider: string;data: CentralCreateProjectCIDRReqBody}> = (props) => {
          const {orgId,projectId,provider,data} = props ?? {};

          return  validateProjectCIDR(orgId,projectId,provider,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof validateProjectCIDR>>, TError, {orgId: string;projectId: string;provider: string;data: CentralCreateProjectCIDRReqBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const getProjectCIDR = (
    orgId: string,
    projectId: string,
    provider: string,
    region: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralProjectCIDR>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/${provider}/${region}/cidr`, method: 'get', signal
    },
      { _metadata: { operationName: 'getProjectCIDR', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/{provider}/{region}/cidr', },  ...options});
    }
  

export const getGetProjectCIDRQueryKey = (orgId: string,
    projectId: string,
    provider: string,
    region: string,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/${provider}/${region}/cidr`];

    
export type GetProjectCIDRQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectCIDR>>>
export type GetProjectCIDRQueryError = unknown

export const useGetProjectCIDR = <TData = Awaited<ReturnType<typeof getProjectCIDR>>, TError = unknown>(
 orgId: string,
    projectId: string,
    provider: string,
    region: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProjectCIDR>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetProjectCIDRQueryKey(orgId,projectId,provider,region);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectCIDR>>> = ({ signal }) => getProjectCIDR(orgId,projectId,provider,region, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getProjectCIDR>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && provider && region), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const listUsers = (
    orgId: string,
    params?: ListUsersParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralUsers>(
      {url: `/api/v1/orgs/${orgId}/users`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listUsers', pathRoute: '/api/v1/orgs/{org_id}/users', },  ...options});
    }
  

export const getListUsersQueryKey = (orgId: string,
    params?: ListUsersParams,) => [`/api/v1/orgs/${orgId}/users`, ...(params ? [params]: [])];

    
export type ListUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listUsers>>>
export type ListUsersQueryError = unknown

export const useListUsers = <TData = Awaited<ReturnType<typeof listUsers>>, TError = unknown>(
 orgId: string,
    params?: ListUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUsersQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listUsers>>> = ({ signal }) => listUsers(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listUsers>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const inviteUserToOrg = (
    orgId: string,
    inviteUserToOrgBody: InviteUserToOrgBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<InviteUserToOrg200>(
      {url: `/api/v1/orgs/${orgId}/users`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inviteUserToOrgBody
    },
      { _metadata: { operationName: 'inviteUserToOrg', pathRoute: '/api/v1/orgs/{org_id}/users', },  ...options});
    }
  


    export type InviteUserToOrgMutationResult = NonNullable<Awaited<ReturnType<typeof inviteUserToOrg>>>
    export type InviteUserToOrgMutationBody = InviteUserToOrgBody
    export type InviteUserToOrgMutationError = RpcStatus

    export const useInviteUserToOrg = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inviteUserToOrg>>, TError,{orgId: string;data: InviteUserToOrgBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inviteUserToOrg>>, {orgId: string;data: InviteUserToOrgBody}> = (props) => {
          const {orgId,data} = props ?? {};

          return  inviteUserToOrg(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof inviteUserToOrg>>, TError, {orgId: string;data: InviteUserToOrgBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const getUserProfile = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralUserProfile>(
      {url: `/api/v1/orgs/${orgId}/users/me`, method: 'get', signal
    },
      { _metadata: { operationName: 'getUserProfile', pathRoute: '/api/v1/orgs/{org_id}/users/me', },  ...options});
    }
  

export const getGetUserProfileQueryKey = (orgId: string,) => [`/api/v1/orgs/${orgId}/users/me`];

    
export type GetUserProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getUserProfile>>>
export type GetUserProfileQueryError = unknown

export const useGetUserProfile = <TData = Awaited<ReturnType<typeof getUserProfile>>, TError = unknown>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserProfile>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserProfileQueryKey(orgId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserProfile>>> = ({ signal }) => getUserProfile(orgId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserProfile>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const updateUserProfile = (
    orgId: string,
    centralUpdateUserProfileReq: CentralUpdateUserProfileReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/users/me`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: centralUpdateUserProfileReq
    },
      { _metadata: { operationName: 'updateUserProfile', pathRoute: '/api/v1/orgs/{org_id}/users/me', },  ...options});
    }
  


    export type UpdateUserProfileMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserProfile>>>
    export type UpdateUserProfileMutationBody = CentralUpdateUserProfileReq
    export type UpdateUserProfileMutationError = unknown

    export const useUpdateUserProfile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserProfile>>, TError,{orgId: string;data: CentralUpdateUserProfileReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserProfile>>, {orgId: string;data: CentralUpdateUserProfileReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  updateUserProfile(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateUserProfile>>, TError, {orgId: string;data: CentralUpdateUserProfileReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const deleteUser = (
    orgId: string,
    userId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/users/${userId}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteUser', pathRoute: '/api/v1/orgs/{org_id}/users/{user_id}', },  ...options});
    }
  


    export type DeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>
    
    export type DeleteUserMutationError = unknown

    export const useDeleteUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError,{orgId: string;userId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUser>>, {orgId: string;userId: string}> = (props) => {
          const {orgId,userId} = props ?? {};

          return  deleteUser(orgId,userId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteUser>>, TError, {orgId: string;userId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const updateUser = (
    orgId: string,
    userId: string,
    updateUserBody: UpdateUserBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/${orgId}/users/${userId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateUserBody
    },
      { _metadata: { operationName: 'updateUser', pathRoute: '/api/v1/orgs/{org_id}/users/{user_id}', },  ...options});
    }
  


    export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>
    export type UpdateUserMutationBody = UpdateUserBody
    export type UpdateUserMutationError = unknown

    export const useUpdateUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError,{orgId: string;userId: string;data: UpdateUserBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUser>>, {orgId: string;userId: string;data: UpdateUserBody}> = (props) => {
          const {orgId,userId,data} = props ?? {};

          return  updateUser(orgId,userId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateUser>>, TError, {orgId: string;userId: string;data: UpdateUserBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * ListTenantDiscounts
 * @summary List Tenant Discount (User portal)
 */
export const listTenantDiscounts = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsDiscount[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/users/discounts`, method: 'get', signal
    },
      { _metadata: { operationName: 'listTenantDiscounts', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/users/discounts', },  ...options});
    }
  

export const getListTenantDiscountsQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/users/discounts`];

    
export type ListTenantDiscountsQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantDiscounts>>>
export type ListTenantDiscountsQueryError = unknown

export const useListTenantDiscounts = <TData = Awaited<ReturnType<typeof listTenantDiscounts>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantDiscounts>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantDiscountsQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantDiscounts>>> = ({ signal }) => listTenantDiscounts(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listTenantDiscounts>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getBillsDetails = (
    tenantId: string,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsCostExplorerDetailsResult>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/bills_details/year/${year}/month/${month}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getBillsDetails', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/costexplorer/bills_details/year/{year}/month/{month}', },  ...options});
    }
  

export const getGetBillsDetailsQueryKey = (tenantId: string,
    year: string,
    month: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/bills_details/year/${year}/month/${month}`];

    
export type GetBillsDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getBillsDetails>>>
export type GetBillsDetailsQueryError = unknown

export const useGetBillsDetails = <TData = Awaited<ReturnType<typeof getBillsDetails>>, TError = unknown>(
 tenantId: string,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillsDetails>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBillsDetailsQueryKey(tenantId,year,month);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillsDetails>>> = ({ signal }) => getBillsDetails(tenantId,year,month, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getBillsDetails>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && year && month), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Monthly Bills Explorer Monthly
 */
export const getMonthlyBillsExport = (
    tenantId: string,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiHttpBody>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_bills/year/${year}/month/${month}/export`, method: 'post'
    },
      { _metadata: { operationName: 'getMonthlyBillsExport', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/costexplorer/monthly_bills/year/{year}/month/{month}/export', },  ...options});
    }
  


    export type GetMonthlyBillsExportMutationResult = NonNullable<Awaited<ReturnType<typeof getMonthlyBillsExport>>>
    
    export type GetMonthlyBillsExportMutationError = unknown

    export const useGetMonthlyBillsExport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getMonthlyBillsExport>>, TError,{tenantId: string;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getMonthlyBillsExport>>, {tenantId: string;year: string;month: string}> = (props) => {
          const {tenantId,year,month} = props ?? {};

          return  getMonthlyBillsExport(tenantId,year,month,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof getMonthlyBillsExport>>, TError, {tenantId: string;year: string;month: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary Cost Explorer Monthly
 */
export const getMonthlyCost = (
    tenantId: string,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsMonthlycostExplorerResult>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_cost/year/${year}/month/${month}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getMonthlyCost', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/costexplorer/monthly_cost/year/{year}/month/{month}', },  ...options});
    }
  

export const getGetMonthlyCostQueryKey = (tenantId: string,
    year: string,
    month: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_cost/year/${year}/month/${month}`];

    
export type GetMonthlyCostQueryResult = NonNullable<Awaited<ReturnType<typeof getMonthlyCost>>>
export type GetMonthlyCostQueryError = unknown

export const useGetMonthlyCost = <TData = Awaited<ReturnType<typeof getMonthlyCost>>, TError = unknown>(
 tenantId: string,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMonthlyCost>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMonthlyCostQueryKey(tenantId,year,month);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMonthlyCost>>> = ({ signal }) => getMonthlyCost(tenantId,year,month, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getMonthlyCost>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && year && month), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary DataAPI usage Explorer Monthly
 */
export const getDataAPIUsageExplorerMonthly = (
    tenantId: string,
    appId: string,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsDataAPIUsageExplore>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_usage/dataapi/apps/${appId}/year/${year}/month/${month}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getDataAPIUsageExplorerMonthly', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/costexplorer/monthly_usage/dataapi/apps/{app_id}/year/{year}/month/{month}', },  ...options});
    }
  

export const getGetDataAPIUsageExplorerMonthlyQueryKey = (tenantId: string,
    appId: string,
    year: string,
    month: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_usage/dataapi/apps/${appId}/year/${year}/month/${month}`];

    
export type GetDataAPIUsageExplorerMonthlyQueryResult = NonNullable<Awaited<ReturnType<typeof getDataAPIUsageExplorerMonthly>>>
export type GetDataAPIUsageExplorerMonthlyQueryError = unknown

export const useGetDataAPIUsageExplorerMonthly = <TData = Awaited<ReturnType<typeof getDataAPIUsageExplorerMonthly>>, TError = unknown>(
 tenantId: string,
    appId: string,
    year: string,
    month: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDataAPIUsageExplorerMonthly>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDataAPIUsageExplorerMonthlyQueryKey(tenantId,appId,year,month);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataAPIUsageExplorerMonthly>>> = ({ signal }) => getDataAPIUsageExplorerMonthly(tenantId,appId,year,month, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getDataAPIUsageExplorerMonthly>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && appId && year && month), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Cost Explorer Monthly
 */
export const exportMonthBillCSV = (
    tenantId: string,
    year: string,
    month: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ApiHttpBody>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/costexplorer/monthly_cost/year/${year}/month/${month}/export`, method: 'post'
    },
      { _metadata: { operationName: 'exportMonthBillCSV', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/costexplorer/monthly_cost/year/{year}/month/{month}/export', },  ...options});
    }
  


    export type ExportMonthBillCSVMutationResult = NonNullable<Awaited<ReturnType<typeof exportMonthBillCSV>>>
    
    export type ExportMonthBillCSVMutationError = unknown

    export const useExportMonthBillCSV = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof exportMonthBillCSV>>, TError,{tenantId: string;year: string;month: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof exportMonthBillCSV>>, {tenantId: string;year: string;month: string}> = (props) => {
          const {tenantId,year,month} = props ?? {};

          return  exportMonthBillCSV(tenantId,year,month,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof exportMonthBillCSV>>, TError, {tenantId: string;year: string;month: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary List Invoices
 */
export const listInvoices = (
    tenantId: string,
    params?: ListInvoicesParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsInvoice[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/invoices`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listInvoices', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/invoices', },  ...options});
    }
  

export const getListInvoicesQueryKey = (tenantId: string,
    params?: ListInvoicesParams,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/invoices`, ...(params ? [params]: [])];

    
export type ListInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof listInvoices>>>
export type ListInvoicesQueryError = unknown

export const useListInvoices = <TData = Awaited<ReturnType<typeof listInvoices>>, TError = unknown>(
 tenantId: string,
    params?: ListInvoicesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listInvoices>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListInvoicesQueryKey(tenantId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listInvoices>>> = ({ signal }) => listInvoices(tenantId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listInvoices>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Create Invoice
 */
export const postApiProxyBillingApiV1BillingTenantsTenantIdInvoices = (
    tenantId: string,
    formsCreateInvoiceReq: FormsCreateInvoiceReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsInvoice>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/invoices`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsCreateInvoiceReq
    },
      { _metadata: { operationName: 'postApiProxyBillingApiV1BillingTenantsTenantIdInvoices', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/invoices', },  ...options});
    }
  


    export type PostApiProxyBillingApiV1BillingTenantsTenantIdInvoicesMutationResult = NonNullable<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdInvoices>>>
    export type PostApiProxyBillingApiV1BillingTenantsTenantIdInvoicesMutationBody = FormsCreateInvoiceReq
    export type PostApiProxyBillingApiV1BillingTenantsTenantIdInvoicesMutationError = unknown

    export const usePostApiProxyBillingApiV1BillingTenantsTenantIdInvoices = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdInvoices>>, TError,{tenantId: string;data: FormsCreateInvoiceReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdInvoices>>, {tenantId: string;data: FormsCreateInvoiceReq}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  postApiProxyBillingApiV1BillingTenantsTenantIdInvoices(tenantId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof postApiProxyBillingApiV1BillingTenantsTenantIdInvoices>>, TError, {tenantId: string;data: FormsCreateInvoiceReq}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary GetCustomerInfo
 */
export const getCustomerInfo = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsStripeCustomerInfo>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/customer`, method: 'get', signal
    },
      { _metadata: { operationName: 'getCustomerInfo', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/payments/stripe/customer', },  ...options});
    }
  

export const getGetCustomerInfoQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/customer`];

    
export type GetCustomerInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getCustomerInfo>>>
export type GetCustomerInfoQueryError = unknown

export const useGetCustomerInfo = <TData = Awaited<ReturnType<typeof getCustomerInfo>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCustomerInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustomerInfoQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustomerInfo>>> = ({ signal }) => getCustomerInfo(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getCustomerInfo>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary ListStripeInvoices
 */
export const listStripeInvoices = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsStripeInvoice[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/invoices`, method: 'get', signal
    },
      { _metadata: { operationName: 'listStripeInvoices', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/payments/stripe/invoices', },  ...options});
    }
  

export const getListStripeInvoicesQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/invoices`];

    
export type ListStripeInvoicesQueryResult = NonNullable<Awaited<ReturnType<typeof listStripeInvoices>>>
export type ListStripeInvoicesQueryError = unknown

export const useListStripeInvoices = <TData = Awaited<ReturnType<typeof listStripeInvoices>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listStripeInvoices>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListStripeInvoicesQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listStripeInvoices>>> = ({ signal }) => listStripeInvoices(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listStripeInvoices>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary ListPaymentMethods
 */
export const listPaymentMethods = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsStripePaymentMethod[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/payment_methods`, method: 'get', signal
    },
      { _metadata: { operationName: 'listPaymentMethods', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/payments/stripe/payment_methods', },  ...options});
    }
  

export const getListPaymentMethodsQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/payment_methods`];

    
export type ListPaymentMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof listPaymentMethods>>>
export type ListPaymentMethodsQueryError = unknown

export const useListPaymentMethods = <TData = Awaited<ReturnType<typeof listPaymentMethods>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listPaymentMethods>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListPaymentMethodsQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listPaymentMethods>>> = ({ signal }) => listPaymentMethods(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listPaymentMethods>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary UpdatePaymentMethod
 */
export const updatePaymentMethod = (
    tenantId: string,
    paymentMethodId: string,
    formsStripePaymentMethod: FormsStripePaymentMethod,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsStripePaymentMethod>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/payment_methods/${paymentMethodId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsStripePaymentMethod
    },
      { _metadata: { operationName: 'updatePaymentMethod', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/payments/stripe/payment_methods/{payment_method_id}', },  ...options});
    }
  


    export type UpdatePaymentMethodMutationResult = NonNullable<Awaited<ReturnType<typeof updatePaymentMethod>>>
    export type UpdatePaymentMethodMutationBody = FormsStripePaymentMethod
    export type UpdatePaymentMethodMutationError = unknown

    export const useUpdatePaymentMethod = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePaymentMethod>>, TError,{tenantId: string;paymentMethodId: string;data: FormsStripePaymentMethod}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePaymentMethod>>, {tenantId: string;paymentMethodId: string;data: FormsStripePaymentMethod}> = (props) => {
          const {tenantId,paymentMethodId,data} = props ?? {};

          return  updatePaymentMethod(tenantId,paymentMethodId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updatePaymentMethod>>, TError, {tenantId: string;paymentMethodId: string;data: FormsStripePaymentMethod}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary DeletePaymentMethod
 */
export const deletePaymentMethod = (
    tenantId: string,
    paymentMethodId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/payment_methods/${paymentMethodId}`, method: 'delete'
    },
      { _metadata: { operationName: 'deletePaymentMethod', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/payments/stripe/payment_methods/{payment_method_id}', },  ...options});
    }
  


    export type DeletePaymentMethodMutationResult = NonNullable<Awaited<ReturnType<typeof deletePaymentMethod>>>
    
    export type DeletePaymentMethodMutationError = unknown

    export const useDeletePaymentMethod = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePaymentMethod>>, TError,{tenantId: string;paymentMethodId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePaymentMethod>>, {tenantId: string;paymentMethodId: string}> = (props) => {
          const {tenantId,paymentMethodId} = props ?? {};

          return  deletePaymentMethod(tenantId,paymentMethodId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deletePaymentMethod>>, TError, {tenantId: string;paymentMethodId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * credit card validation. if pass, attach the paymentMethod to customer
 * @summary credit card validation, query after s.SetUpIntent, before s.ConfirmIntent
 */
export const validateCreditCard = (
    tenantId: string,
    paymentMethodId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/payment_methods/${paymentMethodId}/validate`, method: 'put'
    },
      { _metadata: { operationName: 'validateCreditCard', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/payments/stripe/payment_methods/{payment_method_id}/validate', },  ...options});
    }
  


    export type ValidateCreditCardMutationResult = NonNullable<Awaited<ReturnType<typeof validateCreditCard>>>
    
    export type ValidateCreditCardMutationError = unknown

    export const useValidateCreditCard = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof validateCreditCard>>, TError,{tenantId: string;paymentMethodId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof validateCreditCard>>, {tenantId: string;paymentMethodId: string}> = (props) => {
          const {tenantId,paymentMethodId} = props ?? {};

          return  validateCreditCard(tenantId,paymentMethodId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof validateCreditCard>>, TError, {tenantId: string;paymentMethodId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary SetPaymentMethodDefault
 */
export const setPaymentMethodDefault = (
    tenantId: string,
    paymentMethodId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/payment_methods/${paymentMethodId}/default`, method: 'put'
    },
      { _metadata: { operationName: 'setPaymentMethodDefault', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/payments/stripe/payment_methods/{payment_method_id}/default', },  ...options});
    }
  


    export type SetPaymentMethodDefaultMutationResult = NonNullable<Awaited<ReturnType<typeof setPaymentMethodDefault>>>
    
    export type SetPaymentMethodDefaultMutationError = unknown

    export const useSetPaymentMethodDefault = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setPaymentMethodDefault>>, TError,{tenantId: string;paymentMethodId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setPaymentMethodDefault>>, {tenantId: string;paymentMethodId: string}> = (props) => {
          const {tenantId,paymentMethodId} = props ?? {};

          return  setPaymentMethodDefault(tenantId,paymentMethodId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof setPaymentMethodDefault>>, TError, {tenantId: string;paymentMethodId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * access control of function: credit card validation
 * @summary access control
 */
export const allowCreditCardValidation = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<ApiSwitchStruct>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/switch/card/validate/is_allowed`, method: 'get', signal
    },
      { _metadata: { operationName: 'allowCreditCardValidation', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/switch/card/validate/is_allowed', },  ...options});
    }
  

export const getAllowCreditCardValidationQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/switch/card/validate/is_allowed`];

    
export type AllowCreditCardValidationQueryResult = NonNullable<Awaited<ReturnType<typeof allowCreditCardValidation>>>
export type AllowCreditCardValidationQueryError = unknown

export const useAllowCreditCardValidation = <TData = Awaited<ReturnType<typeof allowCreditCardValidation>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof allowCreditCardValidation>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAllowCreditCardValidationQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof allowCreditCardValidation>>> = ({ signal }) => allowCreditCardValidation(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof allowCreditCardValidation>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary SetupIntent
 */
export const setupIntent = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsSetupIntent>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/setup_intent`, method: 'post'
    },
      { _metadata: { operationName: 'setupIntent', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/payments/stripe/setup_intent', },  ...options});
    }
  


    export type SetupIntentMutationResult = NonNullable<Awaited<ReturnType<typeof setupIntent>>>
    
    export type SetupIntentMutationError = unknown

    export const useSetupIntent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setupIntent>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setupIntent>>, {tenantId: string}> = (props) => {
          const {tenantId} = props ?? {};

          return  setupIntent(tenantId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof setupIntent>>, TError, {tenantId: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const setupIntentConfirm = (
    tenantId: string,
    centralOrgIdReq: CentralOrgIdReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/payments/stripe/setup_intent/confirm`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: centralOrgIdReq
    },
      { _metadata: { operationName: 'setupIntentConfirm', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/payments/stripe/setup_intent/confirm', },  ...options});
    }
  


    export type SetupIntentConfirmMutationResult = NonNullable<Awaited<ReturnType<typeof setupIntentConfirm>>>
    export type SetupIntentConfirmMutationBody = CentralOrgIdReq
    export type SetupIntentConfirmMutationError = unknown

    export const useSetupIntentConfirm = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setupIntentConfirm>>, TError,{tenantId: string;data: CentralOrgIdReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setupIntentConfirm>>, {tenantId: string;data: CentralOrgIdReq}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  setupIntentConfirm(tenantId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof setupIntentConfirm>>, TError, {tenantId: string;data: CentralOrgIdReq}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary Get BillingInfo
 */
export const getBillingInfo = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsBillingInformation>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_info`, method: 'get', signal
    },
      { _metadata: { operationName: 'getBillingInfo', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_info', },  ...options});
    }
  

export const getGetBillingInfoQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_info`];

    
export type GetBillingInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getBillingInfo>>>
export type GetBillingInfoQueryError = unknown

export const useGetBillingInfo = <TData = Awaited<ReturnType<typeof getBillingInfo>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBillingInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetBillingInfoQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBillingInfo>>> = ({ signal }) => getBillingInfo(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getBillingInfo>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Update BillingInfo
 */
export const updateBillingInfo = (
    tenantId: string,
    formsBillingInformationInput: FormsBillingInformationInput,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBillingInformation>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/billing_info`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: formsBillingInformationInput
    },
      { _metadata: { operationName: 'updateBillingInfo', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/billing_info', },  ...options});
    }
  


    export type UpdateBillingInfoMutationResult = NonNullable<Awaited<ReturnType<typeof updateBillingInfo>>>
    export type UpdateBillingInfoMutationBody = FormsBillingInformationInput
    export type UpdateBillingInfoMutationError = unknown

    export const useUpdateBillingInfo = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBillingInfo>>, TError,{tenantId: string;data: FormsBillingInformationInput}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBillingInfo>>, {tenantId: string;data: FormsBillingInformationInput}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  updateBillingInfo(tenantId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateBillingInfo>>, TError, {tenantId: string;data: FormsBillingInformationInput}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary Get Public Support Plan
 */
export const getPublicSupportPlan = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsSupportPlan[]>(
      {url: `/api_proxy/billing/api/v1/billing/support_plan`, method: 'get', signal
    },
      { _metadata: { operationName: 'getPublicSupportPlan', pathRoute: '/api_proxy/billing/api/v1/billing/support_plan', },  ...options});
    }
  

export const getGetPublicSupportPlanQueryKey = () => [`/api_proxy/billing/api/v1/billing/support_plan`];

    
export type GetPublicSupportPlanQueryResult = NonNullable<Awaited<ReturnType<typeof getPublicSupportPlan>>>
export type GetPublicSupportPlanQueryError = unknown

export const useGetPublicSupportPlan = <TData = Awaited<ReturnType<typeof getPublicSupportPlan>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPublicSupportPlan>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPublicSupportPlanQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPublicSupportPlan>>> = ({ signal }) => getPublicSupportPlan(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getPublicSupportPlan>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get Tenant Support Plan
 */
export const getTenantSupportPlan = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsBindedSupportPlan>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/binded_support_plan`, method: 'get', signal
    },
      { _metadata: { operationName: 'getTenantSupportPlan', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/binded_support_plan', },  ...options});
    }
  

export const getGetTenantSupportPlanQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/binded_support_plan`];

    
export type GetTenantSupportPlanQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantSupportPlan>>>
export type GetTenantSupportPlanQueryError = unknown

export const useGetTenantSupportPlan = <TData = Awaited<ReturnType<typeof getTenantSupportPlan>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantSupportPlan>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantSupportPlanQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantSupportPlan>>> = ({ signal }) => getTenantSupportPlan(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTenantSupportPlan>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Cancel Tenant Support Plan
 */
export const cancelTenantSupportPlan = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/binded_support_plan`, method: 'delete'
    },
      { _metadata: { operationName: 'cancelTenantSupportPlan', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/binded_support_plan', },  ...options});
    }
  


    export type CancelTenantSupportPlanMutationResult = NonNullable<Awaited<ReturnType<typeof cancelTenantSupportPlan>>>
    
    export type CancelTenantSupportPlanMutationError = unknown

    export const useCancelTenantSupportPlan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelTenantSupportPlan>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelTenantSupportPlan>>, {tenantId: string}> = (props) => {
          const {tenantId} = props ?? {};

          return  cancelTenantSupportPlan(tenantId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof cancelTenantSupportPlan>>, TError, {tenantId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary Tenant Bind Public Support Plan
 */
export const tenantBindPublicSupportPlan = (
    tenantId: string,
    formsBindedSupportPlan: FormsBindedSupportPlan,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsBindedSupportPlan>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/binded_support_plan/public`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: formsBindedSupportPlan
    },
      { _metadata: { operationName: 'tenantBindPublicSupportPlan', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/binded_support_plan/public', },  ...options});
    }
  


    export type TenantBindPublicSupportPlanMutationResult = NonNullable<Awaited<ReturnType<typeof tenantBindPublicSupportPlan>>>
    export type TenantBindPublicSupportPlanMutationBody = FormsBindedSupportPlan
    export type TenantBindPublicSupportPlanMutationError = unknown

    export const useTenantBindPublicSupportPlan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tenantBindPublicSupportPlan>>, TError,{tenantId: string;data: FormsBindedSupportPlan}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tenantBindPublicSupportPlan>>, {tenantId: string;data: FormsBindedSupportPlan}> = (props) => {
          const {tenantId,data} = props ?? {};

          return  tenantBindPublicSupportPlan(tenantId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof tenantBindPublicSupportPlan>>, TError, {tenantId: string;data: FormsBindedSupportPlan}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary CancelBindedTenantSupportPlan
 */
export const cancelBindedTenantSupportPlan = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/binded_support_plan/public`, method: 'delete'
    },
      { _metadata: { operationName: 'cancelBindedTenantSupportPlan', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/binded_support_plan/public', },  ...options});
    }
  


    export type CancelBindedTenantSupportPlanMutationResult = NonNullable<Awaited<ReturnType<typeof cancelBindedTenantSupportPlan>>>
    
    export type CancelBindedTenantSupportPlanMutationError = unknown

    export const useCancelBindedTenantSupportPlan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cancelBindedTenantSupportPlan>>, TError,{tenantId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cancelBindedTenantSupportPlan>>, {tenantId: string}> = (props) => {
          const {tenantId} = props ?? {};

          return  cancelBindedTenantSupportPlan(tenantId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof cancelBindedTenantSupportPlan>>, TError, {tenantId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary List Tenants Credits
 */
export const listTenantsCredits = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsCreditsPackage[]>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/credits`, method: 'get', signal
    },
      { _metadata: { operationName: 'listTenantsCredits', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/credits', },  ...options});
    }
  

export const getListTenantsCreditsQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/credits`];

    
export type ListTenantsCreditsQueryResult = NonNullable<Awaited<ReturnType<typeof listTenantsCredits>>>
export type ListTenantsCreditsQueryError = unknown

export const useListTenantsCredits = <TData = Awaited<ReturnType<typeof listTenantsCredits>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTenantsCredits>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListTenantsCreditsQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTenantsCredits>>> = ({ signal }) => listTenantsCredits(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listTenantsCredits>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get Tenants Credits Summary
 */
export const getTenantsCreditsSummary = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsCreditsSummary>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/credits_summary`, method: 'get', signal
    },
      { _metadata: { operationName: 'getTenantsCreditsSummary', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/credits_summary', },  ...options});
    }
  

export const getGetTenantsCreditsSummaryQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/credits_summary`];

    
export type GetTenantsCreditsSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantsCreditsSummary>>>
export type GetTenantsCreditsSummaryQueryError = unknown

export const useGetTenantsCreditsSummary = <TData = Awaited<ReturnType<typeof getTenantsCreditsSummary>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantsCreditsSummary>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantsCreditsSummaryQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantsCreditsSummary>>> = ({ signal }) => getTenantsCreditsSummary(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTenantsCreditsSummary>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Download Contract
 */
export const downloadContract = (
    tenantId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<ApiHttpBody>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/contracts/${id}/download`, method: 'get', signal
    },
      { _metadata: { operationName: 'downloadContract', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/contracts/{id}/download', },  ...options});
    }
  

export const getDownloadContractQueryKey = (tenantId: string,
    id: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/contracts/${id}/download`];

    
export type DownloadContractQueryResult = NonNullable<Awaited<ReturnType<typeof downloadContract>>>
export type DownloadContractQueryError = unknown

export const useDownloadContract = <TData = Awaited<ReturnType<typeof downloadContract>>, TError = unknown>(
 tenantId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof downloadContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDownloadContractQueryKey(tenantId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadContract>>> = ({ signal }) => downloadContract(tenantId,id, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof downloadContract>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get Tenant Cost Quota
 */
export const getTenantCostQuota = (
    tenantId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsCostQuota>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/cost_quota`, method: 'get', signal
    },
      { _metadata: { operationName: 'getTenantCostQuota', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/cost_quota', },  ...options});
    }
  

export const getGetTenantCostQuotaQueryKey = (tenantId: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/cost_quota`];

    
export type GetTenantCostQuotaQueryResult = NonNullable<Awaited<ReturnType<typeof getTenantCostQuota>>>
export type GetTenantCostQuotaQueryError = unknown

export const useGetTenantCostQuota = <TData = Awaited<ReturnType<typeof getTenantCostQuota>>, TError = unknown>(
 tenantId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTenantCostQuota>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTenantCostQuotaQueryKey(tenantId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTenantCostQuota>>> = ({ signal }) => getTenantCostQuota(tenantId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getTenantCostQuota>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary List User View Contract
 */
export const listUserViewContract = (
    tenantId: string,
    params?: ListUserViewContractParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsContractsResponse>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/users/contracts`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listUserViewContract', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/users/contracts', },  ...options});
    }
  

export const getListUserViewContractQueryKey = (tenantId: string,
    params?: ListUserViewContractParams,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/users/contracts`, ...(params ? [params]: [])];

    
export type ListUserViewContractQueryResult = NonNullable<Awaited<ReturnType<typeof listUserViewContract>>>
export type ListUserViewContractQueryError = unknown

export const useListUserViewContract = <TData = Awaited<ReturnType<typeof listUserViewContract>>, TError = unknown>(
 tenantId: string,
    params?: ListUserViewContractParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listUserViewContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListUserViewContractQueryKey(tenantId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listUserViewContract>>> = ({ signal }) => listUserViewContract(tenantId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listUserViewContract>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Get User View Contract
 */
export const getUserViewContract = (
    tenantId: string,
    id: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<FormsContract>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/users/contracts/${id}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getUserViewContract', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/users/contracts/{id}', },  ...options});
    }
  

export const getGetUserViewContractQueryKey = (tenantId: string,
    id: string,) => [`/api_proxy/billing/api/v1/billing/tenants/${tenantId}/users/contracts/${id}`];

    
export type GetUserViewContractQueryResult = NonNullable<Awaited<ReturnType<typeof getUserViewContract>>>
export type GetUserViewContractQueryError = unknown

export const useGetUserViewContract = <TData = Awaited<ReturnType<typeof getUserViewContract>>, TError = unknown>(
 tenantId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserViewContract>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserViewContractQueryKey(tenantId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserViewContract>>> = ({ signal }) => getUserViewContract(tenantId,id, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserViewContract>>, TError, TData>({ queryKey, queryFn, enabled: !!(tenantId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary Operate Contract
 */
export const operateContract = (
    tenantId: string,
    id: string,
    action: 'accept' | 'reject',
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<FormsOKType>(
      {url: `/api_proxy/billing/api/v1/billing/tenants/${tenantId}/users/contracts/${id}/actions/${action}`, method: 'post'
    },
      { _metadata: { operationName: 'operateContract', pathRoute: '/api_proxy/billing/api/v1/billing/tenants/{tenant_id}/users/contracts/{id}/actions/{action}', },  ...options});
    }
  


    export type OperateContractMutationResult = NonNullable<Awaited<ReturnType<typeof operateContract>>>
    
    export type OperateContractMutationError = unknown

    export const useOperateContract = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof operateContract>>, TError,{tenantId: string;id: string;action: 'accept' | 'reject'}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof operateContract>>, {tenantId: string;id: string;action: 'accept' | 'reject'}> = (props) => {
          const {tenantId,id,action} = props ?? {};

          return  operateContract(tenantId,id,action,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof operateContract>>, TError, {tenantId: string;id: string;action: 'accept' | 'reject'}, TContext>(mutationFn, mutationOptions);
    }
    
export const getUserDebugFeatureList = (
    organization: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<UserDebugFeatureList>(
      {url: `/api/userdebug/v1/${organization}/features`, method: 'get', signal
    },
      { _metadata: { operationName: 'getUserDebugFeatureList', pathRoute: '/api/userdebug/v1/{organization}/features', },  ...options});
    }
  

export const getGetUserDebugFeatureListQueryKey = (organization: string,) => [`/api/userdebug/v1/${organization}/features`];

    
export type GetUserDebugFeatureListQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDebugFeatureList>>>
export type GetUserDebugFeatureListQueryError = unknown

export const useGetUserDebugFeatureList = <TData = Awaited<ReturnType<typeof getUserDebugFeatureList>>, TError = unknown>(
 organization: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserDebugFeatureList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserDebugFeatureListQueryKey(organization);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDebugFeatureList>>> = ({ signal }) => getUserDebugFeatureList(organization, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserDebugFeatureList>>, TError, TData>({ queryKey, queryFn, enabled: !!(organization), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getUserDebugAuthorizationList = (
    organization: string,
    params?: GetUserDebugAuthorizationListParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<UserDebugAuthorizationList>(
      {url: `/api/userdebug/v1/${organization}/authorizations`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getUserDebugAuthorizationList', pathRoute: '/api/userdebug/v1/{organization}/authorizations', },  ...options});
    }
  

export const getGetUserDebugAuthorizationListQueryKey = (organization: string,
    params?: GetUserDebugAuthorizationListParams,) => [`/api/userdebug/v1/${organization}/authorizations`, ...(params ? [params]: [])];

    
export type GetUserDebugAuthorizationListQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDebugAuthorizationList>>>
export type GetUserDebugAuthorizationListQueryError = unknown

export const useGetUserDebugAuthorizationList = <TData = Awaited<ReturnType<typeof getUserDebugAuthorizationList>>, TError = unknown>(
 organization: string,
    params?: GetUserDebugAuthorizationListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserDebugAuthorizationList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserDebugAuthorizationListQueryKey(organization,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDebugAuthorizationList>>> = ({ signal }) => getUserDebugAuthorizationList(organization,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserDebugAuthorizationList>>, TError, TData>({ queryKey, queryFn, enabled: !!(organization), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getUserDebugAuthorizationStatus = (
    organization: string,
    cluster: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<UserDebugAuthorization[]>(
      {url: `/api/userdebug/v1/${organization}/${cluster}/authorization`, method: 'get', signal
    },
      { _metadata: { operationName: 'getUserDebugAuthorizationStatus', pathRoute: '/api/userdebug/v1/{organization}/{cluster}/authorization', },  ...options});
    }
  

export const getGetUserDebugAuthorizationStatusQueryKey = (organization: string,
    cluster: string,) => [`/api/userdebug/v1/${organization}/${cluster}/authorization`];

    
export type GetUserDebugAuthorizationStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDebugAuthorizationStatus>>>
export type GetUserDebugAuthorizationStatusQueryError = UserDebugResponseMessage

export const useGetUserDebugAuthorizationStatus = <TData = Awaited<ReturnType<typeof getUserDebugAuthorizationStatus>>, TError = UserDebugResponseMessage>(
 organization: string,
    cluster: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserDebugAuthorizationStatus>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserDebugAuthorizationStatusQueryKey(organization,cluster);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDebugAuthorizationStatus>>> = ({ signal }) => getUserDebugAuthorizationStatus(organization,cluster, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserDebugAuthorizationStatus>>, TError, TData>({ queryKey, queryFn, enabled: !!(organization && cluster), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const newUserDebugAuthorization = (
    organization: string,
    cluster: string,
    newUserDebugAuthorizationBody: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/api/userdebug/v1/${organization}/${cluster}/authorization`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: newUserDebugAuthorizationBody
    },
      { _metadata: { operationName: 'newUserDebugAuthorization', pathRoute: '/api/userdebug/v1/{organization}/{cluster}/authorization', },  ...options});
    }
  


    export type NewUserDebugAuthorizationMutationResult = NonNullable<Awaited<ReturnType<typeof newUserDebugAuthorization>>>
    export type NewUserDebugAuthorizationMutationBody = number
    export type NewUserDebugAuthorizationMutationError = UserDebugResponseMessage

    export const useNewUserDebugAuthorization = <TError = UserDebugResponseMessage,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof newUserDebugAuthorization>>, TError,{organization: string;cluster: string;data: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof newUserDebugAuthorization>>, {organization: string;cluster: string;data: number}> = (props) => {
          const {organization,cluster,data} = props ?? {};

          return  newUserDebugAuthorization(organization,cluster,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof newUserDebugAuthorization>>, TError, {organization: string;cluster: string;data: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const revokeUserDebugAuthorization = (
    organization: string,
    cluster: string,
    revokeUserDebugAuthorizationBody: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UserDebugResponseMessage>(
      {url: `/api/userdebug/v1/${organization}/${cluster}/authorization`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: revokeUserDebugAuthorizationBody
    },
      { _metadata: { operationName: 'revokeUserDebugAuthorization', pathRoute: '/api/userdebug/v1/{organization}/{cluster}/authorization', },  ...options});
    }
  


    export type RevokeUserDebugAuthorizationMutationResult = NonNullable<Awaited<ReturnType<typeof revokeUserDebugAuthorization>>>
    export type RevokeUserDebugAuthorizationMutationBody = string
    export type RevokeUserDebugAuthorizationMutationError = unknown

    export const useRevokeUserDebugAuthorization = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof revokeUserDebugAuthorization>>, TError,{organization: string;cluster: string;data: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof revokeUserDebugAuthorization>>, {organization: string;cluster: string;data: string}> = (props) => {
          const {organization,cluster,data} = props ?? {};

          return  revokeUserDebugAuthorization(organization,cluster,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof revokeUserDebugAuthorization>>, TError, {organization: string;cluster: string;data: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const getUserDebugAuthorizationDetail = (
    organization: string,
    cluster: string,
    authId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<UserDebugAuthorizationDetail>(
      {url: `/api/userdebug/v1/${organization}/${cluster}/authorization/details/${authId}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getUserDebugAuthorizationDetail', pathRoute: '/api/userdebug/v1/{organization}/{cluster}/authorization/details/{auth_id}', },  ...options});
    }
  

export const getGetUserDebugAuthorizationDetailQueryKey = (organization: string,
    cluster: string,
    authId: string,) => [`/api/userdebug/v1/${organization}/${cluster}/authorization/details/${authId}`];

    
export type GetUserDebugAuthorizationDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDebugAuthorizationDetail>>>
export type GetUserDebugAuthorizationDetailQueryError = UserDebugResponseMessage

export const useGetUserDebugAuthorizationDetail = <TData = Awaited<ReturnType<typeof getUserDebugAuthorizationDetail>>, TError = UserDebugResponseMessage>(
 organization: string,
    cluster: string,
    authId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserDebugAuthorizationDetail>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserDebugAuthorizationDetailQueryKey(organization,cluster,authId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDebugAuthorizationDetail>>> = ({ signal }) => getUserDebugAuthorizationDetail(organization,cluster,authId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserDebugAuthorizationDetail>>, TError, TData>({ queryKey, queryFn, enabled: !!(organization && cluster && authId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getUserDebugAuthorizationRecords = (
    organization: string,
    cluster: string,
    params?: GetUserDebugAuthorizationRecordsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<UserDebugAuthorizationRecords>(
      {url: `/api/userdebug/v1/${organization}/${cluster}/authorization/records`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getUserDebugAuthorizationRecords', pathRoute: '/api/userdebug/v1/{organization}/{cluster}/authorization/records', },  ...options});
    }
  

export const getGetUserDebugAuthorizationRecordsQueryKey = (organization: string,
    cluster: string,
    params?: GetUserDebugAuthorizationRecordsParams,) => [`/api/userdebug/v1/${organization}/${cluster}/authorization/records`, ...(params ? [params]: [])];

    
export type GetUserDebugAuthorizationRecordsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDebugAuthorizationRecords>>>
export type GetUserDebugAuthorizationRecordsQueryError = UserDebugResponseMessage

export const useGetUserDebugAuthorizationRecords = <TData = Awaited<ReturnType<typeof getUserDebugAuthorizationRecords>>, TError = UserDebugResponseMessage>(
 organization: string,
    cluster: string,
    params?: GetUserDebugAuthorizationRecordsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserDebugAuthorizationRecords>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserDebugAuthorizationRecordsQueryKey(organization,cluster,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDebugAuthorizationRecords>>> = ({ signal }) => getUserDebugAuthorizationRecords(organization,cluster,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserDebugAuthorizationRecords>>, TError, TData>({ queryKey, queryFn, enabled: !!(organization && cluster), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getUserDebugAuthorizationRecordsForAuth = (
    organization: string,
    cluster: string,
    authId: string,
    params?: GetUserDebugAuthorizationRecordsForAuthParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<UserDebugAuthorizationRecords>(
      {url: `/api/userdebug/v1/${organization}/${cluster}/authorization/records/${authId}`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getUserDebugAuthorizationRecordsForAuth', pathRoute: '/api/userdebug/v1/{organization}/{cluster}/authorization/records/{auth_id}', },  ...options});
    }
  

export const getGetUserDebugAuthorizationRecordsForAuthQueryKey = (organization: string,
    cluster: string,
    authId: string,
    params?: GetUserDebugAuthorizationRecordsForAuthParams,) => [`/api/userdebug/v1/${organization}/${cluster}/authorization/records/${authId}`, ...(params ? [params]: [])];

    
export type GetUserDebugAuthorizationRecordsForAuthQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDebugAuthorizationRecordsForAuth>>>
export type GetUserDebugAuthorizationRecordsForAuthQueryError = UserDebugResponseMessage

export const useGetUserDebugAuthorizationRecordsForAuth = <TData = Awaited<ReturnType<typeof getUserDebugAuthorizationRecordsForAuth>>, TError = UserDebugResponseMessage>(
 organization: string,
    cluster: string,
    authId: string,
    params?: GetUserDebugAuthorizationRecordsForAuthParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserDebugAuthorizationRecordsForAuth>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserDebugAuthorizationRecordsForAuthQueryKey(organization,cluster,authId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDebugAuthorizationRecordsForAuth>>> = ({ signal }) => getUserDebugAuthorizationRecordsForAuth(organization,cluster,authId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserDebugAuthorizationRecordsForAuth>>, TError, TData>({ queryKey, queryFn, enabled: !!(organization && cluster && authId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const setClusterHibernate = (
    clusterid: string,
    hibernateClusterReq: HibernateClusterReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/api/v1/devtier/clusters/${clusterid}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: hibernateClusterReq
    },
      { _metadata: { operationName: 'setClusterHibernate', pathRoute: '/api/v1/devtier/clusters/{clusterid}', },  ...options});
    }
  


    export type SetClusterHibernateMutationResult = NonNullable<Awaited<ReturnType<typeof setClusterHibernate>>>
    export type SetClusterHibernateMutationBody = HibernateClusterReq
    export type SetClusterHibernateMutationError = unknown

    export const useSetClusterHibernate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setClusterHibernate>>, TError,{clusterid: string;data: HibernateClusterReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setClusterHibernate>>, {clusterid: string;data: HibernateClusterReq}> = (props) => {
          const {clusterid,data} = props ?? {};

          return  setClusterHibernate(clusterid,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof setClusterHibernate>>, TError, {clusterid: string;data: HibernateClusterReq}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * getClusterStatus
 * @summary GetClusterStatus
 */
export const getClusterStatus = (
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<string>(
      {url: `/api/v1/devtier/clusters/projects/${projectId}/clusters/${clusterId}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getClusterStatus', pathRoute: '/api/v1/devtier/clusters/projects/{project_id}/clusters/{cluster_id}', },  ...options});
    }
  

export const getGetClusterStatusQueryKey = (projectId: string,
    clusterId: string,) => [`/api/v1/devtier/clusters/projects/${projectId}/clusters/${clusterId}`];

    
export type GetClusterStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getClusterStatus>>>
export type GetClusterStatusQueryError = unknown

export const useGetClusterStatus = <TData = Awaited<ReturnType<typeof getClusterStatus>>, TError = unknown>(
 projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClusterStatus>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClusterStatusQueryKey(projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClusterStatus>>> = ({ signal }) => getClusterStatus(projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getClusterStatus>>, TError, TData>({ queryKey, queryFn, enabled: !!(projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * PlaygroundSQLExecute
 */
export const playgroundSQLExecute = (
    orgId: string,
    projectId: string,
    clusterId: string,
    playgroundSQLReq: PlaygroundSQLReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<PlaygroundSQLRespSucc>(
      {url: `/api/v1/devtier/playground/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: playgroundSQLReq
    },
      { _metadata: { operationName: 'playgroundSQLExecute', pathRoute: '/api/v1/devtier/playground/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}', },  ...options});
    }
  


    export type PlaygroundSQLExecuteMutationResult = NonNullable<Awaited<ReturnType<typeof playgroundSQLExecute>>>
    export type PlaygroundSQLExecuteMutationBody = PlaygroundSQLReq
    export type PlaygroundSQLExecuteMutationError = PlaygroundSQLRespFail

    export const usePlaygroundSQLExecute = <TError = PlaygroundSQLRespFail,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof playgroundSQLExecute>>, TError,{orgId: string;projectId: string;clusterId: string;data: PlaygroundSQLReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof playgroundSQLExecute>>, {orgId: string;projectId: string;clusterId: string;data: PlaygroundSQLReq}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  playgroundSQLExecute(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof playgroundSQLExecute>>, TError, {orgId: string;projectId: string;clusterId: string;data: PlaygroundSQLReq}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * GetClusterPlaygroundStatus
 * @summary GetClusterPlaygroundStatus
 */
export const getClusterPlaygroundStatus = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<string>(
      {url: `/api/v1/devtier/playground/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getClusterPlaygroundStatus', pathRoute: '/api/v1/devtier/playground/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}', },  ...options});
    }
  

export const getGetClusterPlaygroundStatusQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,) => [`/api/v1/devtier/playground/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}`];

    
export type GetClusterPlaygroundStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getClusterPlaygroundStatus>>>
export type GetClusterPlaygroundStatusQueryError = unknown

export const useGetClusterPlaygroundStatus = <TData = Awaited<ReturnType<typeof getClusterPlaygroundStatus>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClusterPlaygroundStatus>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClusterPlaygroundStatusQueryKey(orgId,projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClusterPlaygroundStatus>>> = ({ signal }) => getClusterPlaygroundStatus(orgId,projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getClusterPlaygroundStatus>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getClusterSpendLimit = (
    orgId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<ServerlessGetClusterSpendLimitsResp>(
      {url: `/api/v1/serverless/orgs/${orgId}/clusters/${clusterId}/spend_limits`, method: 'get', signal
    },
      { _metadata: { operationName: 'getClusterSpendLimit', pathRoute: '/api/v1/serverless/orgs/{org_id}/clusters/{cluster_id}/spend_limits', },  ...options});
    }
  

export const getGetClusterSpendLimitQueryKey = (orgId: string,
    clusterId: string,) => [`/api/v1/serverless/orgs/${orgId}/clusters/${clusterId}/spend_limits`];

    
export type GetClusterSpendLimitQueryResult = NonNullable<Awaited<ReturnType<typeof getClusterSpendLimit>>>
export type GetClusterSpendLimitQueryError = GooglerpcStatus

export const useGetClusterSpendLimit = <TData = Awaited<ReturnType<typeof getClusterSpendLimit>>, TError = GooglerpcStatus>(
 orgId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClusterSpendLimit>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetClusterSpendLimitQueryKey(orgId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClusterSpendLimit>>> = ({ signal }) => getClusterSpendLimit(orgId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getClusterSpendLimit>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const changeSpendLimit = (
    orgId: string,
    clusterId: string,
    changeSpendLimitBody: ChangeSpendLimitBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<string>(
      {url: `/api/v1/serverless/orgs/${orgId}/clusters/${clusterId}/spend_limits`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: changeSpendLimitBody
    },
      { _metadata: { operationName: 'changeSpendLimit', pathRoute: '/api/v1/serverless/orgs/{org_id}/clusters/{cluster_id}/spend_limits', },  ...options});
    }
  


    export type ChangeSpendLimitMutationResult = NonNullable<Awaited<ReturnType<typeof changeSpendLimit>>>
    export type ChangeSpendLimitMutationBody = ChangeSpendLimitBody
    export type ChangeSpendLimitMutationError = GooglerpcStatus

    export const useChangeSpendLimit = <TError = GooglerpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeSpendLimit>>, TError,{orgId: string;clusterId: string;data: ChangeSpendLimitBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeSpendLimit>>, {orgId: string;clusterId: string;data: ChangeSpendLimitBody}> = (props) => {
          const {orgId,clusterId,data} = props ?? {};

          return  changeSpendLimit(orgId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof changeSpendLimit>>, TError, {orgId: string;clusterId: string;data: ChangeSpendLimitBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const getSpendLimitDetails = (
    orgId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<ServerlessGetSpendLimitDetailsResp>(
      {url: `/api/v1/serverless/orgs/${orgId}/clusters/${clusterId}/spend_limits/details`, method: 'get', signal
    },
      { _metadata: { operationName: 'getSpendLimitDetails', pathRoute: '/api/v1/serverless/orgs/{org_id}/clusters/{cluster_id}/spend_limits/details', },  ...options});
    }
  

export const getGetSpendLimitDetailsQueryKey = (orgId: string,
    clusterId: string,) => [`/api/v1/serverless/orgs/${orgId}/clusters/${clusterId}/spend_limits/details`];

    
export type GetSpendLimitDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getSpendLimitDetails>>>
export type GetSpendLimitDetailsQueryError = GooglerpcStatus

export const useGetSpendLimitDetails = <TData = Awaited<ReturnType<typeof getSpendLimitDetails>>, TError = GooglerpcStatus>(
 orgId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSpendLimitDetails>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSpendLimitDetailsQueryKey(orgId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSpendLimitDetails>>> = ({ signal }) => getSpendLimitDetails(orgId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getSpendLimitDetails>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getSpendLimitMeasureDetail = (
    orgId: string,
    clusterId: string,
    params: GetSpendLimitMeasureDetailParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<ServerlessSpendLimitsMeasureResp>(
      {url: `/api/v1/serverless/orgs/${orgId}/clusters/${clusterId}/spend_limits/measure`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getSpendLimitMeasureDetail', pathRoute: '/api/v1/serverless/orgs/{org_id}/clusters/{cluster_id}/spend_limits/measure', },  ...options});
    }
  

export const getGetSpendLimitMeasureDetailQueryKey = (orgId: string,
    clusterId: string,
    params: GetSpendLimitMeasureDetailParams,) => [`/api/v1/serverless/orgs/${orgId}/clusters/${clusterId}/spend_limits/measure`, ...(params ? [params]: [])];

    
export type GetSpendLimitMeasureDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getSpendLimitMeasureDetail>>>
export type GetSpendLimitMeasureDetailQueryError = GooglerpcStatus

export const useGetSpendLimitMeasureDetail = <TData = Awaited<ReturnType<typeof getSpendLimitMeasureDetail>>, TError = GooglerpcStatus>(
 orgId: string,
    clusterId: string,
    params: GetSpendLimitMeasureDetailParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSpendLimitMeasureDetail>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSpendLimitMeasureDetailQueryKey(orgId,clusterId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSpendLimitMeasureDetail>>> = ({ signal }) => getSpendLimitMeasureDetail(orgId,clusterId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getSpendLimitMeasureDetail>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const predictionSpendLimitMeasure = (
    orgId: string,
    clusterId: string,
    predictionSpendLimitMeasureBody: PredictionSpendLimitMeasureBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<ServerlessSpendLimitsMeasureResp>(
      {url: `/api/v1/serverless/orgs/${orgId}/clusters/${clusterId}/spend_limits/measure`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: predictionSpendLimitMeasureBody
    },
      { _metadata: { operationName: 'predictionSpendLimitMeasure', pathRoute: '/api/v1/serverless/orgs/{org_id}/clusters/{cluster_id}/spend_limits/measure', },  ...options});
    }
  


    export type PredictionSpendLimitMeasureMutationResult = NonNullable<Awaited<ReturnType<typeof predictionSpendLimitMeasure>>>
    export type PredictionSpendLimitMeasureMutationBody = PredictionSpendLimitMeasureBody
    export type PredictionSpendLimitMeasureMutationError = GooglerpcStatus

    export const usePredictionSpendLimitMeasure = <TError = GooglerpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof predictionSpendLimitMeasure>>, TError,{orgId: string;clusterId: string;data: PredictionSpendLimitMeasureBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof predictionSpendLimitMeasure>>, {orgId: string;clusterId: string;data: PredictionSpendLimitMeasureBody}> = (props) => {
          const {orgId,clusterId,data} = props ?? {};

          return  predictionSpendLimitMeasure(orgId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof predictionSpendLimitMeasure>>, TError, {orgId: string;clusterId: string;data: PredictionSpendLimitMeasureBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const getAllSpendLimit = (
    orgId: string,
    params: GetAllSpendLimitParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<ServerlessGetAllSpendLimitsResp>(
      {url: `/api/v1/serverless/orgs/${orgId}/spend_limits`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getAllSpendLimit', pathRoute: '/api/v1/serverless/orgs/{org_id}/spend_limits', },  ...options});
    }
  

export const getGetAllSpendLimitQueryKey = (orgId: string,
    params: GetAllSpendLimitParams,) => [`/api/v1/serverless/orgs/${orgId}/spend_limits`, ...(params ? [params]: [])];

    
export type GetAllSpendLimitQueryResult = NonNullable<Awaited<ReturnType<typeof getAllSpendLimit>>>
export type GetAllSpendLimitQueryError = GooglerpcStatus

export const useGetAllSpendLimit = <TData = Awaited<ReturnType<typeof getAllSpendLimit>>, TError = GooglerpcStatus>(
 orgId: string,
    params: GetAllSpendLimitParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllSpendLimit>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllSpendLimitQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllSpendLimit>>> = ({ signal }) => getAllSpendLimit(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAllSpendLimit>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const predictSpendLimit = (
    orgId: string,
    params: PredictSpendLimitParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<ServerlessPredictSpendLimitResp>(
      {url: `/api/v1/serverless/orgs/${orgId}/spend_limits/predict`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'predictSpendLimit', pathRoute: '/api/v1/serverless/orgs/{org_id}/spend_limits/predict', },  ...options});
    }
  

export const getPredictSpendLimitQueryKey = (orgId: string,
    params: PredictSpendLimitParams,) => [`/api/v1/serverless/orgs/${orgId}/spend_limits/predict`, ...(params ? [params]: [])];

    
export type PredictSpendLimitQueryResult = NonNullable<Awaited<ReturnType<typeof predictSpendLimit>>>
export type PredictSpendLimitQueryError = GooglerpcStatus

export const usePredictSpendLimit = <TData = Awaited<ReturnType<typeof predictSpendLimit>>, TError = GooglerpcStatus>(
 orgId: string,
    params: PredictSpendLimitParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof predictSpendLimit>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPredictSpendLimitQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof predictSpendLimit>>> = ({ signal }) => predictSpendLimit(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof predictSpendLimit>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const predictClusterSpendLimit = (
    orgId: string,
    clusterId: string,
    params: PredictClusterSpendLimitParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<ServerlessPredictSpendLimitResp>(
      {url: `/api/v1/serverless/orgs/${orgId}/clusters/${clusterId}/spend_limits/predict`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'predictClusterSpendLimit', pathRoute: '/api/v1/serverless/orgs/{org_id}/clusters/{cluster_id}/spend_limits/predict', },  ...options});
    }
  

export const getPredictClusterSpendLimitQueryKey = (orgId: string,
    clusterId: string,
    params: PredictClusterSpendLimitParams,) => [`/api/v1/serverless/orgs/${orgId}/clusters/${clusterId}/spend_limits/predict`, ...(params ? [params]: [])];

    
export type PredictClusterSpendLimitQueryResult = NonNullable<Awaited<ReturnType<typeof predictClusterSpendLimit>>>
export type PredictClusterSpendLimitQueryError = GooglerpcStatus

export const usePredictClusterSpendLimit = <TData = Awaited<ReturnType<typeof predictClusterSpendLimit>>, TError = GooglerpcStatus>(
 orgId: string,
    clusterId: string,
    params: PredictClusterSpendLimitParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof predictClusterSpendLimit>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPredictClusterSpendLimitQueryKey(orgId,clusterId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof predictClusterSpendLimit>>> = ({ signal }) => predictClusterSpendLimit(orgId,clusterId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof predictClusterSpendLimit>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * import fortune500
 * @summary import fortune500
 */
export const importFortuneData = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoSampleInitializeRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sample/initialize`, method: 'post'
    },
      { _metadata: { operationName: 'importFortuneData', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/sample/initialize', },  ...options});
    }
  


    export type ImportFortuneDataMutationResult = NonNullable<Awaited<ReturnType<typeof importFortuneData>>>
    
    export type ImportFortuneDataMutationError = unknown

    export const useImportFortuneData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importFortuneData>>, TError,{orgId: string;projectId: string;clusterId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof importFortuneData>>, {orgId: string;projectId: string;clusterId: string}> = (props) => {
          const {orgId,projectId,clusterId} = props ?? {};

          return  importFortuneData(orgId,projectId,clusterId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof importFortuneData>>, TError, {orgId: string;projectId: string;clusterId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * GetSqlEditorDbList
 * @summary show db
 */
export const getSqlEditorDbList = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<DtoShowDBsRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/dbs`, method: 'get', signal
    },
      { _metadata: { operationName: 'getSqlEditorDbList', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/dbs', },  ...options});
    }
  

export const getGetSqlEditorDbListQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,) => [`/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/dbs`];

    
export type GetSqlEditorDbListQueryResult = NonNullable<Awaited<ReturnType<typeof getSqlEditorDbList>>>
export type GetSqlEditorDbListQueryError = unknown

export const useGetSqlEditorDbList = <TData = Awaited<ReturnType<typeof getSqlEditorDbList>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSqlEditorDbList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSqlEditorDbListQueryKey(orgId,projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSqlEditorDbList>>> = ({ signal }) => getSqlEditorDbList(orgId,projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getSqlEditorDbList>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * GetSqlEditorTableList
 * @summary show tables
 */
export const getSqlEditorTableList = (
    orgId: string,
    projectId: string,
    clusterId: string,
    dbName: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<DtoShowTablesRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/dbs/${dbName}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getSqlEditorTableList', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/dbs/{db_name}', },  ...options});
    }
  

export const getGetSqlEditorTableListQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,
    dbName: string,) => [`/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/dbs/${dbName}`];

    
export type GetSqlEditorTableListQueryResult = NonNullable<Awaited<ReturnType<typeof getSqlEditorTableList>>>
export type GetSqlEditorTableListQueryError = unknown

export const useGetSqlEditorTableList = <TData = Awaited<ReturnType<typeof getSqlEditorTableList>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string,
    dbName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSqlEditorTableList>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSqlEditorTableListQueryKey(orgId,projectId,clusterId,dbName);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSqlEditorTableList>>> = ({ signal }) => getSqlEditorTableList(orgId,projectId,clusterId,dbName, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getSqlEditorTableList>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId && dbName), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * table meta
 * @summary show table meta
 */
export const getDbMeta = (
    orgId: string,
    projectId: string,
    clusterId: string,
    dbName: string,
    tableName: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<DtoShowTableMetaRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/dbs/${dbName}/${tableName}/meta`, method: 'get', signal
    },
      { _metadata: { operationName: 'getDbMeta', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/dbs/{db_name}/{table_name}/meta', },  ...options});
    }
  

export const getGetDbMetaQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,
    dbName: string,
    tableName: string,) => [`/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/dbs/${dbName}/${tableName}/meta`];

    
export type GetDbMetaQueryResult = NonNullable<Awaited<ReturnType<typeof getDbMeta>>>
export type GetDbMetaQueryError = unknown

export const useGetDbMeta = <TData = Awaited<ReturnType<typeof getDbMeta>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string,
    dbName: string,
    tableName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDbMeta>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetDbMetaQueryKey(orgId,projectId,clusterId,dbName,tableName);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDbMeta>>> = ({ signal }) => getDbMeta(orgId,projectId,clusterId,dbName,tableName, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getDbMeta>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId && dbName && tableName), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * show all db meta
 * @summary show all db meta
 */
export const getAllDbData = (
    orgId: string,
    projectId: string,
    clusterId: string,
    params: GetAllDbDataParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<DtoClusterMetaRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/meta`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getAllDbData', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/meta', },  ...options});
    }
  

export const getGetAllDbDataQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,
    params: GetAllDbDataParams,) => [`/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/meta`, ...(params ? [params]: [])];

    
export type GetAllDbDataQueryResult = NonNullable<Awaited<ReturnType<typeof getAllDbData>>>
export type GetAllDbDataQueryError = unknown

export const useGetAllDbData = <TData = Awaited<ReturnType<typeof getAllDbData>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string,
    params: GetAllDbDataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllDbData>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllDbDataQueryKey(orgId,projectId,clusterId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllDbData>>> = ({ signal }) => getAllDbData(orgId,projectId,clusterId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAllDbData>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * create session result
 * @summary create session
 */
export const createsSqlEditorSession = (
    orgId: string,
    projectId: string,
    clusterId: string,
    entitySessionParam: EntitySessionParam,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoSessionRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/session`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: entitySessionParam
    },
      { _metadata: { operationName: 'createsSqlEditorSession', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/session', },  ...options});
    }
  


    export type CreatesSqlEditorSessionMutationResult = NonNullable<Awaited<ReturnType<typeof createsSqlEditorSession>>>
    export type CreatesSqlEditorSessionMutationBody = EntitySessionParam
    export type CreatesSqlEditorSessionMutationError = unknown

    export const useCreatesSqlEditorSession = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createsSqlEditorSession>>, TError,{orgId: string;projectId: string;clusterId: string;data: EntitySessionParam}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createsSqlEditorSession>>, {orgId: string;projectId: string;clusterId: string;data: EntitySessionParam}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  createsSqlEditorSession(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createsSqlEditorSession>>, TError, {orgId: string;projectId: string;clusterId: string;data: EntitySessionParam}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * chat with bot streaming
 * @summary chat with bot streaming
 */
export const generateSqlByBotStreaming = (
    orgId: string,
    projectId: string,
    params: GenerateSqlByBotStreamingParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<DtoStreamingInteractResponse>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/streaming`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'generateSqlByBotStreaming', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/streaming', },  ...options});
    }
  

export const getGenerateSqlByBotStreamingQueryKey = (orgId: string,
    projectId: string,
    params: GenerateSqlByBotStreamingParams,) => [`/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/streaming`, ...(params ? [params]: [])];

    
export type GenerateSqlByBotStreamingQueryResult = NonNullable<Awaited<ReturnType<typeof generateSqlByBotStreaming>>>
export type GenerateSqlByBotStreamingQueryError = unknown

export const useGenerateSqlByBotStreaming = <TData = Awaited<ReturnType<typeof generateSqlByBotStreaming>>, TError = unknown>(
 orgId: string,
    projectId: string,
    params: GenerateSqlByBotStreamingParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof generateSqlByBotStreaming>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGenerateSqlByBotStreamingQueryKey(orgId,projectId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof generateSqlByBotStreaming>>> = ({ signal }) => generateSqlByBotStreaming(orgId,projectId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof generateSqlByBotStreaming>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * chat with bot
 * @summary chat with bot
 */
export const generateSqlByBot = (
    orgId: string,
    projectId: string,
    dtoInteractRequest: DtoInteractRequest,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoInteractResponse>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/bot`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: dtoInteractRequest
    },
      { _metadata: { operationName: 'generateSqlByBot', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/bot', },  ...options});
    }
  


    export type GenerateSqlByBotMutationResult = NonNullable<Awaited<ReturnType<typeof generateSqlByBot>>>
    export type GenerateSqlByBotMutationBody = DtoInteractRequest
    export type GenerateSqlByBotMutationError = unknown

    export const useGenerateSqlByBot = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof generateSqlByBot>>, TError,{orgId: string;projectId: string;data: DtoInteractRequest}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof generateSqlByBot>>, {orgId: string;projectId: string;data: DtoInteractRequest}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  generateSqlByBot(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof generateSqlByBot>>, TError, {orgId: string;projectId: string;data: DtoInteractRequest}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * close session result
 * @summary close session
 */
export const deleteSqlEditorSession = (
    orgId: string,
    projectId: string,
    clusterId: string,
    sessionId: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoSessionRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/session/${sessionId}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteSqlEditorSession', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/session/{session_id}', },  ...options});
    }
  


    export type DeleteSqlEditorSessionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSqlEditorSession>>>
    
    export type DeleteSqlEditorSessionMutationError = unknown

    export const useDeleteSqlEditorSession = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSqlEditorSession>>, TError,{orgId: string;projectId: string;clusterId: string;sessionId: string}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSqlEditorSession>>, {orgId: string;projectId: string;clusterId: string;sessionId: string}> = (props) => {
          const {orgId,projectId,clusterId,sessionId} = props ?? {};

          return  deleteSqlEditorSession(orgId,projectId,clusterId,sessionId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteSqlEditorSession>>, TError, {orgId: string;projectId: string;clusterId: string;sessionId: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * GetSqlEditorRowsSetting
 * @summary show settings
 */
export const getSqlEditorRowsSetting = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<DtoShowSettingsRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/settings`, method: 'get', signal
    },
      { _metadata: { operationName: 'getSqlEditorRowsSetting', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/settings', },  ...options});
    }
  

export const getGetSqlEditorRowsSettingQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,) => [`/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/settings`];

    
export type GetSqlEditorRowsSettingQueryResult = NonNullable<Awaited<ReturnType<typeof getSqlEditorRowsSetting>>>
export type GetSqlEditorRowsSettingQueryError = unknown

export const useGetSqlEditorRowsSetting = <TData = Awaited<ReturnType<typeof getSqlEditorRowsSetting>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSqlEditorRowsSetting>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSqlEditorRowsSettingQueryKey(orgId,projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSqlEditorRowsSetting>>> = ({ signal }) => getSqlEditorRowsSetting(orgId,projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getSqlEditorRowsSetting>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * SqlEditorRowsSetting
 * @summary update settings
 */
export const sqlEditorRowsSetting = (
    orgId: string,
    projectId: string,
    clusterId: string,
    entitySettingsParam: EntitySettingsParam,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoUpdateSettingsRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/settings`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: entitySettingsParam
    },
      { _metadata: { operationName: 'sqlEditorRowsSetting', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/settings', },  ...options});
    }
  


    export type SqlEditorRowsSettingMutationResult = NonNullable<Awaited<ReturnType<typeof sqlEditorRowsSetting>>>
    export type SqlEditorRowsSettingMutationBody = EntitySettingsParam
    export type SqlEditorRowsSettingMutationError = unknown

    export const useSqlEditorRowsSetting = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sqlEditorRowsSetting>>, TError,{orgId: string;projectId: string;clusterId: string;data: EntitySettingsParam}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sqlEditorRowsSetting>>, {orgId: string;projectId: string;clusterId: string;data: EntitySettingsParam}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  sqlEditorRowsSetting(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof sqlEditorRowsSetting>>, TError, {orgId: string;projectId: string;clusterId: string;data: EntitySettingsParam}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * GetSqlEditorFiles
 * @summary list sql file
 */
export const getSqlEditorFiles = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<DtoListSQLFileRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sqlfiles`, method: 'get', signal
    },
      { _metadata: { operationName: 'getSqlEditorFiles', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/sqlfiles', },  ...options});
    }
  

export const getGetSqlEditorFilesQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,) => [`/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sqlfiles`];

    
export type GetSqlEditorFilesQueryResult = NonNullable<Awaited<ReturnType<typeof getSqlEditorFiles>>>
export type GetSqlEditorFilesQueryError = unknown

export const useGetSqlEditorFiles = <TData = Awaited<ReturnType<typeof getSqlEditorFiles>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSqlEditorFiles>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSqlEditorFilesQueryKey(orgId,projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSqlEditorFiles>>> = ({ signal }) => getSqlEditorFiles(orgId,projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getSqlEditorFiles>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * CreateSqlEditorFile
 * @summary create sql file
 */
export const createSqlEditorFile = (
    orgId: string,
    projectId: string,
    clusterId: string,
    entitySqlEditorFileBody: EntitySqlEditorFileBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoCreateSQLFileRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sqlfiles`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: entitySqlEditorFileBody
    },
      { _metadata: { operationName: 'createSqlEditorFile', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/sqlfiles', },  ...options});
    }
  


    export type CreateSqlEditorFileMutationResult = NonNullable<Awaited<ReturnType<typeof createSqlEditorFile>>>
    export type CreateSqlEditorFileMutationBody = EntitySqlEditorFileBody
    export type CreateSqlEditorFileMutationError = unknown

    export const useCreateSqlEditorFile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSqlEditorFile>>, TError,{orgId: string;projectId: string;clusterId: string;data: EntitySqlEditorFileBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSqlEditorFile>>, {orgId: string;projectId: string;clusterId: string;data: EntitySqlEditorFileBody}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  createSqlEditorFile(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createSqlEditorFile>>, TError, {orgId: string;projectId: string;clusterId: string;data: EntitySqlEditorFileBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * GetSqlEditorFile
 * @summary GetSqlEditorFile
 */
export const getSqlEditorFile = (
    orgId: string,
    projectId: string,
    clusterId: string,
    sqlFileId: number,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<DtoShowSQLFileRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sqlfiles/${sqlFileId}`, method: 'get', signal
    },
      { _metadata: { operationName: 'getSqlEditorFile', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/sqlfiles/{sql_file_id}', },  ...options});
    }
  

export const getGetSqlEditorFileQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,
    sqlFileId: number,) => [`/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sqlfiles/${sqlFileId}`];

    
export type GetSqlEditorFileQueryResult = NonNullable<Awaited<ReturnType<typeof getSqlEditorFile>>>
export type GetSqlEditorFileQueryError = unknown

export const useGetSqlEditorFile = <TData = Awaited<ReturnType<typeof getSqlEditorFile>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string,
    sqlFileId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSqlEditorFile>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSqlEditorFileQueryKey(orgId,projectId,clusterId,sqlFileId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSqlEditorFile>>> = ({ signal }) => getSqlEditorFile(orgId,projectId,clusterId,sqlFileId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getSqlEditorFile>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId && sqlFileId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * UpdateSqlEditorFile
 * @summary update sql file
 */
export const updateSqlEditorFile = (
    orgId: string,
    projectId: string,
    clusterId: string,
    sqlFileId: number,
    entitySqlEditorFileBody: EntitySqlEditorFileBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoUpdateSQLFileRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sqlfiles/${sqlFileId}`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: entitySqlEditorFileBody
    },
      { _metadata: { operationName: 'updateSqlEditorFile', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/sqlfiles/{sql_file_id}', },  ...options});
    }
  


    export type UpdateSqlEditorFileMutationResult = NonNullable<Awaited<ReturnType<typeof updateSqlEditorFile>>>
    export type UpdateSqlEditorFileMutationBody = EntitySqlEditorFileBody
    export type UpdateSqlEditorFileMutationError = unknown

    export const useUpdateSqlEditorFile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSqlEditorFile>>, TError,{orgId: string;projectId: string;clusterId: string;sqlFileId: number;data: EntitySqlEditorFileBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSqlEditorFile>>, {orgId: string;projectId: string;clusterId: string;sqlFileId: number;data: EntitySqlEditorFileBody}> = (props) => {
          const {orgId,projectId,clusterId,sqlFileId,data} = props ?? {};

          return  updateSqlEditorFile(orgId,projectId,clusterId,sqlFileId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateSqlEditorFile>>, TError, {orgId: string;projectId: string;clusterId: string;sqlFileId: number;data: EntitySqlEditorFileBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * DeleteSqlEditorFile
 * @summary delete sql file
 */
export const deleteSqlEditorFile = (
    orgId: string,
    projectId: string,
    clusterId: string,
    sqlFileId: number,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoDeleteSQLFileRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sqlfiles/${sqlFileId}`, method: 'delete'
    },
      { _metadata: { operationName: 'deleteSqlEditorFile', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/sqlfiles/{sql_file_id}', },  ...options});
    }
  


    export type DeleteSqlEditorFileMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSqlEditorFile>>>
    
    export type DeleteSqlEditorFileMutationError = unknown

    export const useDeleteSqlEditorFile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSqlEditorFile>>, TError,{orgId: string;projectId: string;clusterId: string;sqlFileId: number}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSqlEditorFile>>, {orgId: string;projectId: string;clusterId: string;sqlFileId: number}> = (props) => {
          const {orgId,projectId,clusterId,sqlFileId} = props ?? {};

          return  deleteSqlEditorFile(orgId,projectId,clusterId,sqlFileId,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteSqlEditorFile>>, TError, {orgId: string;projectId: string;clusterId: string;sqlFileId: number}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * SqlEditorSQLExecute
 * @summary execute sql
 */
export const sqlEditorSQLExecute = (
    orgId: string,
    projectId: string,
    clusterId: string,
    entityStatementParam: EntityStatementParam,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoStatementsRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/statements`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: entityStatementParam
    },
      { _metadata: { operationName: 'sqlEditorSQLExecute', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/statements', },  ...options});
    }
  


    export type SqlEditorSQLExecuteMutationResult = NonNullable<Awaited<ReturnType<typeof sqlEditorSQLExecute>>>
    export type SqlEditorSQLExecuteMutationBody = EntityStatementParam
    export type SqlEditorSQLExecuteMutationError = unknown

    export const useSqlEditorSQLExecute = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sqlEditorSQLExecute>>, TError,{orgId: string;projectId: string;clusterId: string;data: EntityStatementParam}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sqlEditorSQLExecute>>, {orgId: string;projectId: string;clusterId: string;data: EntityStatementParam}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  sqlEditorSQLExecute(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof sqlEditorSQLExecute>>, TError, {orgId: string;projectId: string;clusterId: string;data: EntityStatementParam}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * show user info
 * @summary show user info
 */
export const getAIUserSetting = (
    orgId: string,
    projectId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<DtoShowUserInfoRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/users`, method: 'get', signal
    },
      { _metadata: { operationName: 'getAIUserSetting', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/users', },  ...options});
    }
  

export const getGetAIUserSettingQueryKey = (orgId: string,
    projectId: string,) => [`/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/users`];

    
export type GetAIUserSettingQueryResult = NonNullable<Awaited<ReturnType<typeof getAIUserSetting>>>
export type GetAIUserSettingQueryError = unknown

export const useGetAIUserSetting = <TData = Awaited<ReturnType<typeof getAIUserSetting>>, TError = unknown>(
 orgId: string,
    projectId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAIUserSetting>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAIUserSettingQueryKey(orgId,projectId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAIUserSetting>>> = ({ signal }) => getAIUserSetting(orgId,projectId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAIUserSetting>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * update user info
 * @summary update user info
 */
export const updateAIUserSetting = (
    orgId: string,
    projectId: string,
    entityUserInfoParam: EntityUserInfoParam,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoUpdateUserInfoRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/users`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: entityUserInfoParam
    },
      { _metadata: { operationName: 'updateAIUserSetting', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/users', },  ...options});
    }
  


    export type UpdateAIUserSettingMutationResult = NonNullable<Awaited<ReturnType<typeof updateAIUserSetting>>>
    export type UpdateAIUserSettingMutationBody = EntityUserInfoParam
    export type UpdateAIUserSettingMutationError = unknown

    export const useUpdateAIUserSetting = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAIUserSetting>>, TError,{orgId: string;projectId: string;data: EntityUserInfoParam}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAIUserSetting>>, {orgId: string;projectId: string;data: EntityUserInfoParam}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  updateAIUserSetting(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateAIUserSetting>>, TError, {orgId: string;projectId: string;data: EntityUserInfoParam}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * is table exist
 * @summary is table exist
 */
export const getIsTableExist = (
    orgId: string,
    projectId: string,
    clusterId: string,
    dbName: string,
    tableName: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<DtoIsDBTableExistRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/dbs/${dbName}/${tableName}/exist`, method: 'get', signal
    },
      { _metadata: { operationName: 'getIsTableExist', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/dbs/{db_name}/{table_name}/exist', },  ...options});
    }
  

export const getGetIsTableExistQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,
    dbName: string,
    tableName: string,) => [`/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/dbs/${dbName}/${tableName}/exist`];

    
export type GetIsTableExistQueryResult = NonNullable<Awaited<ReturnType<typeof getIsTableExist>>>
export type GetIsTableExistQueryError = unknown

export const useGetIsTableExist = <TData = Awaited<ReturnType<typeof getIsTableExist>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string,
    dbName: string,
    tableName: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIsTableExist>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetIsTableExistQueryKey(orgId,projectId,clusterId,dbName,tableName);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIsTableExist>>> = ({ signal }) => getIsTableExist(orgId,projectId,clusterId,dbName,tableName, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getIsTableExist>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId && dbName && tableName), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * show chat2data sample url
 * @summary show chat2data sample url
 */
export const getSampleUrl = (
    orgId: string,
    projectId: string,
    clusterId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<DtoGenericSuccessApiResponse>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/dataapi/sample`, method: 'get', signal
    },
      { _metadata: { operationName: 'getSampleUrl', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/dataapi/sample', },  ...options});
    }
  

export const getGetSampleUrlQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,) => [`/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/dataapi/sample`];

    
export type GetSampleUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getSampleUrl>>>
export type GetSampleUrlQueryError = unknown

export const useGetSampleUrl = <TData = Awaited<ReturnType<typeof getSampleUrl>>, TError = unknown>(
 orgId: string,
    projectId: string,
    clusterId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSampleUrl>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSampleUrlQueryKey(orgId,projectId,clusterId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSampleUrl>>> = ({ signal }) => getSampleUrl(orgId,projectId,clusterId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getSampleUrl>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * update dataapp info
 * @summary update dataapp info
 */
export const updateChat2DataSettings = (
    orgId: string,
    projectId: string,
    entityDataAppSettingsFormat: EntityDataAppSettingsFormat,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoGenericSuccessApiResponse>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/settings`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: entityDataAppSettingsFormat
    },
      { _metadata: { operationName: 'updateChat2DataSettings', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/settings', },  ...options});
    }
  


    export type UpdateChat2DataSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof updateChat2DataSettings>>>
    export type UpdateChat2DataSettingsMutationBody = EntityDataAppSettingsFormat
    export type UpdateChat2DataSettingsMutationError = unknown

    export const useUpdateChat2DataSettings = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateChat2DataSettings>>, TError,{orgId: string;projectId: string;data: EntityDataAppSettingsFormat}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateChat2DataSettings>>, {orgId: string;projectId: string;data: EntityDataAppSettingsFormat}> = (props) => {
          const {orgId,projectId,data} = props ?? {};

          return  updateChat2DataSettings(orgId,projectId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateChat2DataSettings>>, TError, {orgId: string;projectId: string;data: EntityDataAppSettingsFormat}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * create oplog
 * @summary create oplog
 */
export const createChat2QueryOplog = (
    orgId: string,
    projectId: string,
    clusterId: string,
    entitySqlEditorOplogParam: EntitySqlEditorOplogParam,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DtoBasicRes>(
      {url: `/api/v1/dataapps/sqleditor/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/oplog`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: entitySqlEditorOplogParam
    },
      { _metadata: { operationName: 'createChat2QueryOplog', pathRoute: '/api/v1/dataapps/sqleditor/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/oplog', },  ...options});
    }
  


    export type CreateChat2QueryOplogMutationResult = NonNullable<Awaited<ReturnType<typeof createChat2QueryOplog>>>
    export type CreateChat2QueryOplogMutationBody = EntitySqlEditorOplogParam
    export type CreateChat2QueryOplogMutationError = unknown

    export const useCreateChat2QueryOplog = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createChat2QueryOplog>>, TError,{orgId: string;projectId: string;clusterId: string;data: EntitySqlEditorOplogParam}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createChat2QueryOplog>>, {orgId: string;projectId: string;clusterId: string;data: EntitySqlEditorOplogParam}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  createChat2QueryOplog(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createChat2QueryOplog>>, TError, {orgId: string;projectId: string;clusterId: string;data: EntitySqlEditorOplogParam}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary query audit log
 */
export const getAuditLogs = (
    orgId: string,
    params?: GetAuditLogsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<TypesQueryAuditLogResp>(
      {url: `/api/v1/audit/${orgId}/audit_logs`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getAuditLogs', pathRoute: '/api/v1/audit/{org_id}/audit_logs', },  ...options});
    }
  

export const getGetAuditLogsQueryKey = (orgId: string,
    params?: GetAuditLogsParams,) => [`/api/v1/audit/${orgId}/audit_logs`, ...(params ? [params]: [])];

    
export type GetAuditLogsQueryResult = NonNullable<Awaited<ReturnType<typeof getAuditLogs>>>
export type GetAuditLogsQueryError = unknown

export const useGetAuditLogs = <TData = Awaited<ReturnType<typeof getAuditLogs>>, TError = unknown>(
 orgId: string,
    params?: GetAuditLogsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAuditLogs>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAuditLogsQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAuditLogs>>> = ({ signal }) => getAuditLogs(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getAuditLogs>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary query event types
 */
export const getEventTypes = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<TypesEventTypesResp>(
      {url: `/api/v1/audit/event_types`, method: 'get', signal
    },
      { _metadata: { operationName: 'getEventTypes', pathRoute: '/api/v1/audit/event_types', },  ...options});
    }
  

export const getGetEventTypesQueryKey = () => [`/api/v1/audit/event_types`];

    
export type GetEventTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getEventTypes>>>
export type GetEventTypesQueryError = unknown

export const useGetEventTypes = <TData = Awaited<ReturnType<typeof getEventTypes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventTypes>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetEventTypesQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventTypes>>> = ({ signal }) => getEventTypes(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getEventTypes>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary update settings
 */
export const updateSettings = (
    orgId: string,
    typesUpdateSettingResp: TypesUpdateSettingResp,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<void>(
      {url: `/api/v1/audit/${orgId}/settings`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: typesUpdateSettingResp
    },
      { _metadata: { operationName: 'updateSettings', pathRoute: '/api/v1/audit/{org_id}/settings', },  ...options});
    }
  


    export type UpdateSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof updateSettings>>>
    export type UpdateSettingsMutationBody = TypesUpdateSettingResp
    export type UpdateSettingsMutationError = unknown

    export const useUpdateSettings = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSettings>>, TError,{orgId: string;data: TypesUpdateSettingResp}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSettings>>, {orgId: string;data: TypesUpdateSettingResp}> = (props) => {
          const {orgId,data} = props ?? {};

          return  updateSettings(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateSettings>>, TError, {orgId: string;data: TypesUpdateSettingResp}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * @summary query settings
 */
export const getSettings = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<TypesQuerySettingsResp>(
      {url: `/api/v1/audit/${orgId}/settings`, method: 'get', signal
    },
      { _metadata: { operationName: 'getSettings', pathRoute: '/api/v1/audit/{org_id}/settings', },  ...options});
    }
  

export const getGetSettingsQueryKey = (orgId: string,) => [`/api/v1/audit/${orgId}/settings`];

    
export type GetSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getSettings>>>
export type GetSettingsQueryError = unknown

export const useGetSettings = <TData = Awaited<ReturnType<typeof getSettings>>, TError = unknown>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSettings>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetSettingsQueryKey(orgId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSettings>>> = ({ signal }) => getSettings(orgId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getSettings>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * @summary query audit log
 */
export const auditLogsExport = (
    orgId: string,
    params?: AuditLogsExportParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<unknown>(
      {url: `/api/v1/audit/${orgId}/audit_logs/export`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'auditLogsExport', pathRoute: '/api/v1/audit/{org_id}/audit_logs/export', },  ...options});
    }
  

export const getAuditLogsExportQueryKey = (orgId: string,
    params?: AuditLogsExportParams,) => [`/api/v1/audit/${orgId}/audit_logs/export`, ...(params ? [params]: [])];

    
export type AuditLogsExportQueryResult = NonNullable<Awaited<ReturnType<typeof auditLogsExport>>>
export type AuditLogsExportQueryError = unknown

export const useAuditLogsExport = <TData = Awaited<ReturnType<typeof auditLogsExport>>, TError = unknown>(
 orgId: string,
    params?: AuditLogsExportParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof auditLogsExport>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getAuditLogsExportQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof auditLogsExport>>> = ({ signal }) => auditLogsExport(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof auditLogsExport>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


