import { CentralClusterDevTierClusterType, CentralCluster, CentralClusterStatus } from 'dbaas/services'
import { isCrossAccount } from 'dbaas/utils/chore'

export function isServerlessTier(clusterOrType: CentralCluster | CentralClusterDevTierClusterType): boolean {
  if (typeof clusterOrType === 'string') {
    return clusterOrType === CentralClusterDevTierClusterType.DEVTIER_SERVERLESS
  }
  return clusterOrType?.dev_tier_type === CentralClusterDevTierClusterType.DEVTIER_SERVERLESS
}

export function canImportFromLocal(cluster: CentralCluster) {
  return cluster?.is_dev_tier ?? false
}

export function getClusterType(c?: CentralCluster): 'Serverless' | 'DevTier' | 'Dedicated' | 'Unselected' {
  if (!c) {
    return 'Unselected'
  }

  if (isServerlessTier(c)) {
    return 'Serverless'
  }

  if (c.is_dev_tier) {
    return 'DevTier'
  }

  return 'Dedicated'
}

export const isClusterReady = (cluster: CentralCluster) => {
  cluster ??= {} as CentralCluster
  const { status, cross_account_deployment_id, endpoint } = cluster
  return !(
    (
      [
        CentralClusterStatus.CREATING,
        CentralClusterStatus.RECOVERING,
        CentralClusterStatus.IMPORTING
      ] as CentralClusterStatus[]
    ).includes(status) ||
    (!endpoint?.ready && !isCrossAccount(cross_account_deployment_id))
  )
}
