import { isBoolean, isString } from 'lodash-es'

import config from 'config'
import { CentralUserProfile } from 'dbaas/services'

/******************
 * Env constants
 ******************/
const __DEV__ = config.NODE_ENV === 'development'
const __TESTING__ = config.RELEASE_ENV === 'dev'
const __STAGING__ = config.RELEASE_ENV === 'staging'

/****************************
 * Feature flag filters pipe
 ****************************/

/**
 * Create a feature flag filters pipe
 */
function createFlagFiltersPipe(
  defaultEnabledEnv?: Partial<{
    dev: boolean
    testing: boolean
  }>
) {
  const { dev: devEnabled = true, testing: testingEnabled = true } = defaultEnabledEnv || {}
  const defaultEnabled = (__DEV__ && devEnabled) || (__TESTING__ && testingEnabled)

  return (
    filters: {
      enabled: boolean | undefined
      endIf?: (enabled: boolean | undefined) => boolean
    }[]
  ) => {
    if (defaultEnabled) {
      return true
    }

    const { returnVal } = filters.reduce(
      (prev, filter) => {
        if (prev.over) {
          return prev
        }

        const { enabled, endIf } = filter

        if (endIf?.(enabled)) {
          return {
            over: true,
            returnVal: enabled
          }
        }

        return {
          over: false,
          returnVal: enabled
        }
      },
      {
        over: false,
        returnVal: undefined
      }
    )

    return isBoolean(returnVal) ? returnVal : true
  }
}

/**
 * The default feature flag filters pipe,
 * feature is enabled default for __DEV__ or __TESTING__
 */
const pipeFlagFilters = createFlagFiltersPipe()

/**
 * A feature flag filters pipe for deployment version,
 * feature is enabled default for __DEV__
 */
const pipeFlagFiltersForDeploy = createFlagFiltersPipe({ testing: false })

/*****************************
 * Feature flag filter utils
 *****************************/

const onOffMapping = {
  on: true,
  off: false
}

/**
 * Filter to force a feature be enabled or disabled
 * @param storeKey key of item of session storage
 */
const isForceEnabled = (storeKey: string) => {
  const onOff = sessionStorage.getItem(storeKey)
  const enabled = isString(onOff) ? onOffMapping[onOff.toLowerCase()] : void 0

  return isBoolean(enabled) ? enabled : void 0
}

/**
 * Filter to control enabled or disabled with environment variable
 * @deprecated use `createEnvVarFilter` to create an env var filter
 *
 * @param options.envVar environment variable
 * @param options.initial the initial value of environment variable
 */
const isEnvVarEnabled = (options: { envVar: string | undefined; initial?: boolean }) => {
  const { envVar, initial = true } = options
  const enabled = !!JSON.parse(envVar || String(initial))

  return enabled
}

/**
 * Filter to control enabled or disabled with the user profile
 * @deprecated use `isMailEnabled` instead
 */
const isPrivateEnabled = (userProfile: CentralUserProfile) => {
  return /@pingcap\.com$/.test(userProfile.email)
}

interface EnvVarFilterParams {
  envVar?: string
  targetVal: string
}

/**
 * Create a feature flag filter that uses environment variable
 *
 * @param pattern custom filter to resolve value of env var
 *
 * Return of `envVarFilter` v2:
 * When `envVar` is not existed or not a string, it returns `false`
 * When `envVar` is a empty string, it returns `false`
 * When `envVar` is equal to `*`, it returns `true`
 * Otherwise, it calls `pattern` to return boolean
 *
 * Return of `envVarFilter` v1:
 * When `envVar` is not existed or not a string, it returns `true`
 * When `envVar` is a empty string, it returns `false`
 * When `envVar` is equal to `*`, it returns `true`
 * Otherwise, it calls `pattern` to return boolean
 */
const createEnvVarFilter = (pattern: (options: EnvVarFilterParams) => boolean) => {
  const preFilterVers = {
    v2: [
      {
        condition: (val?: string) => !isString(val),
        ret: false
      },
      {
        condition: (val?: string) => val?.trim() === '',
        ret: false
      },
      {
        condition: (val?: string) => val === '*',
        ret: true
      }
    ],
    /**
     * The legacy version is v1
     */
    v1: [
      {
        condition: (val?: string) => !isString(val),
        ret: true
      },
      {
        condition: (val?: string) => val?.trim() === '',
        ret: false
      },
      {
        condition: (val?: string) => val === '*',
        ret: true
      }
    ]
  }

  const envVarFilter = (options: EnvVarFilterParams, version?: 'v1' | 'v2') => {
    const { envVar } = options
    const preFilters = version ? preFilterVers[version] : preFilterVers.v1
    const preFilter = preFilters.find(({ condition }) => condition(envVar))

    return preFilter ? preFilter.ret : pattern(options)
  }

  return envVarFilter
}

/**
 * Filter to control enabled or disabled with user's mail
 *
 * @param options.envVar value of environment variable
 * @param options.targetVal user's email
 * @param version pre filters version
 */
const isMailEnabled = createEnvVarFilter((options) => {
  const { envVar = '', targetVal: email } = options
  const mailList = envVar.split(',')
  const emailDomain = email.split('@')[1] ?? ''

  return mailList.includes(`@${emailDomain}`) || mailList.includes(email)
})

/**
 * Filter to control enabled or disabled with string list
 *
 * @param options.envVar value of environment variable
 * @param options.targetVal single item or item array
 * @param version pre filters version
 *
 * @example
 * const targetVal = 'abc,def'
 * const result = isListIncluded({ envVar: 'THE_ENV_NAME', targetVal }, 'v2')
 */
const isListIncluded = createEnvVarFilter((options) => {
  const { envVar = '', targetVal } = options
  const itemList = envVar.split(',')

  return itemList.includes(targetVal)
})

/******************************
 * Config feature flags below
 ******************************/

export const isGettingStartedEnabled = (userProfile: CentralUserProfile) => {
  return pipeFlagFilters([
    {
      enabled: isEnvVarEnabled({
        envVar: process.env.REACT_APP_GETTING_STARTED_ENABLED,
        initial: false
      }),
      endIf: (enabled) => !!enabled
    },
    {
      enabled: isPrivateEnabled(userProfile)
    }
  ])
}

export const isServerlessAdvancedEnabled = (userProfile: CentralUserProfile) => {
  return isMailEnabled({
    envVar: process.env.REACT_APP_CLUSTER_SERVERLESS_ADVANCED_WHITELIST,
    targetVal: userProfile.email
  })
}

export const isServerlessVectorEnvEnabled = (userProfile?: CentralUserProfile) => {
  return __DEV__ || __TESTING__
}

export const isServerlessVectorRegionIncluded = (region: string) => {
  return isListIncluded(
    {
      envVar: process.env.REACT_APP_SERVERLESS_VECTOR_REGION_WHITELIST,
      targetVal: region
    },
    'v2'
  )
}

export const isClusterCreationV2Enabled = (userProfile: CentralUserProfile) => {
  return pipeFlagFiltersForDeploy([
    {
      enabled: isForceEnabled('dbaas.cluster_creation_v2.forced_on-off'),
      endIf: (enabled) => isBoolean(enabled)
    },
    {
      enabled: isMailEnabled({
        envVar: process.env.REACT_APP_CLUSTER_CREATION_V2_EMAIL_WHITELIST,
        targetVal: userProfile.email
      })
    }
  ])
}

export const isClusterModifyingV2Enabled = (userProfile: CentralUserProfile) => {
  return pipeFlagFiltersForDeploy([
    {
      enabled: isMailEnabled({
        envVar: process.env.REACT_APP_CLUSTER_MODIFYING_V2_EMAIL_WHITELIST,
        targetVal: userProfile.email
      })
    }
  ])
}

export const isServerlessBackupRestoreEnabled = (userProfile: CentralUserProfile) => {
  return pipeFlagFiltersForDeploy([
    {
      enabled: isMailEnabled({
        envVar: process.env.REACT_APP_SERVERLESS_BR_EMAIL_WHITELIST,
        targetVal: userProfile.email
      })
    }
  ])
}

export const isGithubIntegrationEnabled = (userProfile: CentralUserProfile) => {
  return pipeFlagFilters([
    {
      enabled: isMailEnabled({
        envVar: process.env.REACT_APP_GITHUB_INTEGRATION,
        targetVal: userProfile.email
      })
    }
  ])
}

export const isDarkModeEanbled = (userProfile: CentralUserProfile) => {
  return pipeFlagFilters([
    {
      enabled: isMailEnabled({
        envVar: process.env.REACT_APP_DARK_MODE_WHITELIST,
        targetVal: userProfile.email
      })
    }
  ])
}

export const isChatBotEnabled = (userProfile: CentralUserProfile) => {
  return pipeFlagFilters([
    {
      enabled: isMailEnabled({
        envVar: process.env.REACT_APP_CHAT_BOT_EMAIL_WHITELIST,
        targetVal: userProfile.email
      })
    }
  ])
}

export const isDataserviceUsageEnabled = (userProfile: CentralUserProfile) => {
  return pipeFlagFilters([
    {
      enabled: isMailEnabled({
        envVar: process.env.REACT_APP_DATASERVICE_USAGE_WHITELIST,
        targetVal: userProfile.email
      })
    }
  ])
}

export const isTikvCacheHitMetricEnabled = (tenantID: string) => {
  return pipeFlagFilters([
    {
      enabled: isListIncluded({
        envVar: process.env.REACT_APP_TIKV_CACHE_HIT_METRIC_ALLOW_TENANTS,
        targetVal: tenantID
      })
    }
  ])
}

export const isPDMetricsEnabled = (tenantID: string) => {
  return isListIncluded({
    envVar: process.env.REACT_APP_PD_METRICS_ALLOW_TENANTS,
    targetVal: tenantID
  })
}

export const isDurationLimitTimePickerEnabled = (tenantID: string) => {
  return pipeFlagFilters([
    {
      enabled: isListIncluded({
        envVar: process.env.REACT_APP_DURATION_LIMIT_TIME_PICKER_ALLOW_TENANTS,
        targetVal: tenantID
      })
    }
  ])
}

export const isServerlessEventsEnabled = (userProfile: CentralUserProfile) => {
  return pipeFlagFilters([
    {
      enabled: isMailEnabled({
        envVar: process.env.REACT_APP_SERVERLESS_EVENTS_EMAIL_WHITELIST,
        targetVal: userProfile.email
      })
    }
  ])
}

export const isChat2QueryV2Enabled = (userProfile: CentralUserProfile) => {
  return isMailEnabled({
    envVar: process.env.REACT_APP_CHAT2QUERY_V2_WHITELIST,
    targetVal: userProfile.email
  })
}

export const isCostExplorerEnabled = (userProfile: CentralUserProfile) => {
  return isMailEnabled({
    envVar: process.env.REACT_APP_COST_EXPLORER_WHITELIST,
    targetVal: userProfile.email
  })
}

export const isO11yTestHostEnabled = (tenantID: string, projectID: string) => {
  return isListIncluded({
    envVar: process.env.REACT_APP_O11Y_TEST_HOST_ALLOW_TENANTS_PROJECTS,
    targetVal: `${tenantID}_${projectID}`
  })
}

export const isNotificationEnabled = (userProfile: CentralUserProfile) => {
  return isMailEnabled({
    envVar: process.env.REACT_APP_NOTIFICATION_WHITELIST,
    targetVal: userProfile.email
  })
}
