import type { CloudVendor } from 'dbaas/stores/localStorage'

export function isAWS(provider: string) {
  return provider === 'aws_account_provider'
}

export function isGCP(provider: string) {
  return provider === 'gcp_account_provider'
}

export function isMSP(provider: string) {
  return provider === 'msp_account_provider'
}

export function getThirdPartyVendor(provider: string): CloudVendor {
  return isMSP(provider) ? 'msp' : isAWS(provider) ? 'aws' : 'gcp'
}

export function isFromMarketplace(query: URLSearchParams) {
  const accountProvider = query.get('third_party_account_provider') || ''
  return (isAWS(accountProvider) || isGCP(accountProvider)) && query.has('third_party_account_token')
}

export function getThirdPartyParams(params: URLSearchParams) {
  const accountProvider = params.get('third_party_account_provider') || ''
  const accountToken = params.get('third_party_account_token') || ''
  const freeTrial = params.get('third_party_free_trial') || ''
  return {
    accountProvider,
    accountToken,
    freeTrial: freeTrial === 'true' ? true : false,
    thirdPartyParams: `third_party_account_provider=${encodeURIComponent(
      accountProvider
    )}&third_party_account_token=${encodeURIComponent(accountToken)}&third_party_free_trial=${encodeURIComponent(
      freeTrial
    )}`,
    fromMarketplace: isFromMarketplace(params),
    isMSP: isMSP(accountProvider),
    msp: params.get('msp') || ''
  }
}

export function useThirdParty(query?: URLSearchParams) {
  const params = query ? query : new URLSearchParams(window.location.search)
  const {
    accountProvider,
    accountToken,
    thirdPartyParams: marketplaceParams,
    freeTrial,
    ...rest
  } = getThirdPartyParams(params)
  return {
    provider: getThirdPartyVendor(accountProvider || ''),
    freeTrial,
    accountProvider,
    accountToken,
    marketplaceParams,
    ...rest
  }
}
