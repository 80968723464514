export type CloudVendor = 'aws' | 'gcp' | 'msp'

export enum CHART_TIMEZONE {
  LOCAL = 'LOCAL',
  CUSTOMER = 'CUSTOMER'
}

const getCloud = () => {
  const cloud = localStorage.getItem('dbaasCloud')
  // compatible with exists error status ,like 'undefined'
  if (!cloud || cloud === 'undefined' || cloud === 'null') {
    return 'aws'
  }
  return cloud as CloudVendor
}

const setCloud = (cloud: CloudVendor) => {
  localStorage.setItem('dbaasCloud', cloud)
}

const getLastAccessProject = () => {
  const project = localStorage.getItem('dbaasLastAccessProject')
  // compatible with exists error status ,like 'undefined'
  if (!project || project === 'undefined' || project === 'null') {
    return ''
  }
  return project
}

const setLastAccessProject = (project: string) => {
  localStorage.setItem('dbaasLastAccessProject', project)
}

const LAST_CACHED_ORG_INFO_KEY = 'dbaas.last_cached_org_info'

export const getLastCachedOrg = () => {
  const userEmail = localStorage.getItem('sign_in_email')
  const cachedValStr =
    sessionStorage.getItem(LAST_CACHED_ORG_INFO_KEY) || localStorage.getItem(LAST_CACHED_ORG_INFO_KEY)

  if (!cachedValStr) {
    return {
      isCached: false,
      isMatched: false,
      userEmail: '',
      orgId: ''
    }
  }

  let cachedInfo = {
    userEmail: '',
    orgId: ''
  }

  try {
    cachedInfo = JSON.parse(cachedValStr)
  } catch {}

  return {
    isCached: true,
    isMatched: !!cachedInfo.userEmail && cachedInfo.userEmail === userEmail,
    userEmail: cachedInfo.userEmail || '',
    orgId: cachedInfo.orgId || ''
  }
}

export const setLastCachedOrg = (orgId: string, useSession = false) => {
  const userEmail = localStorage.getItem('sign_in_email')

  if (useSession) {
    return sessionStorage.setItem(
      LAST_CACHED_ORG_INFO_KEY,
      JSON.stringify({
        userEmail,
        orgId
      })
    )
  }

  return localStorage.setItem(
    LAST_CACHED_ORG_INFO_KEY,
    JSON.stringify({
      userEmail,
      orgId
    })
  )
}

export const clearLastCachedOrg = () => {
  sessionStorage.removeItem(LAST_CACHED_ORG_INFO_KEY)
  return localStorage.removeItem(LAST_CACHED_ORG_INFO_KEY)
}

const setChartTimezone = (s: CHART_TIMEZONE) => {
  localStorage.setItem('chart-timezone', s)
}

const getChartTimezone = () => {
  const t = localStorage.getItem('chart-timezone')
  if (!t) {
    return CHART_TIMEZONE.CUSTOMER
  }

  return t as CHART_TIMEZONE
}

export const setSignupPlan = (plan: string) => {
  return localStorage.setItem('signup-plan', plan)
}

export const getSignupPlan = () => {
  return localStorage.getItem('signup-plan')
}

export const removeSignupPlan = () => {
  localStorage.removeItem('signup-plan')
}

export const setShowPoC = () => {
  sessionStorage.setItem('show-poc', JSON.stringify(true))
}

export const getShowPoC = () => {
  return !!sessionStorage.getItem('show-poc')
}

export const removeShowPoC = () => {
  return sessionStorage.removeItem('show-poc')
}

const ssoSourceKey = 'signup-sso-source'

export const setSSOSource = (source: string) => {
  localStorage.setItem(ssoSourceKey, source)
}

export const getSSOSource = () => {
  return localStorage.getItem(ssoSourceKey)
}

const productTypeKey = 'signup-product-type'

export const setSignupProductType = (type: string) => {
  localStorage.setItem(productTypeKey, type)
}

export const getSignupProductType = () => {
  return localStorage.getItem(productTypeKey)
}

export const removeSSOItem = () => {
  localStorage.removeItem(ssoSourceKey)
}

export const isEnableNewClusterList = () => {
  const value = localStorage.getItem('enable-new-cluster-list')
  if (value === 'on') {
    return true
  }
  if (value === 'off') {
    return false
  }
  return null
}

const channelKey = 'dbaas-channel-landing:'
export const setChannelLanding = (email: string) => {
  localStorage.setItem(`${channelKey}${email}`, JSON.stringify(true))
}

export const getChannelLanding = (email: string) => {
  return !!localStorage.getItem(`${channelKey}${email}`)
}

export const lastPageKey = 'dbaas.latest-visited-path'

interface LastPage {
  pathname: string
  search?: string
}

export const setLastPage = (userId: string, page: LastPage) => {
  localStorage.setItem(`${lastPageKey}:${userId}`, JSON.stringify(page))
}

export const getLastPage = (userId: string) => {
  try {
    return JSON.parse(localStorage.getItem(`${lastPageKey}:${userId}`) || '') as { pathname: string; search?: string }
  } catch (e) {
    return null
  }
}

export const clearLastPage = (userId: string) => {
  localStorage.removeItem(`${lastPageKey}:${userId}`)
}

export const getSpendLimitQuotaAlert = (clusterId: string) => {
  return localStorage.getItem(`dbaas-spend-limit-quota-alert:${clusterId}`) as SPEND_LIMIT_ALERT_TYPE
}

export enum SPEND_LIMIT_ALERT_TYPE {
  PER_75 = 'PER_75',
  PER_75_C = 'PER_75_C', // closed
  PER_90 = 'PER_90',
  PER_90_C = 'PER_90_C',
  PER_100 = 'PER_100',
  PER_100_C = 'PER_100_C'
}

export const setSpendLimitQuotaAlert = (clusterId: string, alertType: SPEND_LIMIT_ALERT_TYPE | null) => {
  const key = `dbaas-spend-limit-quota-alert:${clusterId}`
  if (alertType) {
    localStorage.setItem(key, alertType)
  } else {
    localStorage.removeItem(key)
  }
}

const SIGN_IN_TYPE_KEY_PREFIX = 'dbaas-sigin-type:'

interface SignInTypeData {
  type?: string
  companyName?: string
}

export const setSignInType = (email: string, value: SignInTypeData) => {
  const key = `${SIGN_IN_TYPE_KEY_PREFIX}${email}`
  return localStorage.setItem(key, JSON.stringify(value))
}
export const getSignInType = (email: string) => {
  const key = `${SIGN_IN_TYPE_KEY_PREFIX}${email}`
  const value = localStorage.getItem(key) || '{}'
  try {
    return JSON.parse(value) as SignInTypeData
  } catch (e) {
    return {}
  }
}

export const getAuthInfo = () => {
  const email = localStorage.getItem('sign_in_email')
  const signInType = getSignInType(email || '')
  return { email, ...signInType }
}

interface UserProfile {
  userId: string
}

export const getSignInUserProfile = (): UserProfile => {
  try {
    return JSON.parse(localStorage.getItem('dbaas-signin-user') || '{}')
  } catch (e) {
    return {
      userId: ''
    }
  }
}

export const setSignInUserProfile = (user: UserProfile) => {
  localStorage.setItem('dbaas-signin-user', JSON.stringify(user))
}

export const clearSignInUserProfile = () => {
  localStorage.removeItem('dbaas-signin-user')
}

export const ExpFeatHashmap = {
  DataService: 'dataservice_enable_dedicated',
  Chat2Query: 'chat2query_enable_dedicated',
  CloudOrgSSO: 'account_enable_enterprise_org',
  SQLUsers: 'account_enable_sqluser_on_console',
  ClusterAuditLog: 'account_enable_audit_log_org',
  OpenAPIKeys: 'account_enable_openapi_org',
  Replication: 'account_enable_replication_org',
  CrossAccount: 'account_enable_xAccount_org',
  DataserviceSystemEndpoint: 'dataservice_enable_system_endpoint',
  ReginalCIDR: 'account_enable_custom_cidr',
  ServerlessVector: 'serverless_enable_vector_type'
} as const

export type ExpFeatFlags = Partial<Record<typeof ExpFeatHashmap[keyof typeof ExpFeatHashmap], boolean>>

export default {
  getCloud,
  setCloud,
  getLastAccessProject,
  setLastAccessProject,
  getLastCachedOrg,
  setLastCachedOrg,
  clearLastCachedOrg,
  setChartTimezone,
  getChartTimezone,
  getSignupPlan,
  setSignupPlan,
  removeSignupPlan,
  isEnableNewClusterList,
  setLastPage,
  getLastPage,
  clearLastPage
}
