import auth0 from 'auth0-js'
import { sha256 } from 'js-sha256'
import { capitalize } from 'lodash-es'

import { ORG_TYPE } from 'dbaas/features/Organization'
import { RegistrationReqConnectionType } from 'dbaas/services'
import {
  clearSignInUserProfile,
  getAuthInfo,
  removeShowPoC,
  setLastCachedOrg,
  setSignInType
} from 'dbaas/stores/localStorage'
import { ram } from 'dbaas/stores/ram'
import { loginTracing } from 'dbaas/utils/tracing'

import config from '../../config'
import { eventTracking, reportGA } from '../utils/tracking'

export const AUTH_CALLBACK_URI: string = `${window.location.origin}/auth_redirect`

const auth0Domain = config.AUTH0_DOMAIN || 'tidb.auth0.com'
const clientID = config.AUTH0_CLIENTID || 'fqEopwwRMe0G6jgHnt0YJk9r0vuQ5Qmp'
export const databaseConnection = 'custom-email'

export const ConnectionType = {
  GOOGLE: 'google-oauth2',
  GITHUB: 'github',
  EMAIL: 'auth0',
  MICROSOFT: 'windowslive',
  OIDC: 'OIDC',
  SAML: 'SAML',

  // ts doesn't support reverse mapping for string enum :(
  get github() {
    return 'GITHUB' as const
  },
  get 'google-oauth2'() {
    return 'GOOGLE' as const
  },
  get auth0() {
    return 'EMAIL' as const
  },
  get 'custom-email'() {
    return 'EMAIL' as const
  },
  get windowslive() {
    return 'MICROSOFT' as const
  },
  get oidc() {
    return 'OIDC' as const
  },
  get saml() {
    return 'SAML' as const
  }
}

export type SSOTypes = 'GOOGLE' | 'GITHUB' | 'MICROSOFT' | 'OIDC' | 'SAML'

// password reset time is a custom claim
// it must use a namespaced format and use url is encouraged
// see https://auth0.com/docs/secure/tokens/json-web-tokens/create-namespaced-custom-claims
const lastPasswordResetField = `${config.AUTH0_NS}/last_password_reset`

export class Auth {
  auth0 = new auth0.WebAuth({
    domain: auth0Domain,
    clientID: clientID,
    redirectUri: AUTH_CALLBACK_URI,
    responseType: 'token id_token',
    scope: 'openid email'
  })

  login(search?: string) {
    window && this.authorizeWithPrev(search)
  }

  handleAuthentication = (handleSuccess: () => void, handleError: (err: auth0.Auth0ParseHashError | null) => void) => {
    this.auth0.parseHash({ hash: window.location.hash }, (err, authResult) => {
      // console.log('Auth Info ', { err, authResult })
      if (authResult && authResult.accessToken && authResult.idToken) {
        // save auth0 tokens
        this.setSession(authResult)
        // for mixpanel login event
        ram.set('reportLogin', true)
        loginTracing({ status: 'ok', result: authResult })
        // process callback
        reportGA({ event: 'signin_complete', user_id: sha256(authResult.idTokenPayload?.email || '') })
        handleSuccess()
      } else {
        loginTracing({ status: 'error', result: err })
        // process error
        handleError(err)
      }
    })
  }

  checkSession = () => {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, function (err, authResult) {
        if (err) {
          reject(err)
        } else {
          resolve(authResult)
        }
      })
    })
  }

  getLastPasswordResetTime = (): Promise<string | null> => {
    if (this.getConnectionType() !== ConnectionType.auth0) {
      return Promise.resolve(null)
    }
    const accessToken = this.getAccessToken()
    if (accessToken) {
      return new Promise((resolve) => {
        this.auth0.client.userInfo(accessToken, (err, user) => {
          if (err) {
            resolve(null)
          } else {
            resolve(user[lastPasswordResetField] as string)
          }
        })
      })
    }
    return Promise.resolve(null)
  }

  setSession = (authResult: auth0.Auth0DecodedHash) => {
    // Set the time that the Access Token will expire at
    let expiresAt = JSON.stringify(
      !!authResult.idTokenPayload?.exp
        ? authResult.idTokenPayload.exp * 1000
        : (authResult.expiresIn || 0) * 1000 + new Date().getTime()
    )
    // console.log(authResult)
    localStorage.setItem('access_token', authResult.accessToken || '')
    localStorage.setItem('id_token', authResult.idToken || '')
    localStorage.setItem('expires_at', expiresAt)
    const email = authResult.idTokenPayload?.email
    localStorage.setItem('sign_in_email', email)
    // 'google-oauth2' | 'github' | 'auth0' | 'okta'
    localStorage.setItem('connection_type', authResult.idTokenPayload?.sub.split('|')[0])
    const orgType = authResult.idTokenPayload?.login_org_type
    const orgId = authResult.idTokenPayload?.login_org_id
    const companyName = authResult.idTokenPayload?.login_company_name
    // after enterprise sso we using the org id from token
    if (orgType === ORG_TYPE.ENTERPRISE && orgId) {
      setLastCachedOrg(orgId)
    }
    if (orgType) {
      setSignInType(email, { type: orgType, companyName })
    }
  }

  clearSession = () => {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    localStorage.removeItem('sign_in_email')
    localStorage.removeItem('connection_type')
  }

  /**
   * **BE CAUTIOUS!**
   * MAKE SURE the redirect url were added in Auth0 - Applcations - TIDB Cloud - Application URIs - Allowed Logout URLs.
   * Otherwise the logout request will fail and returns error page
   */
  logout = (redirect?: '/signup' | '/' | string, withSearch = true) => {
    this.clearSession()
    clearSignInUserProfile()
    removeShowPoC()
    // logout and navigate to the home route
    this.auth0.logout({
      returnTo: `${window.location.origin}${redirect || '/'}${withSearch ? window.location.search : ''}`
    })

    eventTracking('Logout')
  }

  isAuthenticated = () => {
    // Check whether the current time is past the
    // Access Token's expiry time
    try {
      let expiresAt = JSON.parse(localStorage.getItem('expires_at') || '0')
      return new Date().getTime() < expiresAt
    } catch (e) {
      return false
    }
  }

  getAccessToken = () => {
    return localStorage.getItem('access_token')
  }

  getToken = () => {
    return localStorage.getItem('id_token')
  }

  getAuthInfo = () => {
    return getAuthInfo()
  }

  // GITHUB | EMAIL | GOOGLE
  getConnectionType() {
    const type = localStorage.getItem('connection_type')
    if (type) {
      return (ConnectionType[type] as RegistrationReqConnectionType) || capitalize(type)
    }
  }

  authorizeWithPrev(search?: string) {
    let uri = window.location.href.replace(window.location.origin, '')
    if (uri.includes('third_party_account_provider')) {
      const pathname = window.location.pathname
      uri = uri.replace(`${pathname}?`, `${pathname}&`)
    }
    this.auth0.authorize({
      redirectUri: search ? `${AUTH_CALLBACK_URI}${search}` : `${AUTH_CALLBACK_URI}?prev=${uri}`
    })
  }

  authorizeWithVendor(vendor: 'GITHUB' | 'GOOGLE' | 'MICROSOFT' | string, params = '') {
    this.auth0.authorize({
      redirectUri: `${AUTH_CALLBACK_URI}${params}`,
      connection: ConnectionType[vendor] || vendor
    })
  }
}

const auth = new Auth()

export default auth
