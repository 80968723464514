/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * gRPC for our DBaaS central service
 * notably uses gRPC-Gateway with OpenAPI
 * OpenAPI spec version: 0.1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  CentralOrganizationList,
  RuntimeError,
  CentralDefaultResp,
  CentralRegistrationReq,
  CentralSendVerificationEmailReq,
  CentralGetUserOrgsProjectsResp,
  RpcStatus,
  CentralCreateApiKeyForMspResp,
  CreateApiKeyForMspParams,
  CentralGetMspConfigResp,
  GetMspConfigParams,
  CentralGetMspCustomerResp,
  CentralQueryUserServicesAgreementResp,
  GetUserServicesAgreementParams,
  SaveUserServicesAgreement200,
  CentralUserServicesAgreement,
  CentralCheckAuthMethodRsp,
  CheckAuthMethodParams,
  CreateSqlUser200,
  CreateSqlUserBody,
  DeleteSqlUser200,
  DeleteSqlUserBody,
  CentralListSqlUsersResp,
  ListSqlUsersParams,
  CentralQuerySqlUserResp,
  QuerySqlUserParams,
  UpdateSqlUser200,
  UpdateSqlUserBody,
  CentralGetUserLastVisitHistoryResp,
  CreateUserVisitHistory200,
  CentralCreateUserVisitHistoryReq,
  CreatePlgOnBoardingSurvey200,
  CentralCreatePlgOnBoardingSurveyReq,
  CentralGetUserProfileResp,
  CentralGetUserLoginExtInfoResp,
  OpenOrgSso200,
  OpenOrgSsoBody,
  CentralListOrgActiveAuthMethodsRsp,
  ListOrgActiveAuthMethodsParams,
  CentralListAuthMethodsRsp,
  ListAuthMethodsParams,
  SetAuthMethodStatus200,
  SetAuthMethodStatusBody,
  UpdateAuthMethod200,
  UpdateAuthMethodBody,
  CentralListScimGroupsRsp,
  ListScimGroupsParams,
  UpdateScimGroupRole200,
  UpdateScimGroupRoleBody,
  CentralCreateJsmTicketResp,
  CentralCreateJsmTicketReq,
  CentralGetJsmTicketParamInfoResp,
  GetJsmTicketParamInfoParams,
  CreateSupportAttach200,
  CreateSupportAttachBody
} from './interface'
import { customInstance } from '../openapi';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const listOrganizations = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralOrganizationList>(
      {url: `/api/v1/orgs`, method: 'get', signal
    },
      { _metadata: { operationName: 'listOrganizations', pathRoute: '/api/v1/orgs', },  ...options});
    }
  

export const getListOrganizationsQueryKey = () => [`/api/v1/orgs`];

    
export type ListOrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof listOrganizations>>>
export type ListOrganizationsQueryError = RuntimeError

export const useListOrganizations = <TData = Awaited<ReturnType<typeof listOrganizations>>, TError = RuntimeError>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listOrganizations>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListOrganizationsQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listOrganizations>>> = ({ signal }) => listOrganizations(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listOrganizations>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const registration = (
    centralRegistrationReq: CentralRegistrationReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralDefaultResp>(
      {url: `/api/v1/orgs`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralRegistrationReq
    },
      { _metadata: { operationName: 'registration', pathRoute: '/api/v1/orgs', },  ...options});
    }
  


    export type RegistrationMutationResult = NonNullable<Awaited<ReturnType<typeof registration>>>
    export type RegistrationMutationBody = CentralRegistrationReq
    export type RegistrationMutationError = unknown

    export const useRegistration = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registration>>, TError,{data: CentralRegistrationReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof registration>>, {data: CentralRegistrationReq}> = (props) => {
          const {data} = props ?? {};

          return  registration(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof registration>>, TError, {data: CentralRegistrationReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const sendVerificationEmail = (
    centralSendVerificationEmailReq: CentralSendVerificationEmailReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<unknown>(
      {url: `/api/v1/orgs/verification-email`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralSendVerificationEmailReq
    },
      { _metadata: { operationName: 'sendVerificationEmail', pathRoute: '/api/v1/orgs/verification-email', },  ...options});
    }
  


    export type SendVerificationEmailMutationResult = NonNullable<Awaited<ReturnType<typeof sendVerificationEmail>>>
    export type SendVerificationEmailMutationBody = CentralSendVerificationEmailReq
    export type SendVerificationEmailMutationError = unknown

    export const useSendVerificationEmail = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendVerificationEmail>>, TError,{data: CentralSendVerificationEmailReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendVerificationEmail>>, {data: CentralSendVerificationEmailReq}> = (props) => {
          const {data} = props ?? {};

          return  sendVerificationEmail(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof sendVerificationEmail>>, TError, {data: CentralSendVerificationEmailReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getUserOrgsProjects = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetUserOrgsProjectsResp>(
      {url: `/api/v1/user/scope`, method: 'get', signal
    },
      { _metadata: { operationName: 'getUserOrgsProjects', pathRoute: '/api/v1/user/scope', },  ...options});
    }
  

export const getGetUserOrgsProjectsQueryKey = () => [`/api/v1/user/scope`];

    
export type GetUserOrgsProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserOrgsProjects>>>
export type GetUserOrgsProjectsQueryError = RpcStatus

export const useGetUserOrgsProjects = <TData = Awaited<ReturnType<typeof getUserOrgsProjects>>, TError = RpcStatus>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserOrgsProjects>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserOrgsProjectsQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserOrgsProjects>>> = ({ signal }) => getUserOrgsProjects(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserOrgsProjects>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createApiKeyForMsp = (
    params?: CreateApiKeyForMspParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralCreateApiKeyForMspResp>(
      {url: `/api/v1/api-keys/msp`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'createApiKeyForMsp', pathRoute: '/api/v1/api-keys/msp', },  ...options});
    }
  

export const getCreateApiKeyForMspQueryKey = (params?: CreateApiKeyForMspParams,) => [`/api/v1/api-keys/msp`, ...(params ? [params]: [])];

    
export type CreateApiKeyForMspQueryResult = NonNullable<Awaited<ReturnType<typeof createApiKeyForMsp>>>
export type CreateApiKeyForMspQueryError = RpcStatus

export const useCreateApiKeyForMsp = <TData = Awaited<ReturnType<typeof createApiKeyForMsp>>, TError = RpcStatus>(
 params?: CreateApiKeyForMspParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof createApiKeyForMsp>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCreateApiKeyForMspQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof createApiKeyForMsp>>> = ({ signal }) => createApiKeyForMsp(params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof createApiKeyForMsp>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getMspConfig = (
    params?: GetMspConfigParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetMspConfigResp>(
      {url: `/api/v1/msp/msp-config`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getMspConfig', pathRoute: '/api/v1/msp/msp-config', },  ...options});
    }
  

export const getGetMspConfigQueryKey = (params?: GetMspConfigParams,) => [`/api/v1/msp/msp-config`, ...(params ? [params]: [])];

    
export type GetMspConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getMspConfig>>>
export type GetMspConfigQueryError = RpcStatus

export const useGetMspConfig = <TData = Awaited<ReturnType<typeof getMspConfig>>, TError = RpcStatus>(
 params?: GetMspConfigParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspConfig>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMspConfigQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspConfig>>> = ({ signal }) => getMspConfig(params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getMspConfig>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getMspCustomer = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetMspCustomerResp>(
      {url: `/api/v1/orgs/${orgId}/msp/msp-customer`, method: 'get', signal
    },
      { _metadata: { operationName: 'getMspCustomer', pathRoute: '/api/v1/orgs/{org_id}/msp/msp-customer', },  ...options});
    }
  

export const getGetMspCustomerQueryKey = (orgId: string,) => [`/api/v1/orgs/${orgId}/msp/msp-customer`];

    
export type GetMspCustomerQueryResult = NonNullable<Awaited<ReturnType<typeof getMspCustomer>>>
export type GetMspCustomerQueryError = RpcStatus

export const useGetMspCustomer = <TData = Awaited<ReturnType<typeof getMspCustomer>>, TError = RpcStatus>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMspCustomer>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMspCustomerQueryKey(orgId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMspCustomer>>> = ({ signal }) => getMspCustomer(orgId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getMspCustomer>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getUserServicesAgreement = (
    params?: GetUserServicesAgreementParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralQueryUserServicesAgreementResp>(
      {url: `/api/v1/msp/services-agreement`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getUserServicesAgreement', pathRoute: '/api/v1/msp/services-agreement', },  ...options});
    }
  

export const getGetUserServicesAgreementQueryKey = (params?: GetUserServicesAgreementParams,) => [`/api/v1/msp/services-agreement`, ...(params ? [params]: [])];

    
export type GetUserServicesAgreementQueryResult = NonNullable<Awaited<ReturnType<typeof getUserServicesAgreement>>>
export type GetUserServicesAgreementQueryError = RpcStatus

export const useGetUserServicesAgreement = <TData = Awaited<ReturnType<typeof getUserServicesAgreement>>, TError = RpcStatus>(
 params?: GetUserServicesAgreementParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserServicesAgreement>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserServicesAgreementQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserServicesAgreement>>> = ({ signal }) => getUserServicesAgreement(params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserServicesAgreement>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const saveUserServicesAgreement = (
    centralUserServicesAgreement: CentralUserServicesAgreement,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<SaveUserServicesAgreement200>(
      {url: `/api/v1/msp/services-agreement`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralUserServicesAgreement
    },
      { _metadata: { operationName: 'saveUserServicesAgreement', pathRoute: '/api/v1/msp/services-agreement', },  ...options});
    }
  


    export type SaveUserServicesAgreementMutationResult = NonNullable<Awaited<ReturnType<typeof saveUserServicesAgreement>>>
    export type SaveUserServicesAgreementMutationBody = CentralUserServicesAgreement
    export type SaveUserServicesAgreementMutationError = RpcStatus

    export const useSaveUserServicesAgreement = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveUserServicesAgreement>>, TError,{data: CentralUserServicesAgreement}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveUserServicesAgreement>>, {data: CentralUserServicesAgreement}> = (props) => {
          const {data} = props ?? {};

          return  saveUserServicesAgreement(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof saveUserServicesAgreement>>, TError, {data: CentralUserServicesAgreement}, TContext>(mutationFn, mutationOptions);
    }
    
export const checkAuthMethod = (
    params?: CheckAuthMethodParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralCheckAuthMethodRsp>(
      {url: `/api/v1/auth-method/valid-check`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'checkAuthMethod', pathRoute: '/api/v1/auth-method/valid-check', },  ...options});
    }
  

export const getCheckAuthMethodQueryKey = (params?: CheckAuthMethodParams,) => [`/api/v1/auth-method/valid-check`, ...(params ? [params]: [])];

    
export type CheckAuthMethodQueryResult = NonNullable<Awaited<ReturnType<typeof checkAuthMethod>>>
export type CheckAuthMethodQueryError = RpcStatus

export const useCheckAuthMethod = <TData = Awaited<ReturnType<typeof checkAuthMethod>>, TError = RpcStatus>(
 params?: CheckAuthMethodParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkAuthMethod>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCheckAuthMethodQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkAuthMethod>>> = ({ signal }) => checkAuthMethod(params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof checkAuthMethod>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createSqlUser = (
    orgId: string,
    projectId: string,
    clusterId: string,
    createSqlUserBody: CreateSqlUserBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CreateSqlUser200>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sql-user/create`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSqlUserBody
    },
      { _metadata: { operationName: 'createSqlUser', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/sql-user/create', },  ...options});
    }
  


    export type CreateSqlUserMutationResult = NonNullable<Awaited<ReturnType<typeof createSqlUser>>>
    export type CreateSqlUserMutationBody = CreateSqlUserBody
    export type CreateSqlUserMutationError = RpcStatus

    export const useCreateSqlUser = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSqlUser>>, TError,{orgId: string;projectId: string;clusterId: string;data: CreateSqlUserBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSqlUser>>, {orgId: string;projectId: string;clusterId: string;data: CreateSqlUserBody}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  createSqlUser(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createSqlUser>>, TError, {orgId: string;projectId: string;clusterId: string;data: CreateSqlUserBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const deleteSqlUser = (
    orgId: string,
    projectId: string,
    clusterId: string,
    deleteSqlUserBody: DeleteSqlUserBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<DeleteSqlUser200>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sql-user/delete`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: deleteSqlUserBody
    },
      { _metadata: { operationName: 'deleteSqlUser', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/sql-user/delete', },  ...options});
    }
  


    export type DeleteSqlUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSqlUser>>>
    export type DeleteSqlUserMutationBody = DeleteSqlUserBody
    export type DeleteSqlUserMutationError = RpcStatus

    export const useDeleteSqlUser = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSqlUser>>, TError,{orgId: string;projectId: string;clusterId: string;data: DeleteSqlUserBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSqlUser>>, {orgId: string;projectId: string;clusterId: string;data: DeleteSqlUserBody}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  deleteSqlUser(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof deleteSqlUser>>, TError, {orgId: string;projectId: string;clusterId: string;data: DeleteSqlUserBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const listSqlUsers = (
    orgId: string,
    projectId: string,
    clusterId: string,
    params?: ListSqlUsersParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralListSqlUsersResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sql-user/list`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listSqlUsers', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/sql-user/list', },  ...options});
    }
  

export const getListSqlUsersQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,
    params?: ListSqlUsersParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sql-user/list`, ...(params ? [params]: [])];

    
export type ListSqlUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listSqlUsers>>>
export type ListSqlUsersQueryError = RpcStatus

export const useListSqlUsers = <TData = Awaited<ReturnType<typeof listSqlUsers>>, TError = RpcStatus>(
 orgId: string,
    projectId: string,
    clusterId: string,
    params?: ListSqlUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listSqlUsers>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListSqlUsersQueryKey(orgId,projectId,clusterId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSqlUsers>>> = ({ signal }) => listSqlUsers(orgId,projectId,clusterId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listSqlUsers>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const querySqlUser = (
    orgId: string,
    projectId: string,
    clusterId: string,
    params?: QuerySqlUserParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralQuerySqlUserResp>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sql-user/query`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'querySqlUser', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/sql-user/query', },  ...options});
    }
  

export const getQuerySqlUserQueryKey = (orgId: string,
    projectId: string,
    clusterId: string,
    params?: QuerySqlUserParams,) => [`/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sql-user/query`, ...(params ? [params]: [])];

    
export type QuerySqlUserQueryResult = NonNullable<Awaited<ReturnType<typeof querySqlUser>>>
export type QuerySqlUserQueryError = RpcStatus

export const useQuerySqlUser = <TData = Awaited<ReturnType<typeof querySqlUser>>, TError = RpcStatus>(
 orgId: string,
    projectId: string,
    clusterId: string,
    params?: QuerySqlUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof querySqlUser>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getQuerySqlUserQueryKey(orgId,projectId,clusterId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof querySqlUser>>> = ({ signal }) => querySqlUser(orgId,projectId,clusterId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof querySqlUser>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId && projectId && clusterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const updateSqlUser = (
    orgId: string,
    projectId: string,
    clusterId: string,
    updateSqlUserBody: UpdateSqlUserBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateSqlUser200>(
      {url: `/api/v1/orgs/${orgId}/projects/${projectId}/clusters/${clusterId}/sql-user/update`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateSqlUserBody
    },
      { _metadata: { operationName: 'updateSqlUser', pathRoute: '/api/v1/orgs/{org_id}/projects/{project_id}/clusters/{cluster_id}/sql-user/update', },  ...options});
    }
  


    export type UpdateSqlUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateSqlUser>>>
    export type UpdateSqlUserMutationBody = UpdateSqlUserBody
    export type UpdateSqlUserMutationError = RpcStatus

    export const useUpdateSqlUser = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSqlUser>>, TError,{orgId: string;projectId: string;clusterId: string;data: UpdateSqlUserBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSqlUser>>, {orgId: string;projectId: string;clusterId: string;data: UpdateSqlUserBody}> = (props) => {
          const {orgId,projectId,clusterId,data} = props ?? {};

          return  updateSqlUser(orgId,projectId,clusterId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateSqlUser>>, TError, {orgId: string;projectId: string;clusterId: string;data: UpdateSqlUserBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const getUserLastVisitHistory = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetUserLastVisitHistoryResp>(
      {url: `/api/v1/user/history/user-visit`, method: 'get', signal
    },
      { _metadata: { operationName: 'getUserLastVisitHistory', pathRoute: '/api/v1/user/history/user-visit', },  ...options});
    }
  

export const getGetUserLastVisitHistoryQueryKey = () => [`/api/v1/user/history/user-visit`];

    
export type GetUserLastVisitHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getUserLastVisitHistory>>>
export type GetUserLastVisitHistoryQueryError = RpcStatus

export const useGetUserLastVisitHistory = <TData = Awaited<ReturnType<typeof getUserLastVisitHistory>>, TError = RpcStatus>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserLastVisitHistory>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserLastVisitHistoryQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserLastVisitHistory>>> = ({ signal }) => getUserLastVisitHistory(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserLastVisitHistory>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createUserVisitHistory = (
    centralCreateUserVisitHistoryReq: CentralCreateUserVisitHistoryReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CreateUserVisitHistory200>(
      {url: `/api/v1/user/history/user-visit`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateUserVisitHistoryReq
    },
      { _metadata: { operationName: 'createUserVisitHistory', pathRoute: '/api/v1/user/history/user-visit', },  ...options});
    }
  


    export type CreateUserVisitHistoryMutationResult = NonNullable<Awaited<ReturnType<typeof createUserVisitHistory>>>
    export type CreateUserVisitHistoryMutationBody = CentralCreateUserVisitHistoryReq
    export type CreateUserVisitHistoryMutationError = RpcStatus

    export const useCreateUserVisitHistory = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUserVisitHistory>>, TError,{data: CentralCreateUserVisitHistoryReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUserVisitHistory>>, {data: CentralCreateUserVisitHistoryReq}> = (props) => {
          const {data} = props ?? {};

          return  createUserVisitHistory(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createUserVisitHistory>>, TError, {data: CentralCreateUserVisitHistoryReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const createPlgOnBoardingSurvey = (
    centralCreatePlgOnBoardingSurveyReq: CentralCreatePlgOnBoardingSurveyReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CreatePlgOnBoardingSurvey200>(
      {url: `/api/v1/user/plg-on-boarding-survey/create`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreatePlgOnBoardingSurveyReq
    },
      { _metadata: { operationName: 'createPlgOnBoardingSurvey', pathRoute: '/api/v1/user/plg-on-boarding-survey/create', },  ...options});
    }
  


    export type CreatePlgOnBoardingSurveyMutationResult = NonNullable<Awaited<ReturnType<typeof createPlgOnBoardingSurvey>>>
    export type CreatePlgOnBoardingSurveyMutationBody = CentralCreatePlgOnBoardingSurveyReq
    export type CreatePlgOnBoardingSurveyMutationError = RpcStatus

    export const useCreatePlgOnBoardingSurvey = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPlgOnBoardingSurvey>>, TError,{data: CentralCreatePlgOnBoardingSurveyReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPlgOnBoardingSurvey>>, {data: CentralCreatePlgOnBoardingSurveyReq}> = (props) => {
          const {data} = props ?? {};

          return  createPlgOnBoardingSurvey(data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createPlgOnBoardingSurvey>>, TError, {data: CentralCreatePlgOnBoardingSurveyReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getUserProfileV2 = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetUserProfileResp>(
      {url: `/api/v1/user/me`, method: 'get', signal
    },
      { _metadata: { operationName: 'getUserProfileV2', pathRoute: '/api/v1/user/me', },  ...options});
    }
  

export const getGetUserProfileV2QueryKey = () => [`/api/v1/user/me`];

    
export type GetUserProfileV2QueryResult = NonNullable<Awaited<ReturnType<typeof getUserProfileV2>>>
export type GetUserProfileV2QueryError = RpcStatus

export const useGetUserProfileV2 = <TData = Awaited<ReturnType<typeof getUserProfileV2>>, TError = RpcStatus>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserProfileV2>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserProfileV2QueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserProfileV2>>> = ({ signal }) => getUserProfileV2(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserProfileV2>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const getUserLoginExtInfo = (
    
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetUserLoginExtInfoResp>(
      {url: `/api/v1/user/user-ext`, method: 'get', signal
    },
      { _metadata: { operationName: 'getUserLoginExtInfo', pathRoute: '/api/v1/user/user-ext', },  ...options});
    }
  

export const getGetUserLoginExtInfoQueryKey = () => [`/api/v1/user/user-ext`];

    
export type GetUserLoginExtInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getUserLoginExtInfo>>>
export type GetUserLoginExtInfoQueryError = RpcStatus

export const useGetUserLoginExtInfo = <TData = Awaited<ReturnType<typeof getUserLoginExtInfo>>, TError = RpcStatus>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserLoginExtInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetUserLoginExtInfoQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserLoginExtInfo>>> = ({ signal }) => getUserLoginExtInfo(requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getUserLoginExtInfo>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const openOrgSso = (
    orgId: string,
    openOrgSsoBody: OpenOrgSsoBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<OpenOrgSso200>(
      {url: `/api/v1/orgs/${orgId}/opensso`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: openOrgSsoBody
    },
      { _metadata: { operationName: 'openOrgSso', pathRoute: '/api/v1/orgs/{org_id}/opensso', },  ...options});
    }
  


    export type OpenOrgSsoMutationResult = NonNullable<Awaited<ReturnType<typeof openOrgSso>>>
    export type OpenOrgSsoMutationBody = OpenOrgSsoBody
    export type OpenOrgSsoMutationError = RpcStatus

    export const useOpenOrgSso = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof openOrgSso>>, TError,{orgId: string;data: OpenOrgSsoBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof openOrgSso>>, {orgId: string;data: OpenOrgSsoBody}> = (props) => {
          const {orgId,data} = props ?? {};

          return  openOrgSso(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof openOrgSso>>, TError, {orgId: string;data: OpenOrgSsoBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const listOrgActiveAuthMethods = (
    params?: ListOrgActiveAuthMethodsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralListOrgActiveAuthMethodsRsp>(
      {url: `/api/v1/auth-method`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listOrgActiveAuthMethods', pathRoute: '/api/v1/auth-method', },  ...options});
    }
  

export const getListOrgActiveAuthMethodsQueryKey = (params?: ListOrgActiveAuthMethodsParams,) => [`/api/v1/auth-method`, ...(params ? [params]: [])];

    
export type ListOrgActiveAuthMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof listOrgActiveAuthMethods>>>
export type ListOrgActiveAuthMethodsQueryError = RpcStatus

export const useListOrgActiveAuthMethods = <TData = Awaited<ReturnType<typeof listOrgActiveAuthMethods>>, TError = RpcStatus>(
 params?: ListOrgActiveAuthMethodsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listOrgActiveAuthMethods>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListOrgActiveAuthMethodsQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listOrgActiveAuthMethods>>> = ({ signal }) => listOrgActiveAuthMethods(params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listOrgActiveAuthMethods>>, TError, TData>({ queryKey, queryFn, ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const showAuthMethods = (
    orgId: string,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralListAuthMethodsRsp>(
      {url: `/api/v1/orgs/${orgId}/auth-method`, method: 'get', signal
    },
      { _metadata: { operationName: 'showAuthMethods', pathRoute: '/api/v1/orgs/{org_id}/auth-method', },  ...options});
    }
  

export const getShowAuthMethodsQueryKey = (orgId: string,) => [`/api/v1/orgs/${orgId}/auth-method`];

    
export type ShowAuthMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof showAuthMethods>>>
export type ShowAuthMethodsQueryError = RpcStatus

export const useShowAuthMethods = <TData = Awaited<ReturnType<typeof showAuthMethods>>, TError = RpcStatus>(
 orgId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof showAuthMethods>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getShowAuthMethodsQueryKey(orgId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof showAuthMethods>>> = ({ signal }) => showAuthMethods(orgId, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof showAuthMethods>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const listAuthMethods = (
    orgId: string,
    params?: ListAuthMethodsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralListOrgActiveAuthMethodsRsp>(
      {url: `/api/v1/orgs/${orgId}/auth-method/list`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listAuthMethods', pathRoute: '/api/v1/orgs/{org_id}/auth-method/list', },  ...options});
    }
  

export const getListAuthMethodsQueryKey = (orgId: string,
    params?: ListAuthMethodsParams,) => [`/api/v1/orgs/${orgId}/auth-method/list`, ...(params ? [params]: [])];

    
export type ListAuthMethodsQueryResult = NonNullable<Awaited<ReturnType<typeof listAuthMethods>>>
export type ListAuthMethodsQueryError = RpcStatus

export const useListAuthMethods = <TData = Awaited<ReturnType<typeof listAuthMethods>>, TError = RpcStatus>(
 orgId: string,
    params?: ListAuthMethodsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAuthMethods>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListAuthMethodsQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAuthMethods>>> = ({ signal }) => listAuthMethods(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listAuthMethods>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const setAuthMethodStatus = (
    orgId: string,
    setAuthMethodStatusBody: SetAuthMethodStatusBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<SetAuthMethodStatus200>(
      {url: `/api/v1/orgs/${orgId}/auth-method/status`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: setAuthMethodStatusBody
    },
      { _metadata: { operationName: 'setAuthMethodStatus', pathRoute: '/api/v1/orgs/{org_id}/auth-method/status', },  ...options});
    }
  


    export type SetAuthMethodStatusMutationResult = NonNullable<Awaited<ReturnType<typeof setAuthMethodStatus>>>
    export type SetAuthMethodStatusMutationBody = SetAuthMethodStatusBody
    export type SetAuthMethodStatusMutationError = RpcStatus

    export const useSetAuthMethodStatus = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setAuthMethodStatus>>, TError,{orgId: string;data: SetAuthMethodStatusBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setAuthMethodStatus>>, {orgId: string;data: SetAuthMethodStatusBody}> = (props) => {
          const {orgId,data} = props ?? {};

          return  setAuthMethodStatus(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof setAuthMethodStatus>>, TError, {orgId: string;data: SetAuthMethodStatusBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const updateAuthMethod = (
    orgId: string,
    updateAuthMethodBody: UpdateAuthMethodBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateAuthMethod200>(
      {url: `/api/v1/orgs/${orgId}/auth-method/update`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: updateAuthMethodBody
    },
      { _metadata: { operationName: 'updateAuthMethod', pathRoute: '/api/v1/orgs/{org_id}/auth-method/update', },  ...options});
    }
  


    export type UpdateAuthMethodMutationResult = NonNullable<Awaited<ReturnType<typeof updateAuthMethod>>>
    export type UpdateAuthMethodMutationBody = UpdateAuthMethodBody
    export type UpdateAuthMethodMutationError = RpcStatus

    export const useUpdateAuthMethod = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAuthMethod>>, TError,{orgId: string;data: UpdateAuthMethodBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAuthMethod>>, {orgId: string;data: UpdateAuthMethodBody}> = (props) => {
          const {orgId,data} = props ?? {};

          return  updateAuthMethod(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateAuthMethod>>, TError, {orgId: string;data: UpdateAuthMethodBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const listScimGroups = (
    orgId: string,
    params?: ListScimGroupsParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralListScimGroupsRsp>(
      {url: `/api/v1/orgs/${orgId}/scim-groups`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'listScimGroups', pathRoute: '/api/v1/orgs/{org_id}/scim-groups', },  ...options});
    }
  

export const getListScimGroupsQueryKey = (orgId: string,
    params?: ListScimGroupsParams,) => [`/api/v1/orgs/${orgId}/scim-groups`, ...(params ? [params]: [])];

    
export type ListScimGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof listScimGroups>>>
export type ListScimGroupsQueryError = RpcStatus

export const useListScimGroups = <TData = Awaited<ReturnType<typeof listScimGroups>>, TError = RpcStatus>(
 orgId: string,
    params?: ListScimGroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listScimGroups>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getListScimGroupsQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof listScimGroups>>> = ({ signal }) => listScimGroups(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof listScimGroups>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const updateScimGroupRole = (
    orgId: string,
    id: string,
    updateScimGroupRoleBody: UpdateScimGroupRoleBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<UpdateScimGroupRole200>(
      {url: `/api/v1/orgs/${orgId}/scim-groups/${id}/update-role`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateScimGroupRoleBody
    },
      { _metadata: { operationName: 'updateScimGroupRole', pathRoute: '/api/v1/orgs/{org_id}/scim-groups/{id}/update-role', },  ...options});
    }
  


    export type UpdateScimGroupRoleMutationResult = NonNullable<Awaited<ReturnType<typeof updateScimGroupRole>>>
    export type UpdateScimGroupRoleMutationBody = UpdateScimGroupRoleBody
    export type UpdateScimGroupRoleMutationError = RpcStatus

    export const useUpdateScimGroupRole = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateScimGroupRole>>, TError,{orgId: string;id: string;data: UpdateScimGroupRoleBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateScimGroupRole>>, {orgId: string;id: string;data: UpdateScimGroupRoleBody}> = (props) => {
          const {orgId,id,data} = props ?? {};

          return  updateScimGroupRole(orgId,id,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof updateScimGroupRole>>, TError, {orgId: string;id: string;data: UpdateScimGroupRoleBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const createJsmTicket = (
    orgId: string,
    centralCreateJsmTicketReq: CentralCreateJsmTicketReq,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CentralCreateJsmTicketResp>(
      {url: `/api/v1/orgs/${orgId}/support/create`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: centralCreateJsmTicketReq
    },
      { _metadata: { operationName: 'createJsmTicket', pathRoute: '/api/v1/orgs/{org_id}/support/create', },  ...options});
    }
  


    export type CreateJsmTicketMutationResult = NonNullable<Awaited<ReturnType<typeof createJsmTicket>>>
    export type CreateJsmTicketMutationBody = CentralCreateJsmTicketReq
    export type CreateJsmTicketMutationError = RpcStatus

    export const useCreateJsmTicket = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createJsmTicket>>, TError,{orgId: string;data: CentralCreateJsmTicketReq}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createJsmTicket>>, {orgId: string;data: CentralCreateJsmTicketReq}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createJsmTicket(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createJsmTicket>>, TError, {orgId: string;data: CentralCreateJsmTicketReq}, TContext>(mutationFn, mutationOptions);
    }
    
export const getJsmTicketParamInfo = (
    orgId: string,
    params?: GetJsmTicketParamInfoParams,
 options?: SecondParameter<typeof customInstance>,signal?: AbortSignal
) => {
      return customInstance<CentralGetJsmTicketParamInfoResp>(
      {url: `/api/v1/orgs/${orgId}/support/preload`, method: 'get',
        params, signal
    },
      { _metadata: { operationName: 'getJsmTicketParamInfo', pathRoute: '/api/v1/orgs/{org_id}/support/preload', },  ...options});
    }
  

export const getGetJsmTicketParamInfoQueryKey = (orgId: string,
    params?: GetJsmTicketParamInfoParams,) => [`/api/v1/orgs/${orgId}/support/preload`, ...(params ? [params]: [])];

    
export type GetJsmTicketParamInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getJsmTicketParamInfo>>>
export type GetJsmTicketParamInfoQueryError = RpcStatus

export const useGetJsmTicketParamInfo = <TData = Awaited<ReturnType<typeof getJsmTicketParamInfo>>, TError = RpcStatus>(
 orgId: string,
    params?: GetJsmTicketParamInfoParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getJsmTicketParamInfo>>, TError, TData>, request?: SecondParameter<typeof customInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetJsmTicketParamInfoQueryKey(orgId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof getJsmTicketParamInfo>>> = ({ signal }) => getJsmTicketParamInfo(orgId,params, requestOptions, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof getJsmTicketParamInfo>>, TError, TData>({ queryKey, queryFn, enabled: !!(orgId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const createSupportAttach = (
    orgId: string,
    createSupportAttachBody: CreateSupportAttachBody,
 options?: SecondParameter<typeof customInstance>,) => {const formData = new FormData();
if(createSupportAttachBody.file !== undefined) {
 formData.append('file', createSupportAttachBody.file)
 }

      return customInstance<CreateSupportAttach200>(
      {url: `/upload/orgs/${orgId}/support-attach`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      { _metadata: { operationName: 'createSupportAttach', pathRoute: '/upload/orgs/{org_id}/support-attach', },  ...options});
    }
  


    export type CreateSupportAttachMutationResult = NonNullable<Awaited<ReturnType<typeof createSupportAttach>>>
    export type CreateSupportAttachMutationBody = CreateSupportAttachBody
    export type CreateSupportAttachMutationError = RpcStatus

    export const useCreateSupportAttach = <TError = RpcStatus,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSupportAttach>>, TError,{orgId: string;data: CreateSupportAttachBody}, TContext>, request?: SecondParameter<typeof customInstance>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSupportAttach>>, {orgId: string;data: CreateSupportAttachBody}> = (props) => {
          const {orgId,data} = props ?? {};

          return  createSupportAttach(orgId,data,requestOptions)
        }

        

      return useMutation<Awaited<ReturnType<typeof createSupportAttach>>, TError, {orgId: string;data: CreateSupportAttachBody}, TContext>(mutationFn, mutationOptions);
    }
    
