import { useGetTenantsCreditsSummary } from 'dbaas/services'
import useStores from 'dbaas/stores/useStores'

export const useCreditsOverview = () => {
  const {
    store: { tenantData }
  } = useStores()
  const { data: creditSummary } = useGetTenantsCreditsSummary(tenantData.id)
  const availableCredits = Number.parseFloat(creditSummary?.available_remaining_credits || '0')

  return {
    availableCredits,
    hasAvailableCredits: availableCredits > 0
  }
}

export const toLegacyPoints = (credits: string) => {
  return (Number.parseFloat(credits) * 100).toString()
}
