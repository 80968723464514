import { throttle } from 'lodash-es'
import { useState, useEffect } from 'react'

const scrollbarWidth = () => {
  // thanks too https://davidwalsh.name/detect-scrollbar-width
  const scrollDiv = document.createElement('div')
  scrollDiv.setAttribute('style', 'width: 100px; height: 100px; overflow: scroll; position:absolute; top:-9999px;')
  document.body.appendChild(scrollDiv)
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
  document.body.removeChild(scrollDiv)
  return scrollbarWidth
}

export const useScrollbarWidth = () => {
  const [sbw, setSbw] = useState(scrollbarWidth())

  // this needed to ensure the scrollbar width in case hook called before the DOM is ready
  useEffect(() => {
    if (typeof sbw !== 'undefined') {
      return
    }

    const raf = requestAnimationFrame(() => {
      setSbw(scrollbarWidth())
    })

    return () => cancelAnimationFrame(raf)
  }, [])

  return sbw
}

// see https://github.com/ElemeFE/element/blob/dev/packages/table/src/table-body.js#L258
export const isTextOverflow = (ele: HTMLElement) => {
  const range = document.createRange()
  range.setStart(ele, 0)
  range.setEnd(ele, ele.childNodes.length)
  const rangeWidth = range.getBoundingClientRect().width
  const padding = (parseInt(getStyle(ele, 'paddingLeft'), 10) || 0) + (parseInt(getStyle(ele, 'paddingRight'), 10) || 0)
  if (rangeWidth + padding > ele.offsetWidth || ele.scrollWidth > ele.offsetWidth) {
    return true
  }
  return false
}

// see https://github.com/ElemeFE/element/blob/dev/src/utils/dom.js
const SPECIAL_CHARS_REGEXP = /([\:\-\_]+(.))/g
const MOZ_HACK_REGEXP = /^moz([A-Z])/

const camelCase = function (name: string) {
  return name
    .replace(SPECIAL_CHARS_REGEXP, function (_, separator, letter, offset) {
      return offset ? letter.toUpperCase() : letter
    })
    .replace(MOZ_HACK_REGEXP, 'Moz$1')
}

export const getStyle = (ele: HTMLElement, styleName: string) => {
  if (!ele || !styleName) {
    return null
  }
  styleName = camelCase(styleName)
  if (styleName === 'float') {
    styleName = 'cssFloat'
  }
  try {
    const computed = document.defaultView?.getComputedStyle(ele, '') || {}
    return ele.style[styleName] || computed ? computed[styleName] : null
  } catch (e) {
    return ele.style[styleName]
  }
}

const canvas = document.createElement('canvas')
const ctx = canvas.getContext('2d')
export const measureText = (str: string, font?: string) => {
  if (!ctx) {
    return 10
  }
  ctx.font = font ? font : '14px Inter'
  return ctx.measureText(str).width
}

export const downloadCSV = (data: string, fileName: string) => {
  const fileUrl = URL.createObjectURL(
    new Blob([data], {
      type: 'text/csv;charset=utf-8;'
    })
  )
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.href = fileUrl
  a.download = `${fileName}.csv`
  a.click()
  setTimeout(() => {
    document.body.removeChild(a)
  }, 0)
}

export const useWindowSize = (duration = 100) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const handleResize = throttle(() => {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }, duration)

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}

export const detectMobile = () => {
  const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i]

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem)
  })
}
