import { groupBy, merge, set } from 'lodash-es'
import { observable, action, runInAction, makeObservable } from 'mobx'
import { inject, IWrappedComponent } from 'mobx-react'

import { toLegacyPoints } from 'dbaas/features/Billing/credits'
import { ORG_TYPE } from 'dbaas/features/Organization'
import {
  CentralUserProfile,
  CentralOrganization,
  CentralCluster,
  FormsSetupIntent,
  CentralImportGetRespStatus,
  CentralComponentNodeProfiles,
  CentralUpdateUserProfileReq,
  UserDebugFeatureListFeaturesItem,
  getUserDebugFeatureList,
  FormsStripePaymentMethod,
  FormsBillingInformation,
  listPaymentMethods,
  getBillingInfo,
  calcNodeProfilesV2,
  getTenantsCreditsSummary
} from 'dbaas/services'
import {
  getOrganization,
  updateOrganization,
  getCluster,
  getUserProfile,
  updateUserProfile,
  listClusters,
  getCustomerInfo,
  setPaymentMethodDefault,
  setupIntent,
  getExpWhiteList
} from 'dbaas/services'
import { getUserLastVisitHistory, getUserProfileV2, listOrganizations } from 'dbaas/services/account'
import {
  CentralGetUserProfileResp,
  CentralUserProfileOrg,
  CentralUserProfileProject
} from 'dbaas/services/account/interface'
import { getTenantPlanInfo } from 'dbaas/services/billing'
import { FeatureType, isFeatureEnabled } from 'dbaas/utils/feature'
import {
  isServerlessBackupRestoreEnabled,
  isClusterModifyingV2Enabled,
  isDarkModeEanbled,
  isGithubIntegrationEnabled,
  isChatBotEnabled,
  isDataserviceUsageEnabled,
  isServerlessEventsEnabled,
  isServerlessAdvancedEnabled,
  isChat2QueryV2Enabled,
  isCostExplorerEnabled,
  isServerlessVectorEnvEnabled,
  isNotificationEnabled
} from 'dbaas/utils/feature-flags'
import { getThirdPartyVendor } from 'dbaas/utils/marketplace'

import { isPolicyReminderVisible } from '../screens/User/utils'
import { sqlShell } from '../services/openapi'

import clientStore, {
  CHART_TIMEZONE,
  getAuthInfo,
  setSignInUserProfile,
  ExpFeatFlags,
  ExpFeatHashmap
} from './localStorage'

export const CLUSTERS_TABLE_PAGE = 10
const querySearch = new URLSearchParams(window.location.search)

export class Store {
  @observable
  userProfile: CentralGetUserProfileResp & CentralUserProfile = {
    user_id: '',
    id: '',
    is_impersonated_user: false,
    email: '',
    first_name: '',
    last_name: '',
    role: '',
    rbac_role: '',
    projects: [],
    orgs: []
  }

  @observable
  tenantData: CentralOrganization & CentralUserProfileOrg = {
    id: '',
    name: '',
    created_at: '',
    email: '',
    country: '',
    timezone: 0,
    user_set_timezone: '',
    status: '',
    plan: '',
    remaining_credit: '0',
    total_credit: '0',
    has_discount: false,
    discount: '0',
    discount_description: '',
    active_clusters_count: 0,
    can_replication: false,
    acknowledge_cn_policy: false,
    free_clusters_count: 0,
    has_tried_dev_tier: false,
    third_party_account_code: '',
    third_party_account_provider: '',
    whitelist_flag: 0,
    org_type: ''
  }

  @observable
  lastVisitLocation = {
    orgId: '',
    projectId: ''
  }

  @observable
  orgList: CentralUserProfileOrg[] = []

  @observable
  tenantFeatureList: UserDebugFeatureListFeaturesItem[] = []

  @observable
  timeWindow = 300

  @observable
  chartTimezone: CHART_TIMEZONE = CHART_TIMEZONE.CUSTOMER

  @observable
  clusterList: CentralCluster[] = []

  @observable
  clustersPagination = {
    pageIndex: querySearch.get('page') || '1',
    pages: 0,
    total: 0
  }

  @observable
  currentCluster: CentralCluster | null = null

  @observable
  currentClusterName = ''

  // new clusterId which is responded from the createCluster API
  @observable
  newbornCluster: string = ''

  @observable
  clustersById: Record<string, CentralCluster[]> = {}

  @observable
  shellVisibleUrl = ''

  @observable
  stripeSetupIntent: FormsSetupIntent = {
    public_key: '',
    client_secret: ''
  }

  @observable
  isStripeTokenStale = true

  @observable
  notificationVisible = false

  @observable
  activeProjectId: string = clientStore.getLastAccessProject()

  @observable
  cnPolicyReminderVisible = false

  @observable
  importStatus: Record<string, CentralImportGetRespStatus> = {}

  @observable
  nodeProfiles: {
    dev: {
      [provider: string]: {
        [region: string]: CentralComponentNodeProfiles[]
      }
    }
    dedicated: {
      [provider: string]: {
        [region: string]: CentralComponentNodeProfiles[]
      }
    }
  } = { dev: {}, dedicated: {} }

  @observable
  payments: {
    paymentMethods: FormsStripePaymentMethod[]
    defaultPaymentId: string
    billingProfile: FormsBillingInformation
  } = {
    paymentMethods: [],
    defaultPaymentId: '',
    billingProfile: {}
  }

  @observable
  hideTopMenu = false

  @observable
  preloadedSQLEditor: {
    db?: string
    table?: string
    name?: string
    content?: string
  } | null

  @observable
  preloadedFileForImport: File | null = null

  @observable
  expWhiteList: ExpFeatFlags = {}

  constructor() {
    makeObservable(this)
  }

  get isThirdParty() {
    return !!this.tenantData.third_party_account_provider
  }

  get thirdPartyVendor() {
    return getThirdPartyVendor(this.tenantData.third_party_account_provider || '')
  }

  get config() {
    const {
      tenantData: { org_tag },
      userProfile,
      expWhiteList
    } = this
    return {
      hasPOCInTopMenu: this.isThirdParty,
      hideInvoices: this.isThirdParty,
      hidePaymentMethod: false,
      showCreditCard: !this.isThirdParty,
      showAWSBillTips: this.isThirdParty,
      enableReplication: expWhiteList[ExpFeatHashmap.Replication],
      enableAuditLog: expWhiteList[ExpFeatHashmap.ClusterAuditLog],
      enableCrossAccount: expWhiteList[ExpFeatHashmap.CrossAccount],
      clusterCreationV2Enabled: true,
      clusterModifyingV2Enabled: isClusterModifyingV2Enabled(userProfile),
      serverlessVectorEnabled: isServerlessVectorEnvEnabled() || !!expWhiteList[ExpFeatHashmap.ServerlessVector],
      serverlessAdvancedEnabled: isServerlessAdvancedEnabled(userProfile),
      enableServerlessBackupRestore: isServerlessBackupRestoreEnabled(userProfile),
      enableCloudOrgSSO: true,
      darkModeEnabled: isDarkModeEanbled(userProfile),
      enableGithubIntegration: isGithubIntegrationEnabled(userProfile),
      enableChatBot: isChatBotEnabled(userProfile),
      enableDataServiceUsage: isDataserviceUsageEnabled(userProfile),
      enableServerlessEvents: isServerlessEventsEnabled(userProfile),
      enableSQLUsers: expWhiteList[ExpFeatHashmap.SQLUsers],
      isMSPCustomer: org_tag === 'msp_customer',
      enableChat2QueryV2: isChat2QueryV2Enabled(userProfile),
      enabledCostExplorer: isCostExplorerEnabled(userProfile),
      enabledNotification: isNotificationEnabled(userProfile)
    }
  }

  get activeProject(): CentralUserProfileProject {
    return (
      this.userProfile.projects.find((project) => {
        return project.id === this.lastVisitLocation.projectId
      }) || {
        name: '',
        org_id: '',
        id: this.lastVisitLocation.projectId,
        is_cross_account: false,
        aws_cmek_enabled: false
      }
    )
  }
}

export type StoreConfig = Store['config']

export class Actions {
  store: Store

  constructor({ store }: { store: Store }) {
    makeObservable(this)
    this.store = store
  }

  @action
  fetchTenant = async (config?: any) => {
    return getOrganization(config).then((data) => {
      runInAction(() => {
        this.store.tenantData = data
        this.store.cnPolicyReminderVisible = isPolicyReminderVisible(data?.country, data?.acknowledge_cn_policy)
      })
      window.APP_ORG_DATA = data
      return data
    })
  }

  @action
  fetchWhiteList = async () => {
    return getExpWhiteList(this.store.tenantData.id, {
      project_id: this.store.activeProjectId || void 0,
      cluster_id: this.store.currentCluster?.id || void 0
    }).then((data) => {
      runInAction(() => {
        const whitelistNames = {} as ExpFeatFlags

        this.store.expWhiteList =
          data.exp_names?.reduce((prev, name) => {
            prev[name] = true
            return prev
          }, whitelistNames) ?? whitelistNames
      })
    })
  }

  @action
  initOrgData = async (orgId: string) => {
    // Get cached org id from local store.
    // The cached id can be set during switching org
    const { isCached, isMatched, ...cachedInfo } = clientStore.getLastCachedOrg()
    let cachedOrgId = cachedInfo.orgId

    if (isCached && !isMatched) {
      cachedOrgId = ''
      clientStore.clearLastCachedOrg()
    }

    // Set `isHandleErr` to `true` for
    // index route component in App.tsx
    return listOrganizations({ isHandleErr: true }).then((data) => {
      const orgList = data.orgs || []
      const matchingOrgByOrgId = orgList.find((item) => !!item.id && item.id === orgId)
      const matchingOrg = orgList.find((item) => !!item.id && item.id === cachedOrgId)
      let targetOrg = matchingOrgByOrgId || matchingOrg || orgList[0]
      const authInfo = getAuthInfo()

      // using the default personal org
      if (authInfo.type === ORG_TYPE.PERSONAL && targetOrg?.org_type === ORG_TYPE.ENTERPRISE) {
        // using targetOrg as a fallback
        targetOrg = orgList.filter((item) => item.org_type !== ORG_TYPE.ENTERPRISE)[0] || targetOrg
      }

      // clear the cached org id if we cannot find it in the org list
      if (cachedOrgId && !matchingOrg) {
        clientStore.clearLastCachedOrg()
      }

      if (targetOrg) {
        window.APP_ORG_DATA = data
      }

      runInAction(() => {
        this.store.orgList = orgList

        if (targetOrg) {
          this.store.tenantData = targetOrg

          if (
            data.isNewUser &&
            data.defaultCluster &&
            localStorage.getItem(`SQLEditorModal_${targetOrg.email}`) !== '1'
          ) {
            localStorage.setItem(`SQLEditorModal_${targetOrg.email}_NewId`, data.defaultCluster)
          }
          // Deprecated feature?
          // this.store.cnPolicyReminderVisible = isPolicyReminderVisible(targetOrg.country, targetOrg.acknowledge_cn_policy)
        }
      })

      return targetOrg
    })
  }

  @action
  refreshOrgData = async () => {
    return Promise.all([this.fetchUserProfileV2(), this.fetchCreditSummary(), this.fetchTenantPlanInfo()]).then(
      ([data, creditSummary, planInfo]) => {
        const org = data[1]
        const mergedOrg = {
          ...this.store.tenantData,
          ...org,
          total_credit: toLegacyPoints(creditSummary?.total_credits || '0'),
          remaining_credit: toLegacyPoints(creditSummary?.available_remaining_credits || '0'),
          plan: planInfo.effective_plan?.toUpperCase()
        }

        window.APP_ORG_DATA = mergedOrg

        return mergedOrg
      }
    )
  }

  // TODO: it may be better to unify the canary mechanism in dbaas
  @action
  fetchFeatureList = async (orgId: string) => {
    return getUserDebugFeatureList(orgId, { isHandleErr: true, _warningStatus: [403] }).then((data) => {
      runInAction(() => {
        this.store.tenantFeatureList = data.features || []
      })
    })
  }

  @action
  isFeatureEnabled = (feature: FeatureType) => {
    return isFeatureEnabled(this.store.tenantFeatureList, feature)
  }

  @action
  updateOrgTimezoneCache = (timezone: number) => {
    this.store.tenantData.timezone = timezone
    this.store.tenantData.user_set_timezone = String(timezone)
  }

  @action
  updateOrgName = async (payload: { name: string }) => {
    // TODO: refactor with PATCH
    return updateOrganization(
      this.store.tenantData.id,
      // @ts-ignore
      {
        name: payload.name,
        timezone: this.store.tenantData.timezone
      }
    ).then(() => {
      runInAction(() => {
        this.store.tenantData.name = payload.name
      })
    })
  }

  @action
  showShell = (clusterId: string, mysqlUser?: string) => {
    this.store.shellVisibleUrl = sqlShell(this.store.tenantData.id, clusterId, mysqlUser)
  }

  @action
  hideShell = (type = 'hide') => {
    this.store.shellVisibleUrl = type
  }

  @action
  fetchCluster = async (id: string) => {
    return getCluster(this.store.tenantData.id, this.store.activeProjectId, id).then((data) => {
      runInAction(() => {
        // this.store.clustersById[id] = [data?.cluster]
        this.store.clustersById = {
          ...this.store.clustersById,
          [id]: [data?.cluster]
        }
      })
      return data
    })
  }

  // @deprecated
  @action
  fetchUserProfile = async (orgId?: string) => {
    // const projectList = await listProjects(this.store.tenantData.id)
    return getUserProfile(orgId || this.store.tenantData.id).then((data) => {
      setSignInUserProfile({ userId: data.id })
      // window.APP_USER_DATA = data
      return data
    })
  }

  @action
  fetchUserProfileV2 = async () => {
    return getUserProfileV2().then((data) => {
      const org = data.orgs.find((o) => o.id === this.store.lastVisitLocation.orgId) || ({} as CentralUserProfileOrg)
      const projects = data.projects.filter((project) => project.org_id === this.store.lastVisitLocation.orgId) || []
      runInAction(() => {
        this.store.userProfile = { ...data, projects, id: data.user_id, role: '', rbac_role: '' }
        this.store.tenantData = {
          ...this.store.tenantData,
          ...org
        }
        this.store.orgList = data.orgs
      })
      window.APP_ORG_DATA = {
        ...window.APP_ORG_DATA,
        ...org
      }
      if (data.is_new_user && data.default_cluster_id && localStorage.getItem(`SQLEditorModal_${org.email}`) !== '1') {
        localStorage.setItem(`SQLEditorModal_${org.email}_NewId`, data.default_cluster_id)
      }
      window.APP_USER_DATA = data
      return [data, { ...this.store.tenantData, ...org }] as const
    })
  }

  @action
  updateUserProfileModel = (payload: CentralGetUserProfileResp, orgId: string) => {
    const org = payload.orgs?.find((o) => o.id === orgId)
    const projects = payload.projects?.filter((project) => project.org_id === orgId) || []

    this.store.userProfile = {
      ...payload,
      projects,
      id: payload.user_id,
      role: '',
      rbac_role: ''
    }
    this.store.orgList = payload.orgs || []
    this.store.tenantData = {
      ...this.store.tenantData,
      ...(org || {})
    }

    // Global vars
    window.APP_USER_DATA = payload
    window.APP_ORG_DATA = {
      ...window.APP_ORG_DATA,
      ...(org || {})
    }
  }

  @action
  updateUserProfile = async (profile: Omit<CentralUpdateUserProfileReq, 'org_id'>) => {
    const orgId = this.store.tenantData.id
    return updateUserProfile(orgId, { ...profile, org_id: orgId }).then(() => {
      runInAction(() => {
        this.store.userProfile.first_name = profile.first_name
        this.store.userProfile.last_name = profile.last_name
        this.store.userProfile.phone_number = profile.phone_number
      })
    })
  }

  @action
  updateLocalUserProfile = (profile: Omit<CentralUpdateUserProfileReq, 'org_id'>) => {
    this.store.userProfile = {
      ...this.store.userProfile,
      first_name: profile.first_name,
      last_name: profile.last_name
    }
  }

  @action
  fetchClusterList = async (project?: string, page?: string) => {
    const {
      activeProjectId,
      lastVisitLocation,
      userProfile: { projects }
    } = this.store
    const projectId = project || activeProjectId
    const orgId = lastVisitLocation.orgId

    if (!lastVisitLocation.orgId || !projectId || (project?.length && !projects.find((p) => p.id === projectId))) {
      return Promise.reject(new Error('Invalid organization ID or invalid project ID'))
    }

    const pageIndex = page || this.store.clustersPagination.pageIndex
    const perPage = CLUSTERS_TABLE_PAGE

    return listClusters(orgId, projectId, {
      page: Number(pageIndex),
      per_page: perPage
    }).then((data) => {
      runInAction(() => {
        const list = data?.items || []
        const total = data?.total || 0
        this.store.clusterList = list
        this.store.clustersPagination = {
          pageIndex,
          pages: Math.ceil(total / perPage),
          total
        }
        this.store.clustersById = groupBy(list, 'id')
      })
      return data
    })
  }

  @action
  updateClustersPagination = (page: string) => {
    this.store.clustersPagination.pageIndex = page
  }

  @action
  changeTimeWindow = (time: number) => {
    this.store.timeWindow = time
  }

  @action
  setupStripe = async () => {
    return setupIntent(this.store.tenantData.id).then((data) => {
      runInAction(() => {
        this.store.stripeSetupIntent = data
        // get a new fresh token
        this.store.isStripeTokenStale = false
      })

      return data
    })
  }

  @action
  setStripeTokenStale = () => {
    this.store.isStripeTokenStale = true
  }

  @action
  fetchOrgBillingInfo = async () => {
    return getCustomerInfo(this.store.tenantData.id).then((data) => {
      runInAction(() => {
        this.store.payments.defaultPaymentId = data?.default_source || ''
      })
    })
  }

  @action
  fetchPaymentMethods = async (id: string) => {
    return listPaymentMethods(id).then((res) => {
      runInAction(() => {
        this.store.payments.paymentMethods = res
      })
      return res
    })
  }

  @action
  fetchBillingProfile = async (id: string) => {
    // sync plan
    return getBillingInfo(id).then((res) => {
      runInAction(() => {
        this.store.payments.billingProfile = res
      })
    })
  }

  @action
  setDefaultPayment = async (id: string) => {
    return setPaymentMethodDefault(this.store.tenantData.id, id).then(() => {
      runInAction(() => {
        this.store.payments.defaultPaymentId = id
      })
    })
  }

  @action
  fetchCreditSummary = async () => {
    return getTenantsCreditsSummary(this.store.lastVisitLocation.orgId).then((data) => {
      runInAction(() => {
        this.store.tenantData = {
          ...this.store.tenantData,
          total_credit: toLegacyPoints(data.available_poc_credits || '0'),
          remaining_credit: toLegacyPoints(data.available_remaining_credits || '0')
        }
      })
      return data
    })
  }

  @action
  fetchTenantPlanInfo = async () => {
    return getTenantPlanInfo(this.store.lastVisitLocation.orgId).then((data) => {
      runInAction(() => {
        const plan = data.effective_plan?.toUpperCase() || ''
        this.store.tenantData = {
          ...this.store.tenantData,
          plan: data.effective_plan?.toUpperCase() || ''
        }
        window.APP_ORG_DATA = {
          ...window.APP_ORG_DATA,
          plan
        }
      })
      return data
    })
  }

  @action
  toggleNotification = (visible = false) => {
    this.store.notificationVisible = visible
  }

  @action
  setCurrentCluster = (info: CentralCluster | null) => {
    this.store.currentCluster = info
  }

  @action
  setCurrentClusterName = (name: string) => {
    this.store.currentClusterName = name
  }

  // @deprecated
  @action
  updateActiveProjectId = (id: string) => {
    this.store.activeProjectId = id
    clientStore.setLastAccessProject(id)
  }

  @action
  updateLastVisitLocation = (data: { orgId?: string; projectId?: string }) => {
    this.store.lastVisitLocation = {
      ...this.store.lastVisitLocation,
      ...data
    }
    if (data.projectId) {
      this.updateActiveProjectId(data.projectId)
    }
  }

  @action
  fetchLastVisit = () => {
    return getUserLastVisitHistory({ isHandleErr: true, _ignoreStatus: [401] }).then((data) => {
      // '0' means null
      const orgId = (data.org_id !== '0' ? data.org_id : '') || ''
      const projectId = (data.project_id !== '0' ? data.project_id : '') || ''

      runInAction(() => {
        this.store.lastVisitLocation = {
          orgId,
          projectId
        }
        this.updateActiveProjectId(projectId)
        this.store.tenantData.id = orgId
      })
      return data
    })
  }

  @action
  toggleCNPolicyReminder = (visible: boolean) => {
    this.store.cnPolicyReminderVisible = visible
  }

  @action
  setImportStatus = (id: string, status: CentralImportGetRespStatus) => {
    this.store.importStatus[id] = status
  }

  @action
  updateChartTimezone = (t: CHART_TIMEZONE) => {
    this.store.chartTimezone = t
    clientStore.setChartTimezone(t)
  }

  @action
  // global node profiles for scale and restore a cluster
  fetchNodeProfiles = async (provider: string, region: string, is_dev_tier: boolean) => {
    // fetch new node profiles
    return calcNodeProfilesV2(this.store.tenantData.id, this.store.activeProjectId, {
      provider,
      region,
      is_dev_tier
    }).then((data) => {
      runInAction(() => {
        // https://mobx.js.org/actions.html#runinaction
        // Use this utility to create a temporary action that is immediately invoked. Can be useful in asynchronous processes.
        // https://lodash.com/docs/4.17.15#set
        set(this.store.nodeProfiles, [is_dev_tier ? 'dev' : 'dedicated', provider, region], data?.node_profiles || [])
      })
      return data?.node_profiles || []
    })
  }

  @action
  setNewbornCluster = (clusterId: string) => {
    this.store.newbornCluster = clusterId
  }

  @action
  setTopMenuState = (state: boolean) => {
    this.store.hideTopMenu = state
  }

  @action
  setPreloadedSQLEditor = (data: Store['preloadedSQLEditor']) => {
    this.store.preloadedSQLEditor = data
  }

  @action
  setPreloadedFileForImport = (file: File | null) => {
    this.store.preloadedFileForImport = file
  }
}

export const store = new Store()
export const actions = new Actions({ store })

// see https://github.com/mobxjs/mobx-react/issues/256#issuecomment-479739578

export type StoresType = {
  store: Store
  actions: Actions
}

export type Subtract<T, K> = Omit<T, keyof K>

export const withStores =
  <TStoreProps extends keyof StoresType>(...stores: TStoreProps[]) =>
  <TProps extends Pick<StoresType, TStoreProps>>(component: React.ComponentType<TProps>) => {
    return inject(...stores)(component) as any as React.FC<
      Subtract<TProps, Pick<StoresType, TStoreProps>> & Partial<Pick<StoresType, TStoreProps>>
    > &
      IWrappedComponent<TProps>
  }
